import { createAction } from 'redux-actions';

export const fetchProjectCategories = createAction(
  'projects://FETCH_CATEGORIES_LIST'
);
export const fetchProjectCategoriesResult = createAction(
  'projects://FETCH_CATEGORIES_LIST_RESULT'
);

export const fetchMyProjects = createAction('projects://FETCH_MY_PROJECTS');
export const fetchMyProjectsSucceeded = createAction(
  'projects://FETCH_MY_PROJECTS_SUCCEEDED'
);

export const fetchAllProjects = createAction('projects://FETCH_ALL_PROJECTS');
export const fetchAllProjectsResult = createAction(
  'projects://FETCH_ALL_PROJECTS_RESULT'
);

export const fetchProjectInfoById = createAction(
  'profile://FETCH_PROJECT_INFO'
);
export const fetchProjectInfoByIdSuccess = createAction(
  'profile://FETCH_PROJECT_INFO_SUCCESS'
);

export const createProjectProcessingStatusSet = createAction(
  'projects://PROCESSING_STATUS_SET'
);

export const createProject = createAction('projects://CREATE_PROJECT');
export const createProjectSuccessed = createAction(
  'projects://CREATE_PROJECT_SUCCESSED'
);
export const createProjectFailed = createAction(
  'projects://CREATE_PROJECT_FAILED'
);
export const createProjectPopupClose = createAction(
  'projects://CREATE_PROJECT_POPUP_CLOSE'
);

export const updateProject = createAction('projects://UPDATE_PROJECT');
export const updateProjectSuccessed = createAction(
  'projects://UPDATE_PROJECT_SUCCESSED'
);
export const updateProjectFailed = createAction(
  'projects://UPDATE_PROJECT_FAILED'
);

export const deleteProject = createAction('projects://DELETE_PROJECT');
export const deleteProjectSuccess = createAction(
  'projects://DELETE_PROJECT_SUCCESS'
);

export const finishProject = createAction('projects://FINISH_PROJECT');
export const finishProjectSuccess = createAction(
  'projects://FINISH_PROJECT_SUCCESS'
);

export const openDescriptionModal = createAction(
  'projects://OPEN_DESCRIPTION_MODAL'
);
