/* eslint-disable */
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { Link, Switch, Route, useParams, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import {
  setCurrentPage,
  redirectToPreviousPage,
} from 'views/create-project-old/store/ducks/createProject/actions';
import { eventsSelector } from 'views/create-project-old/store/ducks/createProject/selectors';

import PageTitle from '../../common/PageTitle';
import PageSubTitle from '../../common/PageSubTitle';
import { BackButton } from '../../common/Button';

import OneTimeEvents from './OneTimeEvents';
import RecurringEvent from './RecurringEvent';
import TimeAWeek from './TimeAWeek';
import Membership from './Membership';

const Wrapper = styled.div`
  width: 100%;
  min-width: 1000px;
  padding: 0 50px;
`;

const Card = styled(Link)`
  text-decoration: none;
  width: 325px;
  height: 250px;
  box-shadow: 5px 7px 30px rgba(0, 0, 0, 0.07);
  border-radius: 9px;
  padding: 20px;
  padding-top: 35px;
  font-size: 12px;
  text-align: center;
  cursor: pointer;

  :hover {
    box-shadow: 5px 7px 45px rgba(0, 0, 0, 0.2);
  }
`;

const Once = styled(Card)`
  background: #f9efe7;
  color: #14161d;
  padding-top: 35px;

  :hover {
    color: #14161d;
  }
`;

const Recurring = styled(Card)`
  background: #f3c6bd;
  color: #14161d;
  margin-left: 125px;
  padding-top: 35px;

  :hover {
    color: #14161d;
  }
`;

const CancelTimeSelection = styled(Card)`
  width: 575px;
  height: 125px;
  margin: 70px auto;
  background: #e5e5ea;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  font-size: 20px;
  font-weight: bold;
  color: #14161d;

  :hover {
    color: #14161d;
  }
`;

const Row = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 50px;
`;

const Title = styled.div`
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 20px;
`;

const ButtonWrapper = styled.div`
  width: 335px;
  height: 56px;
  margin: 50px auto;
  display: flex;
  justify-content: center;
`;

const SelectTimeInputType = ({ redirectToPreviousPageAction }) => {
  const history = useHistory();
  const { t } = useTranslation();

  return (
    <Wrapper>
      <PageTitle>{t('createProjectOld.steps.setTime.title')}</PageTitle>
      <PageSubTitle>
        {t('createProjectOld.steps.setTime.subtitle')}
      </PageSubTitle>
      <Row>
        <Once to="set-time/once">
          <Title>{t('createProjectOld.once')}</Title>
          {t('createProjectOld.steps.setTime.once.desc')}
        </Once>
        <Recurring to="set-time/recurring">
          <Title>{t('createProjectOld.recurring')}</Title>
          {t('createProjectOld.steps.setTime.recurring.desc')}
        </Recurring>
      </Row>
      <CancelTimeSelection to="set-time/time-a-week">
        {t('createProjectOld.steps.setTime.custom.desc')}
      </CancelTimeSelection>
      <ButtonWrapper>
        <BackButton onClick={() => history.push('/create-project/title-image')}>
          {t('createProjectOld.back')}
        </BackButton>
      </ButtonWrapper>
    </Wrapper>
  );
};

const SetTime = ({
  match,
  setCurrentPageAction,
  redirectToPreviousPageAction,
  events,
  intl,
}) => {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(true);

  const { selectTimeMode } = useParams();

  useEffect(() => {
    console.log('events', events);

    const event = events.length && events[0];
    if (!event) {
      setIsLoading(false);
      return;
    }

    if (event.oneTimeEvent) {
      history.push('/create-project/set-time/once');
    }
    if (event.recurringEvent) {
      history.push('/create-project/set-time/recurring');
    }
    if (event.timeAWeek) {
      history.push('/create-project/set-time/time-a-week');
    }
    setIsLoading(false);
  }, [events, history]);

  if (isLoading) {
    return null;
  }

  return (
    <Switch>
      {!selectTimeMode && <SelectTimeInputType />}
      {selectTimeMode === 'once' && <OneTimeEvents />}
      {selectTimeMode === 'recurring' && <RecurringEvent />}
      {selectTimeMode === 'time-a-week' && <TimeAWeek />}
      {selectTimeMode === 'membership' && <Membership />}
    </Switch>
  );
};

const mapState = (state) => ({
  events: eventsSelector(state),
});

export default connect(mapState, null)(SetTime);
