import React, { ReactElement } from 'react';
import Lottie from 'lottie-react';
import cn from 'classnames';

import loaderJSON from './loader.json';
import loaderWhiteJSON from './loaderWhite.json';

import styles from './loader.module.sass';

interface Props {
  className?: string;
  color?: string;
  centered?: boolean;
  centeredStatic?: boolean;
  type?: 'spinner' | 'logo';
}

const Loader: React.FC<Props> = ({
  className,
  color,
  centered,
  centeredStatic,
  type,
}): ReactElement => {
  return (
    <>
      {(!type || (type && type === 'logo')) && (
        <Lottie
          className={cn(styles.lottieLoader, className, {
            [styles.centered]: centered,
            [styles['centered-static']]: centeredStatic,
          })}
          animationData={color === 'white' ? loaderWhiteJSON : loaderJSON}
        />
      )}

      {type && type === 'spinner' && (
        <div
          className={cn(styles.loader, className, {
            [styles.loaderWhite]: color === 'white',
            [styles.centered]: centered,
            [styles['centered-static']]: centeredStatic,
          })}
        />
      )}
    </>
  );
};

export default Loader;
