/* eslint-disable */
import styled from 'styled-components';

const PageSubTitle = styled.div`
  font-size: 26px;
  letter-spacing: 0.2px;
  color: #8e8e93;
  text-align: center;
`;

export default PageSubTitle;
