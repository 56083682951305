/* eslint-disable */
import React from 'react';
import styled from 'styled-components';

const Input = styled.input`
  width: 100%;
  height: 100%;
  background: #efeff4;
  border-radius: 20px;
  border: none;
  outline: none;
  font-size: 20px;
  text-align: center;
  font-weight: bold;

  ::placeholder {
    font-weight: bold;
  }
`;

const Label = styled.div`
  font-size: 14px;
  color: #000;
  text-align: center;
  line-height: 133%;
  margin-top: 10px;
  font-weight: bold;
`;

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
`;

const InputWrapper = ({
  label,
  maxCharacters,
  onChange,
  type = 'text',
  placeholder,
  value,
}) => {
  const handleChange = (e) => {
    let { value } = e.target;

    if (value.length > maxCharacters) return;
    onChange(value);
  };

  return (
    <Wrapper>
      <Input
        onChange={handleChange}
        type={type}
        placeholder={placeholder}
        value={value}
      />
      <Label>{label}</Label>
    </Wrapper>
  );
};

export default InputWrapper;
