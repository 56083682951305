import React from 'react';

import useAutoSignup from './use-auto-signup';

const AutoSignup: React.FC = () => {
  useAutoSignup();
  return null;
};

export default AutoSignup;
