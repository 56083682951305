import { api } from 'views/shared/modules/api/request';
import { User } from 'views/shared/modules/user/model';

export interface LoginResponse {
  status: string;
  statusCode: number;
  message: string;
  token?: string;
  user?: User;
  // firebaseToken?: string;
}

export interface LoginPayload {
  token: string;
  // firebaseToken: string;
  user: User;
}

export interface LoginData {
  email: string;
  password: string;
  groupId?: string;
}

export async function loginWithEmailAndPassword(inputData: LoginData): Promise<LoginPayload> {
  const { data } = await api.post<LoginResponse>('/auth/_login', inputData);
  const {
    token,
    user,
    //  firebaseToken
  } = data;

  if (
    token !== undefined &&
    user !== undefined
    // && firebaseToken !== undefined
  ) {
    return {
      token,
      user,
      // firebaseToken
    };
  }

  return Promise.reject(data);
}
