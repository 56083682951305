import { AnyAction } from 'redux';

/**
 * action types
 */

export const SET_REDIRECT_PATH = 'SET_REDIRECT_PATH';

/**
 * reducer
 */

interface ProtectedRouteState {
  redirectPath: string;
}

const initialState: ProtectedRouteState = {
  redirectPath: '/',
};

export default function reducer(
  state = initialState,
  action: AnyAction
): ProtectedRouteState {
  switch (action.type) {
    case SET_REDIRECT_PATH:
      return {
        ...state,
        redirectPath: { ...action.redirectPath },
      };
    default:
      return state;
  }
}

/**
 * action creators
 */

export function setRedirectPath(redirectPath: string): {
  type: string;
  redirectPath: string;
} {
  return {
    type: SET_REDIRECT_PATH,
    redirectPath,
  };
}
