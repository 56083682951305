/* eslint-disable */
import React from 'react';
import styled from 'styled-components';
import { lighten } from 'polished';

const Button = styled.button`
  width: 100%;
  height: 100%;
  background: #45dbd0;
  border-radius: 15px !important;
  color: #fff;
  font-size: 20px;
  text-transform: uppercase;
  border: none;
  outline: none;
  cursor: pointer;
  transition: background-color 0.4s;

  :hover {
    background: ${lighten(0.1, '#45DBD0')};
  }
`;

const DisabledButton = styled(Button)`
  background: #efeff4;
  cursor: not-allowed;

  :hover {
    background: #efeff4;
  }
`;

const BackButtonComponent = styled(Button)`
  background-color: #fff;
  border: 4px solid #45dbd0;
  color: #111;

  :hover {
    border-color: ${lighten(0.1, '#45DBD0')};
    color: #fff;
  }
`;

const ButtonWrapper = ({ disabled, ...props }) =>
  disabled ? <DisabledButton disabled {...props} /> : <Button {...props} />;

export const BackButton = ({ disabled, ...props }) =>
  disabled ? (
    <DisabledButton disabled {...props} />
  ) : (
    <BackButtonComponent {...props} />
  );

export default ButtonWrapper;
