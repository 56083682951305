import { combineReducers } from 'redux';

import projects from 'views/create-project-old/store/ducks/projects/reducer';
import createProject from 'views/create-project-old/store/ducks/createProject/reducer';
import user from 'views/shared/modules/user/user-redux';
import loader from 'views/shared/components/loader/loader-redux';
import infoMessage from 'views/shared/components/info-message/info-message-redux';
import signUp from 'views/shared/components/sign-up/sign-up-redux';
import protectedRoute from 'views/shared/components/protected-route/protected-route-redux';
import filters from 'views/shared/store/filters-redux';
import projectApplication from 'views/shared/store/project-application-redux';

const appReducer = combineReducers({
  user,
  loader,
  infoMessage,
  signUp,
  projects,
  createProject,
  protectedRoute,
  filters,
  projectApplication,
});

export type RootState = ReturnType<typeof appReducer>;

export default appReducer;
