import axios, { Method, AxiosResponse } from 'axios';

export const api = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_API_URL,
});

export const request = <T>(
  method: Method,
  url: string,
  params?: { [key: string]: string | number }
): Promise<AxiosResponse<T>> => {
  return api.request<T>({
    method,
    url,
    params,
  });
};
