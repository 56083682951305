import React from 'react';

import useRedirectFromNonExistentOrgDomain from './use-redirect-from-non-existent-org-domain';

const RedirectFromNonExistentOrgDomain: React.FC = () => {
  useRedirectFromNonExistentOrgDomain();
  return null;
};

export default RedirectFromNonExistentOrgDomain;
