/* eslint-disable */
import { handleActions } from 'redux-actions';
import findIndex from 'lodash/findIndex';
import range from 'lodash/range';

import * as actions from './actions';

const mockedUrlsToThemeImages = range(1, 37, 1).map((imgNumber) => {
  return `https://s3.eu-central-1.amazonaws.com/img.letsact.de/project_default_pictures/letsact_impact_projekte_(${imgNumber}).jpg`;
});

const eventModal = {
  isOpen: false,
  withFrequency: false,
  date: null,
};

const initialState = {
  currentPage: 0,
  title: '',
  categories: [],
  categoryOptions: [],
  images: {
    titleImage: '',
    uploadedImages: [],
    themeImages: mockedUrlsToThemeImages,
  },
  permanentMembership: null,
  isLocationIndependent: null,
  location: '',
  position: {},
  eventModal,
  purpose: '',
  private: false,
  role: '',
  minimumAge: 16,
  place: {},
  currentGroup: null,
  events: [
    // {
    // ONE TIME EVENT
    // id: uuid,
    //
    // startDate: new Date ,
    // endDate: new Date,
    // shifts: [3600000, 7200000, 3600000]
    // },
    // {
    // REPEATABLE
    // id: uuid(),
    //
    // startDate: new Date,
    // endDate: new Date({ year: 9999 }),
    // shifts: [3600000, 7200000, 3600000],
    //
    // duration: 7200000,
    // cron: '0 12 * * MON',
    // }
  ],
};

const reducer = handleActions(
  {
    [actions.setFormField]: (state, { payload: { field, value } }) => ({
      ...state,
      [field]: value,
    }),
    [actions.setCurrentPage]: (state, { payload: currentPage }) => ({
      ...state,
      currentPage,
    }),
    [actions.setCategoryOptions]: (state, { payload: categoryOptions }) => ({
      ...state,
      categoryOptions,
    }),
    [actions.setOptions]: (state, { payload }) => ({ ...state, ...payload }),
    [actions.setImages]: (state, { payload: { field, images } }) => ({
      ...state,
      images: {
        ...state.images,
        [field]: images,
      },
    }),
    [actions.resetImages]: (state) => ({
      ...state,
      images: {
        ...state.images,
        titleImage: '',
        uploadedImages: [],
      },
    }),
    [actions.openEventModal]: (state, { payload }) => ({
      ...state,
      eventModal: { ...state.eventModal, ...payload, isOpen: true },
    }),
    [actions.setCurrentGroup]: (state, { payload: currentGroup }) => ({
      ...state,
      currentGroup,
    }),
    [actions.closeEventModal]: (state) => ({ ...state, eventModal }),
    [actions.setEventToState]: (state, { payload: newEvent }) => {
      const index = findIndex(state.events, { _id: newEvent._id });

      if (newEvent.oneTimeEvent) {
        return { ...state, events: [newEvent] };
      }

      if (index === -1) {
        return { ...state, events: [...state.events, newEvent] };
      }
      const events = [...state.events];
      events[index] = { ...newEvent };
      return { ...state, events };
    },
    [actions.deleteEvent]: (state, { payload: idToRemove }) => ({
      ...state,
      events: state.events.filter(({ _id }) => _id !== idToRemove),
    }),
    [actions.setEventTermsToState]: (state, { payload }) => ({
      ...state,
      permanentMembership: payload,
    }),
    [actions.setLocationToState]: (state, { payload }) => {
      const { isLocationIndependent, location, position, place } = payload;

      if (isLocationIndependent) return { ...state, isLocationIndependent };

      return {
        ...state,
        location,
        position,
        place,
        isLocationIndependent: false,
      };
    },
    [actions.setDetails]: (state, { payload }) => ({ ...state, ...payload }),
    [actions.deleteEvents]: (state) => ({ ...state, events: [] }),
    [actions.clearState]: () => ({ ...initialState }),
  },
  initialState
);

export default reducer;
