import { useState, FC } from 'react';

import { Stack, Popover, useTheme, Tooltip, Box, SxProps, TooltipProps, styled } from '@mui/material';
import { tooltipClasses } from '@mui/material/Tooltip';

import Picker from 'emoji-picker-react';

import { Reaction } from 'views/community-feed/models/event';
import { useAuth } from 'views/shared/modules/auth/service';
import { EmojiButton } from './emoji-button';
import { IconButtonAnimate } from '../animations';
import { isOwnReaction } from './utils/isOwnReaction';
import { EmojiReactionUsers } from './emoji-reaction-users';
import { Iconify } from '../iconify';

interface Props {
  emojis: Record<string, Reaction[]>;
  handleEmojiClicked: (emoji: string) => void;
  icon?: string;
  sx?: SxProps;
}

export const EmojiPicker: FC<Props> = ({ emojis, handleEmojiClicked, sx, icon }) => {
  const theme = useTheme();
  const { user: currentUser } = useAuth();

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const onEmojiClick = (emoji: string) => {
    handleEmojiClicked(emoji);
    handleClose();
  };

  const uniqueEmojis = Object.keys(emojis);

  return (
    <Stack sx={sx} direction="row" alignItems="center" gap={0.7} flexWrap="wrap">
      <IconButtonAnimate
        onClick={handleClick}
        color="secondary"
        size="small"
        aria-label="add"
        sx={{ border: `1px solid ${theme.palette.divider}` }}
      >
        <Iconify icon={icon || 'ic:outline-plus'} color="grey.600" sx={{ width: 20, height: 20 }} />
      </IconButtonAnimate>
      {uniqueEmojis.map((e) => (
        <CustomWidthTooltip
          key={e}
          title={<EmojiReactionUsers emoji={e} reactions={emojis[e]} />}
          placement="top"
          enterDelay={700}
          disableInteractive
          arrow
        >
          <Box key={e}>
            <EmojiButton
              emoji={e}
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              isOwnReaction={isOwnReaction(currentUser!._id, emojis[e])}
              reactionsAmount={emojis[e].length}
              handleClick={() => onEmojiClick(e)}
            />
          </Box>
        </CustomWidthTooltip>
      ))}

      <Popover
        sx={{ zIndex: 10000 }}
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Picker
          groupVisibility={{
            flags: false,
            symbols: false,
            objects: false,
            activities: false,
            travel_places: false,
            food_drink: false,
            animals_nature: false,
          }}
          onEmojiClick={(e, emojiObject) => onEmojiClick(emojiObject.emoji)}
        />
      </Popover>
    </Stack>
  );
};

const CustomWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 330,
    wordWrap: 'break-word',
  },
});
