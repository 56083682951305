import { AnyAction } from 'redux';

/**
 * action types
 */

export const SHOW_LOADER = 'SHOW_LOADER';
export const HIDE_LOADER = 'HIDE_LOADER';

/**
 * reducer
 */

interface LoaderState {
  isVisible: boolean;
}

const initialState: LoaderState = {
  isVisible: false,
};

export default function reducer(
  state = initialState,
  action: AnyAction
): LoaderState {
  switch (action.type) {
    case SHOW_LOADER:
      return {
        ...state,
        isVisible: true,
      };
    case HIDE_LOADER:
      return {
        ...state,
        isVisible: false,
      };
    default:
      return state;
  }
}

/**
 * action creators
 */

export function showLoader(): { type: string } {
  return {
    type: SHOW_LOADER,
  };
}

export function hideLoader(): { type: string } {
  return {
    type: HIDE_LOADER,
  };
}
