import { ReactNode, useEffect, useMemo } from 'react';
// @mui
import { alpha, ThemeProvider, createTheme, useTheme } from '@mui/material/styles';
// hooks
import { useCurrentOrganizationAndGroup } from 'views/shared/hooks/use-current-organization-and-group';
import { useSettings } from './settings/settings-provider';
//
import componentsOverride from './overrides';
import getColorPresets from './settings/utils/get-color-presets';
import { ThemeColorPresets as ThemeColorPresetsType } from './settings/models/type';

// ----------------------------------------------------------------------

type Props = {
  children: ReactNode;
};

export default function ThemeColorPresets({ children }: Props) {
  const defaultTheme = useTheme();
  const { organization } = useCurrentOrganizationAndGroup();
  const { currentColor, onChangeColorConfig } = useSettings();

  useEffect(() => {
    if (!organization) return;
    const colors =
      organization.themeSettings?.themePalette?.primary?.colors ||
      getColorPresets(organization.name as ThemeColorPresetsType);

    onChangeColorConfig(colors);
  }, [organization?._id]);

  const themeOptions = useMemo(
    () => ({
      ...defaultTheme,
      palette: {
        ...defaultTheme.palette,
        primary: currentColor,
      },
      customShadows: {
        ...defaultTheme.customShadows,
        primary: `0 8px 16px 0 ${alpha(currentColor.main, 0.24)}`,
      },
    }),
    [currentColor, defaultTheme]
  );

  const theme = createTheme(themeOptions);
  theme.components = componentsOverride(theme);

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
}
