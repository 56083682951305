import { useState, FC, Dispatch, SetStateAction, useEffect } from 'react';
import { Box } from '@mui/material';
import Slide, { SlideProps } from '@mui/material/Slide';
import Snackbar, { SnackbarOrigin } from '@mui/material/Snackbar';
//
import { FeedbackConfigPayload, FeedbackResponse, PathnameTypes } from 'models/feedback';
import { Feedback } from './feedback';

type Position = 'top-left' | 'top-center' | 'top-right' | 'bottom-left' | 'bottom-center' | 'bottom-right';

export interface Props {
  open: boolean;
  position?: Position;
  configuration: FeedbackConfigPayload<string>;
  property: PathnameTypes;
}

type TransitionProps = Omit<SlideProps, 'direction'>;

function TransitionDown(props: TransitionProps) {
  return <Slide {...props} direction="down" />;
}

export const FeedbackSnackbar: FC<Props> = ({ open, position = 'bottom-left', configuration, property }) => {
  const [openState, setOpenState] = useState(open);

  useEffect(() => {
    setOpenState(open);
  }, [open]);

  const handleClose = () => {
    setOpenState(false);
  };

  const anchorOrigin = getPosition(position);

  return (
    <Snackbar
      TransitionComponent={TransitionDown}
      anchorOrigin={anchorOrigin}
      open={openState}
      autoHideDuration={null}
      ClickAwayListenerProps={{ onClickAway: () => null }}
      onClose={handleClose}
    >
      <Box>
        <Feedback configuration={configuration} handleClose={handleClose} property={property} />
      </Box>
    </Snackbar>
  );
};

export type ResponseProps = { response: FeedbackResponse; setResponse: Dispatch<SetStateAction<FeedbackResponse>> };

export const getFeedbackValue = (selectedValue: number | null, nOfRatings: number): number | null => {
  return selectedValue ? selectedValue / nOfRatings : null;
};

const getPosition = (position: Position): SnackbarOrigin => {
  switch (position) {
    case 'top-left':
      return {
        vertical: 'top',
        horizontal: 'left',
      };
    case 'top-center':
      return {
        vertical: 'top',
        horizontal: 'center',
      };
    case 'top-right':
      return {
        vertical: 'top',
        horizontal: 'right',
      };
    case 'bottom-left':
      return {
        vertical: 'bottom',
        horizontal: 'left',
      };
    case 'bottom-center':
      return {
        vertical: 'bottom',
        horizontal: 'center',
      };
    case 'bottom-right':
      return {
        vertical: 'bottom',
        horizontal: 'right',
      };
    default:
      return getPosition('bottom-left');
  }
};
