import Title from 'views/create-project-old/components/CreateProject/formPages/Title';
import Categories from 'views/create-project-old/components/CreateProject/formPages/Categories';
import TitleImage from 'views/create-project-old/components/CreateProject/formPages/TitleImage';
import SetTime from 'views/create-project-old/components/CreateProject/formPages/SetTime';
import SetLocation from 'views/create-project-old/components/CreateProject/formPages/SetLocation';
import Description from 'views/create-project-old/components/CreateProject/formPages/Description';
import Options from 'views/create-project-old/components/CreateProject/formPages/Options';

export const pathToComponentMap = {
  title: Title,
  categories: Categories,
  'title-image': TitleImage,
  'set-time': SetTime,
  location: SetLocation,
  description: Description,
  options: Options,
};

export const pagesOrder = [
  'title',
  'categories',
  'title-image',
  'set-time',
  'location',
  'description',
  'options',
];
