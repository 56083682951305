import React from 'react';

import useAutoLogin from './use-auto-login';

const AutoLogin: React.FC = () => {
  useAutoLogin();
  return null;
};

export default AutoLogin;
