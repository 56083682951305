/* eslint-disable */
import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { Calendar } from 'antd';
import moment from 'moment';
import { withRouter, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import PageTitle from '../../common/PageTitle';
import PageSubTitle from '../../common/PageSubTitle';
import Button, { BackButton } from '../../common/Button';

import EventCard from './EventCard';

import {
  openEventModal,
  deleteEvents,
} from 'views/create-project-old/store/ducks/createProject/actions';
import { eventsSelector } from 'views/create-project-old/store/ducks/createProject/selectors';

const Wrapper = styled.div`
  width: 100%;
  max-width: 1200px;
`;

const ButtonWrapper = styled.div`
  width: 335px;
  height: 56px;
`;

const ButtonsWrapper = styled.div`
  width: 750px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 50px auto;
`;

const createEvent = (event) => {
  const shifts = [];
  const startDate = moment(event.startDate).local();

  if (event.shifts) {
    let from = startDate;
    event.shifts.forEach((shift) => {
      const to = from.add(shift, 'ms');
      shifts.push({
        from: from.format('HH:mm'),
        to: to.format('HH:mm'),
      });
      from = to;
    });
  } else {
    shifts.push({
      from: startDate.format('HH:mm'),
      to: startDate.add(event.duration).format('HH:mm'),
    });
  }

  return shifts;
};

const RecurringEvent = ({ openEventModal, events, deleteEvents, intl }) => {
  const { t } = useTranslation();
  const currentDate = moment();
  const history = useHistory();

  const handleSelect = (date) => {
    if (date.isSameOrAfter(currentDate, 'day')) {
      openEventModal({ date, withFrequency: true });
    }
  };

  const handleSubmit = () => {
    history.push('/create-project/set-time/membership');
  };

  const dateCellRender = (date) => {
    return events.map((event) => {
      const { cron } = event;

      const [, , dayOfMonth, month, dayOfWeek] = cron.split(' ').map(Number);
      const [isMinutes, isHour, isDayOfMonth, isMonth, isDayOfWeek] = cron
        .split(' ')
        .map((segment) => segment !== '*');

      const isDaily =
        isMinutes && isHour && !isDayOfMonth && !isMonth && !isDayOfWeek;
      const isWeekly =
        isMinutes && isHour && !isDayOfMonth && !isMonth && isDayOfWeek;
      const isMonthly =
        isMinutes && isHour && isDayOfMonth && !isMonth && !isDayOfWeek;
      const isYearly =
        isMinutes && isHour && isDayOfMonth && isMonth && !isDayOfWeek;

      const isRenderWeekly = isWeekly && date.day() === dayOfWeek;
      const isRenderMonthly = isMonthly && date.date() === dayOfMonth;
      const isRenderYearly =
        isYearly && date.date() === dayOfMonth && date.month() === month;

      if (isDaily || isRenderWeekly || isRenderMonthly || isRenderYearly) {
        const shifts = createEvent(event);
        return (
          <EventCard
            onClick={(e) => {
              e.stopPropagation();

              if (date.isSameOrAfter(currentDate, 'day')) {
                openEventModal({
                  date,
                  withFrequency: true,
                  eventId: event._id,
                });
              }
            }}
            shifts={shifts}
          />
        );
      }

      return null;
    });
  };

  return (
    <Wrapper>
      <PageTitle>{t('createProjectOld.steps.setTime.title')}</PageTitle>
      <PageSubTitle>
        {t('createProjectOld.steps.setTime.recurring.subtitle')}
      </PageSubTitle>
      <Calendar onSelect={handleSelect} dateCellRender={dateCellRender} />
      <ButtonsWrapper>
        <ButtonWrapper>
          <BackButton
            onClick={() => {
              deleteEvents();
              history.push('/create-project/set-time');
            }}>
            {t('createProjectOld.back')}
          </BackButton>
        </ButtonWrapper>
        <ButtonWrapper>
          <Button disabled={events.length === 0} onClick={handleSubmit}>
            {t('createProjectOld.next')}
          </Button>
        </ButtonWrapper>
      </ButtonsWrapper>
    </Wrapper>
  );
};

const mapState = (state) => ({
  events: eventsSelector(state),
});

const mapDispatch = {
  openEventModal,
  deleteEvents,
};

export default connect(mapState, mapDispatch)(withRouter(RecurringEvent));
