import { useEffect } from 'react';
import { useAnalytics } from 'use-analytics';
import { useLocation } from 'react-router-dom';

export const usePageTracker = () => {
  const location = useLocation();
  const { page } = useAnalytics();
  useEffect(() => {
    page({ title: location.pathname, search: location.search });
  }, [location]);
};
