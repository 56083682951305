import { useState, FC } from 'react';
import { Dialog } from '@mui/material';
//
import { FeedbackConfigPayload, PathnameTypes } from 'models/feedback';
import { Feedback } from './feedback';

export interface Props {
  open: boolean;
  configuration: FeedbackConfigPayload<string>;
  property: PathnameTypes;
}

export const FeedbackModal: FC<Props> = ({ open, configuration, property }) => {
  const [openState, setOpenState] = useState(open);

  const handleClose = () => {
    setOpenState(false);
  };

  return openState ? (
    <Dialog
      maxWidth="md"
      open={openState}
      onClose={(_, reason) => {
        if (reason === 'backdropClick' || reason === 'escapeKeyDown') return;
        handleClose();
      }}
    >
      <Feedback configuration={configuration} handleClose={handleClose} property={property} />
    </Dialog>
  ) : null;
};
