/* eslint-disable */
import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { withRouter, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { setEventTerms } from 'views/create-project-old/store/ducks/createProject/actions';

import PageTitle from '../../common/PageTitle';
import PageSubTitle from '../../common/PageSubTitle';
import { BackButton } from '../../../CreateProject/common/Button';

const Wrapper = styled.div`
  width: 100%;
  max-width: 1200px;
`;

const CardWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 100px;
`;

const Card = styled.div`
  width: 325px;
  height: 140px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  font-weight: bold;
  color: #fff;
  padding: 20px;
  box-shadow: 5px 7px 30px rgba(0, 0, 0, 0.07);
  border-radius: 15px;
  text-align: center;
  cursor: pointer;

  :hover {
  }
`;

const LongTerm = styled(Card)`
  background-color: #45dbd0;
`;

const ShortTerm = styled(Card)`
  background-color: #003761;
  margin-left: 70px;
`;

const ButtonWrapper = styled.div`
  width: 335px;
  height: 56px;
  margin: 50px auto;
  display: flex;
  justify-content: center;
`;

function Membership({ setEventTerms, intl }) {
  const history = useHistory();
  const { t } = useTranslation();

  return (
    <Wrapper>
      <PageTitle>
        {t('createProjectOld.steps.setTime.longterm.title')}
      </PageTitle>
      <PageSubTitle>
        {t('createProjectOld.steps.setTime.longterm.subtitle')}
      </PageSubTitle>
      <CardWrapper>
        <LongTerm
          onClick={() => {
            setEventTerms(true);
            history.push('/create-project/location');
          }}>
          {t('createProjectOld.steps.setTime.longterm.yes')}
        </LongTerm>
        <ShortTerm
          onClick={() => {
            setEventTerms(false);
            history.push('/create-project/location');
          }}>
          {t('createProjectOld.steps.setTime.longterm.no')}
        </ShortTerm>
      </CardWrapper>
      <ButtonWrapper>
        <BackButton
          onClick={() => {
            history.push('/create-project/set-time/recurring');
          }}>
          {t('createProjectOld.back')}
        </BackButton>
      </ButtonWrapper>
    </Wrapper>
  );
}

const mapDispatch = {
  setEventTerms,
};

export default connect(null, mapDispatch)(withRouter(Membership));
