import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
import thunk from 'redux-thunk';

import rootSaga from 'views/create-project-old/store/sagas';

import appReducer from './reducers';

const sagaMiddleware = createSagaMiddleware();
const middlewares = [sagaMiddleware, thunk];

const store = createStore(appReducer, compose(applyMiddleware(...middlewares)));

export type AppDispatch = typeof store.dispatch;

sagaMiddleware.run(rootSaga);
export default store;
