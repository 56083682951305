import { useEffect, useRef } from 'react';

import { useOrgByCurrentHostname } from 'views/shared/modules/organization/hooks';

export default function useRedirectFromNonExistentOrgDomain(): void {
  const currentHostnameOrg = useOrgByCurrentHostname;
  const checkEnabled = useRef(false);

  useEffect(() => {
    // Give some time for the currentHostnameOrg to load
    setTimeout(() => {
      checkEnabled.current = true;
    }, 1000);
  }, []);

  useEffect(() => {
    if (
      checkEnabled.current &&
      !currentHostnameOrg &&
      process.env.REACT_APP_BASE_URL
    ) {
      const appUrl = new URL(process.env.REACT_APP_BASE_URL);

      if (window.location.hostname !== appUrl.hostname) {
        window.location.replace(process.env.REACT_APP_BASE_URL);
      }
    }
  }, [currentHostnameOrg, checkEnabled]);
}
