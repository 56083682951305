import { useMemo } from 'react';

import { User } from 'views/shared/modules/user/model';
import { Organization } from 'views/shared/modules/organization/model';
import { useGetOrgByHostname } from 'views/shared/modules/organization/api/get-organizations';

interface UseOrgId {
  orgId: string;
}
export const useOrgId = (user: User | undefined): UseOrgId => {
  const orgId = useMemo(() => {
    if (user !== undefined && user.orgDetail !== undefined) {
      return user.orgDetail._id;
    }

    return '';
  }, [user]);

  return {
    orgId,
  };
};

export function useOrgByCurrentHostname(): {
  orgByHostname: undefined | Organization;
  isFetching: boolean;
} {
  const { data: orgByHostname, isFetching } = useGetOrgByHostname(window.location.hostname);

  return {
    orgByHostname: orgByHostname ? orgByHostname[0] : undefined,
    isFetching,
  };
}
