import { FC } from 'react';
import { IconContainerProps, Rating, Stack, styled } from '@mui/material';
//
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import SentimentDissatisfiedIcon from '@mui/icons-material/SentimentDissatisfied';
import SentimentSatisfiedIcon from '@mui/icons-material/SentimentSatisfied';
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAltOutlined';
import SentimentVerySatisfiedIcon from '@mui/icons-material/SentimentVerySatisfied';
import { ResponseProps, getFeedbackValue } from './feedback-snackbar';

const ICON_SIZE = 42;

export const RatingType: FC<ResponseProps> = ({ response, setResponse }) => {
  return (
    <Stack gap={4} direction="row" alignItems="center" justifyContent="center">
      <RadioGroupRating response={response} setResponse={setResponse} />
    </Stack>
  );
};

const StyledRating = styled(Rating)(({ theme }) => ({
  '& .MuiRating-iconEmpty .MuiSvgIcon-root': {
    color: theme.palette.action.disabled,
  },
}));

export const customIcons: {
  [index: string]: {
    icon: React.ReactElement;
    label: string;
  };
} = {
  1: {
    icon: <SentimentVeryDissatisfiedIcon sx={{ color: 'error.dark', width: ICON_SIZE, height: ICON_SIZE }} />,
    label: 'Very Dissatisfied',
  },
  2: {
    icon: <SentimentDissatisfiedIcon sx={{ width: ICON_SIZE, height: ICON_SIZE }} color="error" />,
    label: 'Dissatisfied',
  },
  3: {
    icon: <SentimentSatisfiedIcon sx={{ width: ICON_SIZE, height: ICON_SIZE }} color="warning" />,
    label: 'Neutral',
  },
  4: {
    icon: <SentimentSatisfiedAltIcon sx={{ width: ICON_SIZE, height: ICON_SIZE }} color="success" />,
    label: 'Satisfied',
  },
  5: {
    icon: <SentimentVerySatisfiedIcon sx={{ color: 'success.dark', width: ICON_SIZE, height: ICON_SIZE }} />,
    label: 'Very Satisfied',
  },
};

function IconContainer(props: IconContainerProps) {
  const { value, ...other } = props;
  return <span {...other}>{customIcons[value].icon}</span>;
}

export const RadioGroupRating: FC<ResponseProps> = ({ response, setResponse }) => {
  const handleChange = (newVal: number | null) => {
    setResponse((prev) => ({
      ...prev,
      type: 'emoji',
      rating: getFeedbackValue(newVal || 0, 5),
    }));
  };

  return (
    <StyledRating
      value={response.rating ? response.rating * 5 : null}
      onChange={(_, newVal) => handleChange(newVal)}
      size="large"
      name="highlight-selected-only"
      IconContainerComponent={IconContainer}
      getLabelText={(value: number) => customIcons[value].label}
      highlightSelectedOnly
    />
  );
};
