import { api } from 'views/shared/modules/api/request';
import { Category } from 'views/shared/modules/category/model';

export interface CategoryData {
  status: string;
  statusCode: number;
  message: string;
  categories: Category[];
}

export async function getCategories(): Promise<Category[]> {
  try {
    const { data: categoryData } = await api.get<CategoryData>('/categories');

    if (categoryData.categories !== undefined) {
      return Promise.resolve(categoryData.categories);
    }

    return Promise.reject(categoryData);
  } catch (err) {
    return Promise.reject(err);
  }
}
