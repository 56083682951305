import storage from 'views/shared/utils/storage';
import { api } from 'views/shared/modules/api/request';
import { useQuery, UseQueryResult } from 'react-query';

import { Project } from 'views/shared/modules/project/model';
import { Organization } from 'views/shared/modules/organization/model';

export interface OrganizationData {
  data: Organization;
}

export interface ProjectsData {
  data: Project[];
}

export async function getOrganization(id: string): Promise<Organization> {
  try {
    const res = await api.get<OrganizationData>(`/organizations/${id}`, {
      headers: {
        Authorization: storage.getToken(),
      },
    });

    const { data: organizationData } = res;

    return Promise.resolve(organizationData.data);
  } catch (err) {
    return Promise.reject(err);
  }
}

export async function getOrganizationProjects(id: string): Promise<Project[]> {
  try {
    const res = await api.get<ProjectsData>(`/organizations/${id}/projects`, {
      headers: {
        Authorization: storage.getToken(),
      },
    });

    const { data } = res;

    let projects: Project[] = [];

    projects = data.data;

    if (projects.length > 0) {
      return Promise.resolve(projects);
    }

    return Promise.resolve([]);
  } catch (err) {
    return Promise.reject(err);
  }
}

export function useGetOrganization(id: undefined | string): UseQueryResult<Organization, unknown> {
  return useQuery(['organization', id], () => getOrganization(id || ''), {
    enabled: !!id,
    keepPreviousData: true,
  });
}

export function useGetOrganizationProjects(organizationId: undefined | string): UseQueryResult<Project[], unknown> {
  return useQuery(['organizationProjects', organizationId], () => getOrganizationProjects(organizationId || ''), {
    enabled: !!organizationId,
    keepPreviousData: true,
  });
}
