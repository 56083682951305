/* eslint-disable */
import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import ImageGallery from 'react-image-gallery';
import { useTranslation } from 'react-i18next';
import 'react-image-gallery/styles/css/image-gallery.css';

import {
  selectTitleImage,
  selectUploadedImages,
} from 'views/create-project-old/store/ducks/createProject/selectors';

import {
  resetImages,
  setImages,
} from 'views/create-project-old/store/ducks/createProject/actions';

const Wrapper = styled.div`
  display: flex;
  flex-flow: column;
  align-items: center;
`;

const SimpleImagePreview = styled.img`
  display: block;
  width: 785px;
  height: 475px;
  object-fit: cover;
  border-radius: 15px;
  margin: 35px auto 0;
`;

const ChangeImage = styled.button`
  display: inline-block;
  color: #8e8e93;
  text-decoration: underline;
  cursor: pointer;
  margin: 50px auto 25px;
  font-size: 26px;
  border: none;
  background-color: transparent;
`;

const MultipleImagePreviewWrpapper = styled.div`
  width: 520px;
  height: 310px;
  margin-top: 35px;

  .image-gallery-left-nav:hover::before,
  .image-gallery-right-nav:hover::before {
    color: #45dbd0;
  }

  button.image-gallery-left-nav:focus,
  button.image-gallery-right-nav:focus {
    outline: none;
    background-color: transparent;
  }

  .image-gallery-thumbnail.active {
    border-color: #45dbd0;
  }

  .original-image {
    max-width: 520px;
    max-height: 310px;
    object-fit: cover;
  }

  .thumbnail-image {
    max-height: 150px;

    img {
      max-height: 130px;
      object-fit: cover;
    }
  }
`;

const MultipleImagePreview = ({ uploadedImages, onImageSlide }) => {
  const images = uploadedImages.map((image) => ({
    original: image,
    thumbnail: image,
    originalClass: 'original-image',
    thumbnailClass: 'thumbnail-image',
  }));
  return (
    <MultipleImagePreviewWrpapper>
      <ImageGallery
        items={images}
        showPlayButton={false}
        showFullscreenButton={false}
        onSlide={onImageSlide}
      />
    </MultipleImagePreviewWrpapper>
  );
};

const Preview = ({ titleImage, uploadedImages, onImageSlide }) => {
  if (!uploadedImages.length || uploadedImages.length === 1) {
    return <SimpleImagePreview src={titleImage} />;
  }

  return (
    <MultipleImagePreview
      titleImage={titleImage}
      uploadedImages={uploadedImages}
      onImageSlide={onImageSlide}
    />
  );
};

function ImagesPreview({ titleImage, uploadedImages, resetImages, setImages }) {
  const { t } = useTranslation();
  const handleImagesSlide = (index) => {
    const titleImage = uploadedImages[index];
    setImages({ field: 'titleImage', images: titleImage });
  };

  return (
    <Wrapper>
      <Preview
        titleImage={titleImage}
        uploadedImages={uploadedImages}
        onImageSlide={handleImagesSlide}
      />
      <ChangeImage onClick={resetImages}>
        {t('createProjectOld.steps.setTitleImage.change')}
      </ChangeImage>
    </Wrapper>
  );
}

const mapState = (state) => ({
  titleImage: selectTitleImage(state),
  uploadedImages: selectUploadedImages(state),
});

const mapDispatch = {
  resetImages,
  setImages,
};

export default connect(mapState, mapDispatch)(ImagesPreview);
