/* eslint-disable */
import React from 'react';
import styled from 'styled-components';

import UserImageUploader from './UserImageUploader';
import ThemeImages from './ThemeImages';

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 70px;
`;

const UserImageUploaderWrapper = styled.div`
  width: 50%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 100px;
  border-right: 1px solid #efeff4;
`;

const ThemeImagesWrapper = styled.div`
  width: 50%;
  display: flex;
  align-items: center;
  padding-left: 100px;
`;

export default () => (
  <Wrapper>
    <UserImageUploaderWrapper>
      <UserImageUploader />
    </UserImageUploaderWrapper>
    <ThemeImagesWrapper>
      <ThemeImages />
    </ThemeImagesWrapper>
  </Wrapper>
);
