import { useCurrentOrganizationAndGroup } from 'views/shared/hooks/use-current-organization-and-group';

export interface HookData {
  filteredMenuItems: string[];
  fullMenu: MenuKey[];
  availableMenu: string[];
  menuItems: MenuItemsType;
  domainSettings?: DomainProperties | null;
  loadingDomain: boolean;
}
interface MenuItem {
  to: string;
  isPathname: string[];
  iconActive: string;
  iconInactive: string;
  label: string;
}
interface MenuItemsType {
  dashboardOrg: MenuItem;
  dashboardEmployee: MenuItem;
  projects: MenuItem;
  volunteers: MenuItem;
  community: MenuItem;
  donation: MenuItem;
  settings: MenuItem;
}
interface DomainProperties {
  domain: string;
  menuItems: MenuKey[];
  defaultRedirect: string;
}

export type MenuKey =
  | 'dashboardOrg'
  | 'dashboardEmployee'
  | 'projects'
  | 'projectCreation'
  | 'volunteers'
  | 'community'
  | 'donation'
  | 'settings';

export const useFilterSideMenu = (): HookData => {
  const { group, organization, isLoadingOrg: loadingDomain } = useCurrentOrganizationAndGroup();

  const filteredCustomDomains: DomainProperties[] = [
    {
      domain: 'wwk-volunteering.joinlets.de', // WWK
      menuItems: ['dashboardOrg', 'dashboardEmployee', 'projects', 'volunteers', 'settings'],
      defaultRedirect: '/dashboard',
    },
    {
      domain: 'getsafe.joinlets.de', // GetSafe
      menuItems: ['dashboardOrg', 'settings', 'donation'],
      defaultRedirect: '/donation-projects',
    },
    {
      domain: 'getsafe-christmas.joinlets.de', // GetSafe
      menuItems: ['dashboardOrg', 'settings', 'donation'],
      defaultRedirect: '/donation-projects',
    },
  ];
  const matchingDomainObject =
    !loadingDomain && organization
      ? filteredCustomDomains.find((domainObj: DomainProperties) => domainObj.domain === organization?.customDomain)
      : null;

  const fullMenu: MenuKey[] = [
    'dashboardOrg',
    'dashboardEmployee',
    'projects',
    'volunteers',
    'community',
    'settings',
    'projectCreation',
    // 'donation',
  ];

  const menuItems = {
    dashboardOrg: {
      to: '/dashboard',
      isPathname: ['dashboard'],
      iconActive: 'chart-fill',
      iconInactive: 'chart',
      label: 'organizationMenu.dashboard',
    },
    dashboardEmployee: {
      to: '/dashboard',
      isPathname: ['dashboard'],
      iconActive: 'home-fill',
      iconInactive: 'home',
      label: 'organizationMenu.home',
    },
    projects: {
      to: group ? '/my-projects' : '/volunteering',
      isPathname: ['volunteering', 'my-projects'],
      iconActive: 'grid-fill',
      iconInactive: 'grid',
      label: 'organizationMenu.projects',
    },
    volunteers: {
      to: '/project-manage',
      isPathname: ['volunteers'],
      iconActive: 'stacked-rect-filled',
      iconInactive: 'stacked-rect',
      label: 'organizationMenu.volunteers',
    },
    community: {
      to: '/community-feed',
      isPathname: ['community-feed'],
      iconActive: 'medal-filled',
      iconInactive: 'medal',
      label: 'organizationMenu.community',
    },
    donation: {
      to: '/donation-projects',
      isPathname: ['donation-projects'],
      iconActive: 'wallet',
      iconInactive: 'wallet',
      label: 'organizationMenu.donation',
    },
    settings: {
      to: '/organisation-account-settings',
      isPathname: ['organisation-account-settings'],
      iconActive: 'cog',
      iconInactive: 'cog-alt',
      label: '',
    },
  };
  const filteredMenuItems = matchingDomainObject ? matchingDomainObject?.menuItems : [];

  const availableMenu = filteredMenuItems.length > 0 ? filteredMenuItems : fullMenu;
  return {
    filteredMenuItems,
    fullMenu,
    availableMenu,
    menuItems,
    domainSettings: matchingDomainObject,
    loadingDomain,
  };
};

export default useFilterSideMenu;
