/* eslint-disable */
import React, { useState } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Modal } from 'antd';
import { useTranslation } from 'react-i18next';

import { selectThemeImages } from 'views/create-project-old/store/ducks/createProject/selectors';

import { setImages } from 'views/create-project-old/store/ducks/createProject/actions';

const ImagesPreviewWrapper = styled.div``;

const ImagePreviewRow = styled.div`
  :first-child {
    margin-bottom: 40px;
  }
`;

const ImagePreview = styled.img`
  display: inline-block;
  width: 225px;
  height: 150px;
  border-radius: 16px;
  cursor: pointer;

  :first-child {
    margin-right: 40px;
  }
`;

const Title = styled.div`
  font-size: 20px;
  color: #14161d;
  font-weight: bold;
  margin-bottom: 10px;
`;

const SubTitle = styled.div`
  color: #14161d;
  font-size: 16px;
  margin-bottom: 30px;
`;

const ShowAllImagesButton = styled.button`
  border: none;
  background: transparent;
  color: #000;
  font-weight: bold;
  text-decoration: underline;
  margin-top: 30px;
  outline: none;
  cursor: pointer;
  font-size: 18px;
`;

const ImagesWrapper = styled.div`
  display: grid;
  grid-template-rows: 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 1vw;
  width: 700px;
  height: 500px;
  overflow-y: scroll;
`;

const Image = styled.img`
  width: 200px;
  height: 100px;
  cursor: pointer;
  border: 4px solid ${(props) => (props.selected ? '#45DBD0' : 'transparent')};
  object-fit: cover;
`;

const ImagesPreview = ({ images, onSelectImage }) => {
  const renderImage = (image) => (
    <ImagePreview src={image} onClick={() => onSelectImage(image)} />
  );

  return (
    <ImagesPreviewWrapper>
      <ImagePreviewRow>{images.slice(0, 2).map(renderImage)}</ImagePreviewRow>
      <ImagePreviewRow>{images.slice(2).map(renderImage)}</ImagePreviewRow>
    </ImagesPreviewWrapper>
  );
};

const Wrapper = styled.div``;

function ThemeImages({ themeImages, setImages, intl }) {
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedModalImage, setSelectedModalImage] = useState(null);

  const handleSelectImage = (image) => {
    setImages({ field: 'titleImage', images: image });
  };

  const handleToggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const previewImages = themeImages.slice(0, 4);

  return (
    <Wrapper>
      <Title>{t('createProjectOld.steps.setTitleImage.choose.title')}</Title>
      <SubTitle>
        {t('createProjectOld.steps.setTitleImage.choose.subtitle')}
      </SubTitle>
      <ImagesPreview onSelectImage={handleSelectImage} images={previewImages} />
      <ShowAllImagesButton onClick={handleToggleModal}>
        {t('createProjectOld.steps.setTitleImage.choose.show')}
      </ShowAllImagesButton>
      <Modal
        visible={isModalOpen}
        onCancel={() => {
          setSelectedModalImage(null);
          handleToggleModal();
        }}
        onOk={() => handleSelectImage(selectedModalImage)}
        width={750}>
        <ImagesWrapper>
          {themeImages.map((image) => (
            <Image
              src={image}
              selected={selectedModalImage === image}
              onClick={() => setSelectedModalImage(image)}
            />
          ))}
        </ImagesWrapper>
      </Modal>
    </Wrapper>
  );
}

const mapState = (state) => ({
  themeImages: selectThemeImages(state),
});

const mapDispatch = {
  setImages,
};

export default connect(mapState, mapDispatch)(ThemeImages);
