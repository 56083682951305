/* eslint-disable */
import { call, select } from 'redux-saga/effects';
import axios from 'axios';
import querystring from 'querystring';

import storage from 'views/shared/utils/storage';

import { authToken } from './ducks/auth/selectors';

const headers = (token) => {
  return {
    headers: {
      Authorization: `${token}`,
    },
  };
};

export function* request(url, config) {
  const token = storage.getToken();

  const requestConfig = {
    url: process.env.REACT_APP_BACKEND_API_URL + url,
    ...headers(token),
    ...config,
  };

  return yield call(axios.request, requestConfig);
}

export function* getRequest(url, config) {
  return yield request(url, {
    method: 'get',
    ...config,
  });
}

export function* postWithoutTokenRequest(url, data) {
  return yield request(url, {
    method: 'post',
    data: {
      ...data,
    },
  });
}

export function* postRequest(url, data, config) {
  return yield request(url, {
    method: 'post',
    data: querystring.stringify({ ...data }),
    ...config,
  });
}

export function* postJSONRequest(url, data, config) {
  return yield request(url, {
    method: 'post',
    data: {
      ...data,
    },
    ...config,
  });
}

export function* putRequest(url, data) {
  return yield request(url, {
    method: 'put',
    data: {
      ...data,
    },
  });
}

export function* deleteRequest(url, data) {
  return yield request(url, {
    method: 'delete',
    data: {
      ...data,
    },
  });
}

export function* postFormDataRequest(url, data) {
  const formData = new FormData();

  Object.keys(data).forEach((key) => {
    if (key === 'files') {
      data[key].forEach((file) => {
        console.log('current file is', file);
        formData.append('files', file.file, file.file.name);
      });
    } else {
      formData.append(key, data[key]);
    }
  });

  return yield request(url, {
    method: 'post',
    data: formData,
  });
}
