import { useEffect, useState } from 'react';
//
import { FeedbackConfig, PathnameTypes } from 'models/feedback';
import usePathPattern from 'views/shared/hooks/use-path-name';

interface ReturnProps {
  delayedConfig: FeedbackConfig<string> | null;
}

export const useMatchConfigByRoute = (data: FeedbackConfig<string>[]): ReturnProps => {
  const pathname = usePathPattern();

  const [delayedConfig, setDelayedConfig] = useState<FeedbackConfig<string> | null>(null);

  useEffect(() => {
    const config = getConfigByPropertyType(data, pathname);
    let timerId: NodeJS.Timeout;
    if (config) {
      timerId = setTimeout(() => setDelayedConfig(config), config.payload.question.delay);
    } else {
      setDelayedConfig(null);
    }
    return () => clearTimeout(timerId);
  }, [pathname, data]);

  return {
    delayedConfig,
  };
};

const getConfigByPropertyType = (data: FeedbackConfig<string>[], property: PathnameTypes) => {
  return data.find((c) => c.property === property);
};
