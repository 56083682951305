/* eslint-disable */
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { selectTitleImage } from 'views/create-project-old/store/ducks/createProject/selectors';
import {
  redirectToNextPage as redirectToNextPageRedux,
  setCurrentPage as setCurrentPageRedux,
  redirectToPreviousPage as redirectToPreviousPageRedux,
} from 'views/create-project-old/store/ducks/createProject/actions';

import PageTitle from '../../common/PageTitle';
import PageSubTitle from '../../common/PageSubTitle';
import Button, { BackButton } from '../../common/Button';
import Tips from '../../common/Tips';

import TitleImageSelector from './TitleImageSelector';
import ImagesPreview from './ImagesPreview';

const arrayStab = Array(3).fill('');

const Wrapper = styled.div`
  width: 100%;
  max-width: 1200px;
`;

const ButtonWrapper = styled.div`
  width: 335px;
  height: 56px;
`;

const ButtonsWrapper = styled.div`
  width: 750px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 50px auto;
`;

const TipsWrapper = styled.div`
  max-width: 800px;
  margin: 50px auto;
  display: flex;
  justify-content: space-between;
`;

const TitleImage = (props) => {
  const history = useHistory();
  const { t } = useTranslation();

  const getContent = () => {
    if (!props.titleImage) {
      return <TitleImageSelector />;
    }

    return <ImagesPreview />;
  };

  useEffect(() => {
    const { setCurrentPageRedux: setCurrentPage } = props;

    setCurrentPage(2);
  }, [props]);

  const {
    titleImage,
    redirectToNextPageRedux: redirectToNextPage,
    redirectToPreviousPageRedux: redirectToPreviousPage,
  } = props;

  return (
    <Wrapper>
      <PageTitle>{t('createProjectOld.steps.setTitleImage.title')}</PageTitle>
      <PageSubTitle>
        {t('createProjectOld.steps.setTitleImage.subtitle')}
      </PageSubTitle>

      {getContent()}

      <ButtonsWrapper>
        <ButtonWrapper>
          <BackButton
            onClick={() => history.push('/create-project/categories')}>
            {t('createProjectOld.back')}
          </BackButton>
        </ButtonWrapper>
        <ButtonWrapper>
          <Button
            onClick={() => history.push('/create-project/set-time')}
            disabled={!titleImage}>
            {t('createProjectOld.next')}
          </Button>
        </ButtonWrapper>
      </ButtonsWrapper>

      <TipsWrapper>
        <Tips
          title={t('createProjectOld.do')}
          subTitle={t(`createProjectOld.steps.setTitleImage.good.title`)}
          type="do"
          tips={arrayStab.map((_o, index) =>
            t(`createProjectOld.steps.setTitleImage.good.example${index}`)
          )}
        />
        <Tips
          title={t('createProjectOld.dont')}
          subTitle={t(`createProjectOld.steps.setTitleImage.bad.title`)}
          type="dont"
          tips={arrayStab.map((_o, index) =>
            t(`createProjectOld.steps.setTitleImage.bad.example${index}`)
          )}
        />
      </TipsWrapper>
    </Wrapper>
  );
};

TitleImage.propTypes = {
  titleImage: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  intl: PropTypes.object.isRequired,
  redirectToNextPageRedux: PropTypes.func.isRequired,
  setCurrentPageRedux: PropTypes.func.isRequired,
  redirectToPreviousPageRedux: PropTypes.func.isRequired,
};

const mapState = (state) => ({
  titleImage: selectTitleImage(state),
});

const mapDispatch = {
  redirectToNextPageRedux,
  setCurrentPageRedux,
  redirectToPreviousPageRedux,
};

export default connect(mapState, mapDispatch)(TitleImage);
