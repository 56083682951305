import { useEffect } from 'react';

import { useAuth } from 'views/shared/modules/auth/service';
import { loginRedirect } from 'views/shared/utils/login-redirect';

export default function useLoginRedirect(): void {
  const { user } = useAuth();

  useEffect(() => {
    if (user && user.status === 'active') {
      (async () => {
        await loginRedirect();
      })();
    }
  }, [user]);
}
