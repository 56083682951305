import { FC } from 'react';
import { Stack, ToggleButton, ToggleButtonGroup } from '@mui/material';
//
import { useReward } from 'react-rewards';
//
import { Iconify } from 'views/shared/components/iconify';
import { ResponseProps, getFeedbackValue } from './feedback-snackbar';

export const ThumbType: FC<ResponseProps> = ({ response, setResponse }) => {
  const handleClickUp = () => {
    emojiRewardUp();
    setResponse((prev) => ({
      ...prev,
      type: 'thumb',
      rating: getFeedbackValue(2, 2),
    }));
  };

  const handleClickDown = () => {
    emojiRewardDown();
    setResponse((prev) => ({
      ...prev,
      type: 'thumb',
      rating: getFeedbackValue(0, 2),
    }));
  };

  const { reward: emojiRewardUp, isAnimating: isAnimatingUp } = useReward('emojiRewardUp', 'emoji', {
    decay: 0.9,
    lifetime: 120,
    spread: 160,
    elementSize: 30,
    elementCount: 20,
    angle: 70,
    emoji: ['👍', '🎉', '🎉', '🥳'],
  });

  const { reward: emojiRewardDown, isAnimating: isAnimatingDown } = useReward('emojiRewardDown', 'emoji', {
    decay: 0.9,
    lifetime: 120,
    spread: 160,
    elementSize: 30,
    elementCount: 20,
    angle: 70,
    emoji: ['👎', '🙁', '👎', '😿'],
  });
  return (
    <Stack gap={4} direction="row" alignItems="center" justifyContent="center">
      <ToggleButtonGroup
        size="large"
        value={response.rating}
        sx={{
          border: 'none',
          gap: 2,
        }}
        exclusive
      >
        <ToggleButton
          disabled={isAnimatingUp}
          id="emojiRewardUp"
          onClick={handleClickUp}
          value={1}
          key="up"
          color="success"
        >
          <Iconify width={32} height={32} icon="noto-v1:thumbs-up" />
        </ToggleButton>
        <ToggleButton
          disabled={isAnimatingDown}
          id="emojiRewardDown"
          onClick={handleClickDown}
          value={0}
          key="down"
          color="error"
        >
          <Iconify width={32} height={32} icon="noto-v1:thumbs-down" />
        </ToggleButton>
      </ToggleButtonGroup>
    </Stack>
  );
};
