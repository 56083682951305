import { EntityDetailed } from './entity';
import { LocaleI } from './locales';

export type PathnameTypes = '/volunteering/:id/apply-success' | '/volunteering/:id/participation-success';

export const routesWithFeedback: PathnameTypes[] = [
  '/volunteering/:id/apply-success',
  '/volunteering/:id/participation-success',
];

export interface FeedbackConfig<T extends LocaleI | string> extends EntityDetailed {
  area: 'feedback-question';
  property: PathnameTypes;
  value: null;

  startDate: Date;
  endDate: Date | null;

  target: 'application';
  targetId: null; // We only have single user App

  payload: FeedbackConfigPayload<T>;
}

export type QuestionType = 'thumb' | 'emoji';
export type PopupType = 'modal' | 'snackbar';

export interface FeedbackConfigPayload<T extends LocaleI | string> {
  popupType: PopupType;
  closable?: boolean;
  question: {
    title: T; //  | string
    subTitle?: T;
    type: QuestionType;
    comment: boolean;
    delay: number;
  };
  activity: {
    activity: 'feedback';
    target: 'application';
  };
}

export interface FeedbackResponse {
  area: 'feedback-request';
  property: PathnameTypes;
  rating: number | null;
  type: QuestionType;
  popupType?: PopupType;
  exactPath?: string;
  text?: string;
}

export type FeedBackResponseDetailed = FeedbackResponse & { userId: string; groupId: string } & EntityDetailed;
