/* eslint-disable */
import React, { useEffect } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import {
  formInput,
  redirectToNextPage,
} from 'views/create-project-old/store/ducks/createProject/actions';
import { titleSelector } from 'views/create-project-old/store/ducks/createProject/selectors';

import PageTitle from '../common/PageTitle';
import PageSubTitle from '../common/PageSubTitle';
import Input from '../common/Input';
import Button from '../common/Button';
import Tips from '../common/Tips';

const arrayStab = Array(4).fill('');

const TitlePageWrapper = styled.div`
  width: 100%;
  max-width: 1000px;
`;

const InputWrapper = styled.div`
  margin: 100px auto 75px;
  height: 80px;
  width: 630px;
`;

const ButtonWrapper = styled.div`
  width: 335px;
  height: 56px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
`;

const TipsWrapper = styled.div`
  max-width: 800px;
  margin: 50px auto;
  display: flex;
  justify-content: space-between;
`;

const Title = ({ title, formInput, redirectToNextPage }) => {
  const handleChange = (value) => formInput({ field: 'title', value });

  const history = useHistory();

  const { t } = useTranslation();

  return (
    <TitlePageWrapper>
      <PageTitle>{t('createProjectOld.steps.setTitle.title')}</PageTitle>
      <PageSubTitle>
        {t('createProjectOld.steps.setTitle.subtitle')}
      </PageSubTitle>
      <InputWrapper>
        <Input
          label={t('createProjectOld.steps.setTitle.maxchar', {
            value: 60 - title.length,
          })}
          maxCharacters={60}
          placeholder={t('createProjectOld.steps.setTitle.example')}
          onChange={handleChange}
          value={title}
        />
      </InputWrapper>
      <ButtonWrapper>
        <Button
          onClick={() => history.push('/create-project/categories')}
          disabled={!title.trim().length}>
          {t('createProjectOld.next')}
        </Button>
      </ButtonWrapper>
      <TipsWrapper>
        <Tips
          title={t('createProjectOld.do')}
          subTitle={t('createProjectOld.steps.setTitle.examples.good.title')}
          type="do"
          tips={arrayStab.map((_o, index) =>
            t(`createProjectOld.steps.setTitle.examples.good.example${index}`)
          )}
        />
        <Tips
          title={t('createProjectOld.dont')}
          subTitle={t('createProjectOld.steps.setTitle.examples.bad.title')}
          type="dont"
          tips={arrayStab.map((_o, index) =>
            t(`createProjectOld.steps.setTitle.examples.bad.example${index}`)
          )}
        />
      </TipsWrapper>
    </TitlePageWrapper>
  );
};

const mapState = (state) => ({
  title: titleSelector(state),
});

const mapDispatch = {
  formInput,
  redirectToNextPage,
};

export default connect(mapState, mapDispatch)(Title);
