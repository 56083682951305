import { createActions } from 'redux-actions';

export const {
  formInput,
  setFormField,
  redirectToNextPage,
  setCurrentPage,
  initCategories,
  setCategoryOptions,
  setImages,
  resetImages,
  openEventModal,
  closeEventModal,
  setEvent,
  setEventToState,
  setTimeAWeek,
  setEventTerms,
  setEventTermsToState,
  setLocation,
  setLocationToState,
  submitDetails,
  setDetails,
  deleteEvent,
  createProject,
  setOptions,
  redirectToPreviousPage,
  deleteEvents,
  clearState,
  setCurrentGroup,
} = createActions(
  'FORM_INPUT',
  'SET_FORM_FIELD',
  'REDIRECT_TO_NEXT_PAGE',
  'SET_CURRENT_PAGE',
  'INIT_CATEGORIES',
  'SET_CATEGORY_OPTIONS',
  'SET_IMAGES',
  'RESET_IMAGES',
  'OPEN_EVENT_MODAL',
  'CLOSE_EVENT_MODAL',
  'SET_EVENT',
  'SET_EVENT_TO_STATE',
  'SET_TIME_A_WEEK',
  'SET_EVENT_TERMS',
  'SET_EVENT_TERMS_TO_STATE',
  'SET_LOCATION',
  'SET_LOCATION_TO_STATE',
  'SUBMIT_DETAILS',
  'SET_DETAILS',
  'DELETE_EVENT',
  'CREATE_PROJECT',
  'SET_OPTIONS',
  'REDIRECT_TO_PREVIOUS_PAGE',
  'DELETE_EVENTS',
  'CLEAR_STATE',
  'SET_CURRENT_GROUP',
  { prefix: 'CREATE_PROJECT' }
);
