import { useEffect } from 'react';
import i18n from 'i18next';

import storage from 'views/shared/utils/storage';

// Ideally, this configuration should be placed elsewhere, somewhere globally
// along with the configuration for hiding menu items for a specific organization etc.
// Because this is the organization specific configuration
const LANGUAGE_BY_HOSTNAME: Record<string, string> = {
  'localhost:3000': 'en',
  'getsafe-christmas.joinlets.de': 'en',
  default: 'de',
};

export default function useAutoSetLanguage() {
  useEffect(() => {
    const language = LANGUAGE_BY_HOSTNAME[window.location.host] || LANGUAGE_BY_HOSTNAME.default;

    handleSelectLanguage(language);
  }, []);

  const handleSelectLanguage = (language: string) => {
    i18n.changeLanguage(language);
    storage.setLanguage(language);
  };
}
