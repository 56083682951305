import storage from 'views/shared/utils/storage';
import { api } from 'views/shared/modules/api/request';

export interface ResponseData {
  redirectUrl: string;
}

export async function getLoginRedirectUrl(): Promise<ResponseData> {
  try {
    const { data } = await api.get<ResponseData>(
      '/auth/login-redirect-url',
      {
        headers: {
          Authorization: storage.getToken(),
        },
      }
    );

    return data;

  } catch (err) {
    return Promise.reject(err);
  }
}
