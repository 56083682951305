import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

// Analytics
import { AnalyticsProvider } from 'use-analytics';
import analytics from 'services/analytics';

import './services/i18n';
import './assets/css/base/index.css';
import App from './routes/index';
import reportWebVitals from './reportWebVitals';

if (['production', 'staging'].includes(process.env.REACT_APP_ENV || '')) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [new Integrations.BrowserTracing()],

    // 1.0 to capture 100% of transactions for performance monitoring
    tracesSampleRate: 1.0,
  });
}

ReactDOM.render(
  <React.StrictMode>
    <AnalyticsProvider instance={analytics}>
      <App />
    </AnalyticsProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

/* If you want to start measuring performance in your app, pass a function
   to log results (for example: reportWebVitals(console.log))
   or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals */
reportWebVitals();
