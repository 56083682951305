import { useLocation } from 'react-router-dom';
import { useQuery } from 'react-query';
//
import { API } from 'api';
//
import { FeedbackConfig, FeedbackResponse } from 'models/feedback';
import useLocales from 'views/shared/hooks/use-locales';
import { LocaleI } from 'models/locales';
import { ActivityClient } from 'models/activity';
import { useMatchConfigByRoute } from './useMatchConfigByRoute';

interface ReturnProps {
  data: FeedbackConfig<string> | null;
  isLoading: boolean;
}

type FeedBackConfigApi = {
  data: FeedbackConfig<LocaleI>[];
  message: string;
  activities: ActivityClient<FeedbackResponse>[];
};

export const useConfiguration = (): ReturnProps => {
  const {
    currentLang: { value },
  } = useLocales('feedback/feedback');

  const { pathname } = useLocation();

  const { data: feedbackConfig, isLoading } = useQuery(
    ['userFeedbackConfig'],
    () =>
      API.get<FeedBackConfigApi>('/configuration/query', {
        params: {
          target: 'application',
          area: 'feedback-request',
        },
      }),
    {
      staleTime: 1000 * 5, // 5 sec
      select: (data) => {
        return data.data
          .map((config) => ({
            ...config,
            payload: {
              ...config.payload,
              question: {
                ...config.payload.question,
                title: config.payload.question.title[value],
                subTitle: config.payload.question.subTitle?.[value],
              },
            },
          }))
          .filter((config) => {
            return !(
              data.activities.findIndex(
                (activity) => activity.payload.property === config.property && pathname === activity.payload.exactPath
              ) !== -1
            );
          }) as FeedbackConfig<string>[];
      },
    }
  );

  const { delayedConfig } = useMatchConfigByRoute(feedbackConfig || []);

  return {
    data: delayedConfig,
    isLoading,
  };
};
