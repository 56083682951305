import { getLoginRedirectUrl } from 'views/shared/modules/auth/api/get-login-redirect-url';
import storage from 'views/shared/utils/storage';

const omitQueryParams = (url: string): string => {
  return url.split('?')[0];
};

const withoutTrailingSlash = (url: string) => {
  return url.replace(/\/$/, '');
};

const cleanUpUrl = (url: string): string => {
  return withoutTrailingSlash(omitQueryParams(url));
};

export const loginRedirect = async () => {
  if (process.env.REACT_APP_REDIRECT_DISABLED === 'true') {
    return;
  }

  const { redirectUrl } = await getLoginRedirectUrl();

  const currentUrl = cleanUpUrl(window.location.host);
  const redirectUrlsDontMatch = cleanUpUrl(redirectUrl) !== cleanUpUrl(currentUrl);

  const wasRedirectedTo = storage.getWasRedirectedTo();

  if (redirectUrl && (!wasRedirectedTo || wasRedirectedTo.length === 0 || redirectUrlsDontMatch)) {
    storage.setWasRedirectedTo(cleanUpUrl(redirectUrl));

    const protocol = process.env.REACT_APP_ENV === 'development' ? 'http://' : 'https://';
    window.location.replace(`${protocol}${redirectUrl}`);
  }
};
