/* eslint-disable */
import { createSelector } from 'reselect';

export const selectCreateProject = (state) => state.createProject;
export const createProjectSelector = createSelector.bind(
  null,
  selectCreateProject
);

export const titleSelector = createProjectSelector((project) => project.title);
export const currentPageSelector = createProjectSelector(
  (project) => project.currentPage
);
export const categoryOptionsSelector = createProjectSelector(
  (project) => project.categoryOptions
);
export const categoriesSelector = createProjectSelector(
  (project) => project.categories
);
export const eventsSelector = createProjectSelector(
  (project) => project.events
);

export const selectTitleImage = createProjectSelector(
  ({ images }) => images.titleImage
);
export const selectUploadedImages = createProjectSelector(({ images }) =>
  images.uploadedImages.map((image) => image.url)
);
export const selectThemeImages = createProjectSelector(
  ({ images }) => images.themeImages
);

export const selectEventModal = createProjectSelector(
  (project) => project.eventModal
);

export const selectEvent = createSelector(
  [selectEventModal, eventsSelector],
  (eventModal, events) => {
    const { eventId } = eventModal;
    if (!eventId) return null;
    return events.find((event) => event._id === eventId) || null;
  }
);

export const selectPosition = createProjectSelector(
  ({ position, location }) => ({ position, location })
);

export const selectIsLocationIndependent = createProjectSelector(
  ({ isLocationIndependent }) => isLocationIndependent
);

export const selectInfo = createProjectSelector(
  ({ purpose, role, additionalInfo }) => ({ purpose, role, additionalInfo })
);

export const selectOptions = createProjectSelector(
  ({
    applicationRequired,
    maxParticipants,
    minimumAge,
    private: isPrivate,
  }) => ({
    applicationRequired,
    maxParticipants,
    minimumAge,
    private: isPrivate,
  })
);

export const selectDataToCreateProject = createProjectSelector((project) => {
  const {
    title,
    categories,
    images,
    permanentMembership,
    isLocationIndependent,
    location,
    position,
    events,
    role,
    purpose,
    additionalInfo,
    applicationRequired,
    maxParticipants,
    minimumAge,
    place,
    private: isPrivate,
  } = project;
  return {
    title,
    categories,
    images,
    permanentMembership,
    isLocationIndependent,
    location,
    position,
    events,
    role,
    purpose,
    additionalInfo,
    applicationRequired,
    maxParticipants,
    minimumAge,
    place,
    private: isPrivate,
  };
});
