/* eslint-disable */
import React from 'react';
import styled from 'styled-components';
import doIcon from 'assets/images/yes.svg';
import dontIcon from 'assets/images/no.svg';

const Icon = styled.div`
  height: 20px;
  width: 20px;
  background-size: 20px 20px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  margin-right: 10px;
`;

const DoIcon = styled(Icon)`
  background-image: url(${doIcon});
`;

const DontIcon = styled(Icon)`
  background-image: url(${dontIcon});
`;

const Title = styled.div`
  color: #8e8e93;
  font-size: 40px;
  font-weight: bold;
  margin-bottom: 20px;
  text-transform: uppercase;
`;

const Text = styled.div`
  color: #8e8e93;
  font-size: 20px;
  max-width: 275px;
`;

const SubTitle = styled(Text)`
  margin-bottom: 20px;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
`;

const TipRow = ({ children, Icon }) => (
  <Row>
    <Icon />
    <Text>{children}</Text>
  </Row>
);

const DoTip = ({ text }) => <TipRow Icon={DoIcon}>{text}</TipRow>;

const DontTip = ({ text }) => <TipRow Icon={DontIcon}>{text}</TipRow>;

const TipsWrapper = ({ title, subTitle, tips, type }) => {
  const Tip = type === 'do' ? DoTip : DontTip;

  return (
    <div>
      <Title>{title}</Title>
      <SubTitle>{subTitle}</SubTitle>
      {tips.map((text) => (
        <Tip key={text} text={text} />
      ))}
    </div>
  );
};

export default TipsWrapper;
