import { useEffect } from 'react';
import { useQueryParams, StringParam } from 'use-query-params';

// import { useAuth } from 'views/shared/modules/auth/service';
import storage from 'views/shared/utils/storage';

export default function useAutoLogin(): void {
  // const { refetchUser } = useAuth();

  const [queryParams, setQueryParams] = useQueryParams({
    token: StringParam,
  });

  useEffect(() => {
    if (queryParams.token) {
      console.log('use-auto-login =>', queryParams.token);

      storage.setToken(queryParams.token);

      // refetchUser();
      setQueryParams({}, 'push');
    }
  }, [queryParams.token]);
}
