import storage from 'views/shared/utils/storage';
import { api } from 'views/shared/modules/api/request';
import { User } from 'views/shared/modules/user/model';

export interface UserData {
  status: number;
  data: User;
}

export async function getUser(): Promise<User> {
  try {
    const { data: userData } = await api.post<UserData>(
      '/users/details',
      {},
      {
        headers: {
          Authorization: storage.getToken(),
        },
      }
    );

    if (userData.data !== undefined) {
      return userData.data;
    }

    return Promise.reject(userData);
  } catch (err) {
    return Promise.reject(err);
  }
}
