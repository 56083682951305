/* eslint-disable */
import React, { Component } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { Map, Marker } from 'google-maps-react';
import { Switch } from 'antd';
import isEmpty from 'lodash/isEmpty';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

import {
  setLocation as setLocationRedux,
  redirectToPreviousPage as redirectToPreviousPageRedux,
} from 'views/create-project-old/store/ducks/createProject/actions';
import {
  selectPosition,
  selectIsLocationIndependent,
} from 'views/create-project-old/store/ducks/createProject/selectors';

import Button, { BackButton } from '../../common/Button';

const Wrapper = styled.div`
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
`;

const Input = styled.input`
  width: 100%;
  height: 50px;
  background: #f6f7f9;
  border-radius: 15px;
  text-align: center;
  font-size: 16px;
  border: none;
  outline: none;
  margin: 30px 0;
`;

const SwitchWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 25px;
`;

const SwitchText = styled.div`
  font-size: 12px;
  color: #8e8e93;
  width: 285px;
  margin-right: 15px;
`;

const ButtonWrapper = styled.div`
  width: 335px;
  height: 56px;
`;

const ButtonsWrapper = styled.div`
  width: 750px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 45px auto 0;
`;

class LocationSearch extends Component {
  constructor(props) {
    super(props);

    this.autocomplete = null;
    this.state = {
      position: null,
      placeName: '',
      isLocationIndependent: false,
      place: {},
      isMapped: false,
    };
  }

  static getDerivedStateFromProps(props, state) {
    let newState = {};

    if (!state.position && !state.isMapped) {
      if (props.isLocationIndependent) {
        return {
          ...newState,
          isMapped: true,
          isLocationIndependent: true,
        };
      }

      const { position, location } = props.defaultLocation;
      newState = {
        ...newState,
        isMapped: true,
        position,
        placeName: location,
      };
    }

    return newState;
  }

  componentDidUpdate(prevProps) {
    const { map } = this.props;

    if (!prevProps.map && map) {
      this.initializeMap(this.props);
    }
  }

  initializeMap = ({ map }) => {
    this.autocomplete.bindTo('bounds', map);
    this.autocomplete.addListener('place_changed', () => {
      const place = this.autocomplete.getPlace();
      if (!place.geometry) return;

      if (place.geometry.viewport) {
        map.fitBounds(place.geometry.viewport);
      } else {
        map.setCenter(place.geometry.location);
        map.setZoom(17);
      }

      const {
        location: { lat, lng },
      } = place.geometry;
      this.setState({
        place,
        position: {
          lat: lat(),
          lng: lng(),
        },
        placeName: this.autoInput.value,
      });
    });
  };

  loadMap = () => {
    const { google } = this.props;
    // eslint-disable-next-line no-undef
    const input = window.document.getElementById('location-input');

    if (!google || !input) return;

    this.autoInput = input;
    this.autocomplete = new google.maps.places.Autocomplete(input);
  };

  handleInputChange = (e) => {
    this.setState({ placeName: e.target.value });
  };

  handleSwitchChange = (checked) => {
    this.setState({ isLocationIndependent: checked });
  };

  handleSubmit = () => {
    const { placeName, position, isLocationIndependent, place } = this.state;
    const { setLocationRedux: setLocation } = this.props;

    if (isLocationIndependent) {
      setLocation({ isLocationIndependent });
    } else {
      setLocation({
        location: placeName,
        position,
        place,
      });
    }
    this.props.history.push('/create-project/description');
  };

  render() {
    const { placeName, position, isLocationIndependent } = this.state;
    const { redirectToPreviousPageRedux: redirectToPreviousPage } = this.props;

    return (
      <Wrapper ref={this.loadMap}>
        <Input
          value={placeName}
          onChange={this.handleInputChange}
          id="location-input"
          placeholder={this.props.t('createProjectOld.locationholder')}
        />
        <Map
          {...this.props}
          center={position}
          disableDefaultUI
          centerAroundCurrentLocation={false}
          containerStyle={{
            height: '250px',
            position: 'relative',
            width: '100%',
          }}>
          <Marker position={position} />
        </Map>
        <SwitchWrapper>
          <SwitchText>
            {this.props.t('createProjectOld.steps.setLocation.remote')}
          </SwitchText>
          <Switch
            checked={isLocationIndependent}
            onChange={this.handleSwitchChange}
          />
        </SwitchWrapper>
        <ButtonsWrapper>
          <ButtonWrapper>
            <BackButton
              onClick={() => {
                this.props.history.push('/create-project/set-time');
              }}>
              {this.props.t('createProjectOld.back')}
            </BackButton>
          </ButtonWrapper>
          <ButtonWrapper>
            <Button
              disabled={!isLocationIndependent && isEmpty(position)}
              onClick={this.handleSubmit}>
              {this.props.t('createProjectOld.next')}
            </Button>
          </ButtonWrapper>
        </ButtonsWrapper>
      </Wrapper>
    );
  }
}

const mapState = (state) => ({
  defaultLocation: selectPosition(state),
  isLocationIndependent: selectIsLocationIndependent(state),
});

const mapDispatch = {
  setLocationRedux,
  redirectToPreviousPageRedux,
};

export default connect(
  mapState,
  mapDispatch
)(withTranslation()(withRouter(LocationSearch)));
