import { useEffect } from 'react';
import { useAnalytics } from 'use-analytics';
import { useAuth } from 'views/shared/modules/auth/service';
import { useCurrentOrganizationAndGroup } from 'views/shared/hooks/use-current-organization-and-group';

// Record project name
export const useIdentifyUser = () => {
  const { user } = useAuth();
  const { group, role, organization } = useCurrentOrganizationAndGroup();
  const { identify } = useAnalytics();
  useEffect(() => {
    if (user?._id) {
      identify(user._id, {
        id: user._id,
        groupId: group?._id,
        role,
        organizationName: organization?.name,
        firstName: user.firstName,
        lastName: user.lastName,
      });
    }
  }, [user?._id, group?._id]);
};
