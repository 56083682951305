import { useEffect, useRef } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useAuth } from 'views/shared/modules/auth/service';

import useFilterSideMenu from 'views/shared/components/filter-side-menu-by-domain/use-filter-side-menu-by-domain';
import { useCurrentOrganizationAndGroup } from 'views/shared/hooks/use-current-organization-and-group';

export default function useAutoRedirectCustomDomain() {
  const { user } = useAuth();

  const wasRedirected = useRef(false);
  const { domainSettings, loadingDomain } = useFilterSideMenu();
  const { role } = useCurrentOrganizationAndGroup();
  const { pathname } = useLocation();
  const history = useHistory();

  useEffect(() => {
    const isAtHome = ['/dashboard'].includes(pathname);

    const redirect = () => {
      if (!loadingDomain && user && isAtHome && role !== 'admin' && domainSettings !== undefined && !wasRedirected.current) {
        if (domainSettings !== null) {
          history.push({ pathname: domainSettings.defaultRedirect });
          wasRedirected.current = true;
        } else {
          history.push({ pathname: '/' });
        }
      }
    };
    redirect();
  }, [loadingDomain, user, pathname]);
}
