import { AnyAction } from 'redux';

/**
 * action types
 */

export const SET_FILTERS = 'SET_FILTERS';
export const SET_COUNT_FILTERS = 'SET_COUNT_FILTERS';

/**
 * reducer
 */

export interface VisibilityType {
  status?: string;
  type?: 'all' | 'group';
  groupId?: string;
}

export interface FiltersSet {
  categories: string[] | undefined;
  categoryLabels?: string[] | undefined;
  remoteExclude: boolean;
  importExclude: boolean;
  repeatable: 'false' | boolean | undefined;
  lat?: number;
  lng?: number;
  address?: string;
  distance?: number;
  visibilityType: VisibilityType;
}

interface FiltersState {
  filters: FiltersSet;
  countFilters: FiltersSet;
}

const initialState: FiltersState = {
  filters: {
    categories: undefined,
    categoryLabels: undefined,
    remoteExclude: false,
    importExclude: false,
    repeatable: undefined,
    address: undefined,
    lat: undefined,
    lng: undefined,
    distance: 25,
    visibilityType: { type: 'all' },
  },
  countFilters: {
    categories: undefined,
    categoryLabels: undefined,
    remoteExclude: false,
    importExclude: false,
    repeatable: undefined,
    address: undefined,
    lat: undefined,
    lng: undefined,
    distance: 25,
    visibilityType: { type: 'all' },
  },
};

export default function reducer(
  state = initialState,
  action: AnyAction
): FiltersState {
  switch (action.type) {
    case SET_FILTERS:
      return {
        ...state,
        filters: { ...state.filters, ...action.filters },
      };
    case SET_COUNT_FILTERS:
      return {
        ...state,
        countFilters: { ...state.countFilters, ...action.countFilters },
      };
    default:
      return state;
  }
}

/**
 * action creators
 */

export function setFilters(filters: Partial<FiltersSet>): {
  type: string;
  filters: Partial<FiltersSet>;
} {
  return {
    type: SET_FILTERS,
    filters,
  };
}

export function setCountFilters(countFilters: Partial<FiltersSet>): {
  type: string;
  countFilters: Partial<FiltersSet>;
} {
  return {
    type: SET_COUNT_FILTERS,
    countFilters,
  };
}
