import React from 'react';

import useLoadCurrentGroupContext from './use-load-current-group-context';

const LoadCurrentGroupContext: React.FC = () => {
  useLoadCurrentGroupContext();
  return null;
};

export default LoadCurrentGroupContext;
