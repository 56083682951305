export function truncate(
  str: string,
  maxLength: number,
  useWordBoundary: boolean
): string {
  if (!str) {
    return '';
  }

  if (str.length <= maxLength) {
    return str;
  }

  const subString = str.substr(0, maxLength - 1);

  const truncatedStr = useWordBoundary
    ? subString.substr(0, subString.lastIndexOf(' '))
    : subString;

  return `${truncatedStr}...`;
}

export const chunk = <T>(arr: T[], size: number): T[][] =>
  Array.from({ length: Math.ceil(arr.length / size) }, (v, i) =>
    arr.slice(i * size, i * size + size)
  );

export function ensure<T>(
  arg: T | undefined | null,
  message = 'This value was promised to be there.'
): T {
  if (arg === undefined || arg === null) {
    throw new TypeError(message);
  }

  return arg;
}

export function capitalize(s: string): string {
  return s.charAt(0).toUpperCase() + s.slice(1);
}

export function intersection<T>(arr1: T[], arr2: T[]): T[] {
  return arr1.filter((value) => arr2.includes(value));
}

export function ordinalSuffixOf(n: number): string {
  const nMod10 = n % 10;
  const nMod100 = n % 100;

  if (nMod10 === 1 && nMod100 !== 11) {
    return `${n}st`;
  }
  if (nMod10 === 2 && nMod100 !== 12) {
    return `${n}nd`;
  }
  if (nMod10 === 3 && nMod100 !== 13) {
    return `${n}rd`;
  }
  return `${n}th`;
}


export const checkIfIsLetsact = (): boolean => window.location.hostname === process.env.REACT_APP_LETSACT_APP_HOSTNAME;
