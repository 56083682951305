import { useState, FC, Dispatch, SetStateAction, ReactElement, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Box, Paper, Stack, TextField, Typography } from '@mui/material';
import Zoom from '@mui/material/Zoom';
//
import useLocales from 'views/shared/hooks/use-locales';
import { EmojiPicker } from 'views/shared/components/emoji-picker/emoji-picker';
import { FeedbackConfigPayload, FeedbackResponse, PathnameTypes, QuestionType } from 'models/feedback';
import { CustomButton } from 'views/shared/components/custom-button/button';
import { Iconify } from 'views/shared/components/iconify';
import { getFeedbackActivityType } from 'views/shared/hooks/use-path-name';
//
import { RatingType } from './feedback-emoji';
import { ThumbType } from './feedback-thumbs';
import { useSubmitFeedback } from './hooks/useSubmitFeedback';

const INITIAL_STATE: Omit<FeedbackResponse, 'property'> = {
  area: 'feedback-request',
  type: 'thumb',
  popupType: 'snackbar',
  rating: null,
};

export interface Props {
  handleClose: VoidFunction;
  configuration: FeedbackConfigPayload<string>;
  property: PathnameTypes;
}

export const Feedback: FC<Props> = ({ configuration, property, handleClose }) => {
  const { translate: t } = useLocales('feedback/feedback');
  const { pathname } = useLocation();
  const [response, setResponse] = useState<FeedbackResponse>({ ...INITIAL_STATE, property });

  const { handleSubmitFeedback, isLoading } = useSubmitFeedback();

  const {
    closable,
    question: { comment: askForComment, title, subTitle, type },
  } = configuration;

  const handleChangeComment = (newComment: string) => {
    setResponse((prev) => ({
      ...prev,
      text: newComment,
    }));
  };

  const handleAddEmoji = (emoji: string) => {
    handleChangeComment((response.text || '') + emoji);
  };

  const handleSubmit = async () => {
    handleSubmitFeedback({
      ...getFeedbackActivityType(pathname),
      payload: {
        ...response,
        exactPath: pathname,
      },
    });
    handleClose();
  };

  useEffect(() => {
    console.log(response.rating);
  }, [response.rating]);

  const QuestionTypeElement = getQuestionTypeElement({ response, setResponse, type });

  return (
    <Paper sx={{ p: 2, width: '100%' }}>
      <Stack gap={2} maxWidth={450}>
        <Typography textAlign="center" variant="h3">
          Hey! 👋 <br /> {title}
        </Typography>
        <Typography textAlign="center" variant="body2" color="text.secondary">
          {subTitle}
        </Typography>
        {QuestionTypeElement}
        {askForComment && (
          <Zoom in={response.rating !== null} timeout={350}>
            <Box sx={{ position: 'relative' }}>
              <TextField
                sx={{ display: response.rating === null ? 'none' : 'flex' }}
                value={response.text || ''}
                variant="filled"
                onChange={(e) => handleChangeComment(e.target.value)}
                multiline
                label={t('comment.label')}
                placeholder={`${t('comment.placeholder')}...`}
                rows={4}
              />
              <EmojiPicker
                sx={{
                  position: 'absolute',
                  right: 4,
                  bottom: 4,
                }}
                emojis={{}}
                icon="fluent:emoji-add-16-regular"
                handleEmojiClicked={handleAddEmoji}
              />
            </Box>
          </Zoom>
        )}
        <CustomButton
          loading={isLoading}
          size="large"
          disabled={response.rating === null}
          handleClick={handleSubmit}
          label={t('actions.submit')}
          endIcon={<Iconify icon="iconamoon:arrow-right-2-duotone" />}
        />
        {closable && (
          <CustomButton
            sx={{ alignSelf: 'center' }}
            loading={isLoading}
            size="medium"
            color="secondary"
            variant="text"
            handleClick={handleClose}
            label={t('actions.later')}
          />
        )}
      </Stack>
    </Paper>
  );
};

export type ResponseProps = { response: FeedbackResponse; setResponse: Dispatch<SetStateAction<FeedbackResponse>> };

const getQuestionTypeElement: FC<ResponseProps & { type: QuestionType }> = ({
  response,
  setResponse,
  type,
}): ReactElement => {
  switch (type) {
    case 'thumb':
      return <ThumbType response={response} setResponse={setResponse} />;
    case 'emoji':
      return <RatingType response={response} setResponse={setResponse} />;
    default:
      return <></>;
  }
};
