import storage from 'views/shared/utils/storage';
import { api } from 'views/shared/modules/api/request';
import { useQuery, UseQueryResult } from 'react-query';

import { Organization } from 'views/shared/modules/organization/model';
import { GroupPopulated } from 'views/shared/modules/group/model';

export interface OrganizationsData {
  docs: Organization[];
  limit: number;
  page: number;
  pages: number;
}

export async function getOrganizations(queryParams: { [key: string]: string | string[] }): Promise<Organization[]> {
  try {
    const res = await api.get<OrganizationsData>('/organizations', {
      headers: {
        Authorization: storage.getToken(),
      },
      params: queryParams,
    });

    const { data: organizationsData } = res;

    return Promise.resolve(organizationsData.docs);
  } catch (err) {
    return Promise.reject(err);
  }
}

export async function getMyOrganizations(queryParams: { [key: string]: string | string[] }): Promise<GroupPopulated[]> {
  try {
    const res = await api.get<GroupPopulated[]>('/organizations/my', {
      headers: {
        Authorization: storage.getToken(),
      },
      params: queryParams,
    });

    const { data: organizationsData } = res;

    return Promise.resolve(organizationsData);
  } catch (err) {
    return Promise.reject(err);
  }
}

export function useGetOrgByHostname(hostname: string | null | undefined): UseQueryResult<Organization[], unknown> {
  return useQuery(
    ['orgByHostname', hostname],
    () =>
      getOrganizations({
        'filters[customDomain]': hostname || '',
      }),
    {
      enabled: !!hostname,
    }
  );
}

export function useGetOrganizations(ids: string[]): UseQueryResult<Organization[], unknown> {
  return useQuery(['myOrganizations', ids], () => getOrganizations({ 'filters[id__in]': ids }), {
    enabled: ids.length > 0,
  });
}

export function useGetMyOrganizations(): UseQueryResult<GroupPopulated[], unknown> {
  return useQuery(['myOrganizations'], () => getMyOrganizations({}));
}
