import { AnyAction } from 'redux';
// import { CurrentStep } from './use-sign-up';

/**
 * action types
 */

export const SHOW_SIGN_UP = 'SHOW_SIGN_UP';
export const SHOW_SIGN_IN = 'SHOW_SIGN_IN';
export const HIDE_SIGN_UP = 'HIDE_SIGN_UP';
// Assuming you have other state slices, include them in this interface
/**
 * reducer
 */

interface SignUpAction {
  type: string;
  email?: string; // optional email field
}
interface SignUpState {
  isVisible: boolean;
  isSignUp: boolean;
  email: string; // add email to the state
}

const initialState: SignUpState = {
  isVisible: false,
  isSignUp: true,
  email: '', // add email to the state
};

export default function reducer(state = initialState, action: AnyAction): SignUpState {
  switch (action.type) {
    case SHOW_SIGN_UP:
      return {
        ...state,
        isVisible: true,
        isSignUp: true,
        email: action.email || state.email,
        // currentStep: action.email ? 'nextStep' : 'enterEmail', // update currentStep based on email
      };
    case SHOW_SIGN_IN:
      return {
        ...state,
        isVisible: true,
        isSignUp: false,
        email: action.email || state.email,
      };
    case HIDE_SIGN_UP:
      return {
        ...state,
        isVisible: false,
      };
    default:
      return state;
  }
}

/**
 * action creators
 */

export function showSignUp(email?: string): SignUpAction {
  return {
    type: SHOW_SIGN_UP,
    email,
  };
}

export function showSignIn(email?: string): SignUpAction {
  return {
    type: SHOW_SIGN_IN,
    email,
  };
}

export function hideSignUp(): { type: string } {
  return {
    type: HIDE_SIGN_UP,
  };
}
