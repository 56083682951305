export const getTokenPayload = (token) => {
  if (!token || token.length === 0) throw new Error('Token undefined or invalid.');

  try {
    const jwt = JSON.parse(atob(token.split('.')[1]));
    // console.log(jwt);
    return jwt;
  } catch (error) {
    // console.log(`error: ${error}`);
    return error;
  }
};
