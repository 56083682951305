import { useConfiguration } from './hooks/useConfiguration';
import { FeedbackModal } from './feedback-modal';
import { FeedbackSnackbar } from './feedback-snackbar';

const UserFeedbackProvider = () => {
  const { data } = useConfiguration();

  if (!data) return null;

  const { popupType } = data.payload;

  if (popupType === 'modal') return <FeedbackModal property={data.property} configuration={data.payload} open />;

  return <FeedbackSnackbar position="bottom-center" property={data.property} configuration={data.payload} open />;
};

export default UserFeedbackProvider;
