import { get } from './get';
import { post } from './post';
import { patch } from './patch';
import { deleteReq } from './delete';

export const API = {
  get,
  post,
  delete: deleteReq,
  patch,
};
