import storage from 'views/shared/utils/storage';
import { api } from 'views/shared/modules/api/request';

export interface ResData {
  token: string;
  // firebaseToken: string;
}

export async function refreshToken(groupId: string | undefined): Promise<ResData> {
  try {
    const { data } = await api.post<ResData>(
      '/auth/refreshToken',
      { groupId },
      {
        headers: {
          Authorization: storage.getToken(),
        },
      }
    );

    storage.setToken(data.token);
    // storage.setFirebaseToken(data.firebaseToken);

    return Promise.resolve(data);
  } catch (err) {
    return Promise.reject(err);
  }
}
