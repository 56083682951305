/* eslint-disable */
import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import plusIcon from 'assets/images/plus.svg';
import { formInput as formInputRedux } from 'views/create-project-old/store/ducks/createProject/actions';

const ImageInputLabel = styled.label`
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  width: 325px;
  height: 250px;
  box-shadow: 5px 7px 30px rgba(0, 0, 0, 0.07);
  border-radius: 9px;
  background-color: #fff;
  cursor: pointer;
  transition: box-shadow 0.4s;

  :hover {
    box-shadow: 5px 7px 45px rgba(0, 0, 0, 0.2);
  }
`;

const ImageInputText = styled.div`
  font-size: 20px;
  font-weight: bold;
  color: #14161d;
  margin-top: 20px;
`;

const ImageInputIcon = styled.div`
  height: 37px;
  width: 37px;
  background-image: url(${plusIcon});
`;

const Input = styled.input`
  opacity: 0;
`;

function UserImageUploader({ formInputRedux: formInput }) {
  const { t } = useTranslation();
  const handleImageSelect = (e) => {
    if (!e.target.files.length) return;

    formInput({
      field: 'userImages',
      value: e.target.files,
    });
  };

  return (
    <div>
      <Input
        onChange={handleImageSelect}
        id="upload-image"
        type="file"
        accept="image/*"
        multiple
      />
      <ImageInputLabel htmlFor="upload-image">
        <ImageInputIcon />
        <ImageInputText>
          {t('createProjectOld.steps.setTitleImage.upload')}
        </ImageInputText>
      </ImageInputLabel>
    </div>
  );
}

const mapDispatch = {
  formInputRedux,
};

export default connect(null, mapDispatch)(UserImageUploader);
