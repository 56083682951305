import { AnyAction } from 'redux';

/**
 * action types
 */

export const SET_PROJECT_ID = 'SET_PROJECT_ID';
export const TOGGLE_CALENDAR_EVENT = 'TOGGLE_CALENDAR_EVENT';
export const SELECT_ALL_CALENDAR_EVENT_GROUPS = 'SELECT_ALL_CALENDAR_EVENT_GROUPS';
export const CLEAR_APPLICATION_FORM = 'CLEAR_APPLICATION_FORM';

/**
 * reducer
 */

export type CalendarEventsSelector =
  | { type: 'all' }
  | {
      // Join only selected events
      type: 'only';
      calendarEvents: {
        calendarEventId: string;
      }[];
    };

export type CalendarEventGroup = {
  calendarEventGroupId: string;
  calendarEvents: CalendarEventsSelector;
};

interface State {
  projectId: string | undefined;
  calendarEventGroups: CalendarEventGroup[];
}

const initialState: State = {
  projectId: undefined,
  calendarEventGroups: [],
};

export default function reducer(
  state = initialState,
  action: AnyAction
): State {
  switch (action.type) {
    case SET_PROJECT_ID:
      return {
        ...state,
        projectId: action.projectId,
      };
    case TOGGLE_CALENDAR_EVENT:
      return {
        ...state,
        calendarEventGroups: reduceToggleCalendarEvent(
          state.calendarEventGroups,
          action.calendarEventGroupId,
          action.calendarEventId,
        ),
      };
    case SELECT_ALL_CALENDAR_EVENT_GROUPS:
      return {
        ...state,
        calendarEventGroups: [],
      };
    case CLEAR_APPLICATION_FORM:
      return {
        ...state,
        projectId: undefined,
        calendarEventGroups: [],
      };
    default:
      return state;
  }
}

/**
 * action creators
 */

export function setProjectId(projectId: string): {
  type: string;
  projectId: string;
} {
  return {
    type: SET_PROJECT_ID,
    projectId,
  };
}

export function toggleCalendarEvent(
  calendarEventGroupId: string,
  calendarEventId: string,
): {
  type: string;
  calendarEventGroupId: string;
  calendarEventId: string;
} {
  return {
    type: TOGGLE_CALENDAR_EVENT,
    calendarEventGroupId,
    calendarEventId,
  };
}

export function selectAllCalendarEventGroups(): { type: string; } {
  return {
    type: SELECT_ALL_CALENDAR_EVENT_GROUPS,
  };
}

export function clearApplicationForm(): {
  type: string;
} {
  return {
    type: CLEAR_APPLICATION_FORM,
  };
}

/**
 * utils
 */

const reduceToggleCalendarEvent = (
  calendarEventGroups: CalendarEventGroup[],
  calendarEventGroupId: string,
  calendarEventId: string,
): CalendarEventGroup[] => {
  const selectedCalendarEventGroup = calendarEventGroups.find((ceg) =>
    ceg.calendarEventGroupId === calendarEventGroupId,
  );

  // If the calendar event group is not selected at all,
  // add it and select the given event
  if (!selectedCalendarEventGroup) {
    return [
      ...calendarEventGroups,
      {
        calendarEventGroupId,
        calendarEvents: {
          type: 'only',
          calendarEvents: [{
            calendarEventId,
          }],
        },
      },
    ];
  }

  // If there is a calendar event group with all events selected,
  // dismiss all events and select only the given event
  if (selectedCalendarEventGroup.calendarEvents.type === 'all') {
    return [
      ...calendarEventGroups.filter((ceg) =>
        ceg.calendarEventGroupId !== calendarEventGroupId,
      ),
      {
        calendarEventGroupId,
        calendarEvents: {
          type: 'only',
          calendarEvents: [{
            calendarEventId,
          }],
        },
      },
    ];
  }

  // Find if the event is already selected
  const selectedCalendarEvent = selectedCalendarEventGroup
    .calendarEvents
    .calendarEvents
    .find((ce) => ce.calendarEventId === calendarEventId);

  // If event is already selected, deselect it
  if (selectedCalendarEvent) {
    const selectedCalendarEvents = selectedCalendarEventGroup
      .calendarEvents
      .calendarEvents
      .filter((ce) => ce.calendarEventId !== calendarEventId);

      // If the list is empty after deselecting an event,
      // remove the whole calendar event group from the list
    if (selectedCalendarEvents.length === 0) {
      return [
        ...calendarEventGroups.filter((ceg) =>
          ceg.calendarEventGroupId !== calendarEventGroupId,
        ),
      ];
    }

    // Otherwise, return the updated calendar event group with the given
    // event diselected
    return [
      ...calendarEventGroups.filter((ceg) =>
        ceg.calendarEventGroupId !== calendarEventGroupId,
      ),
      {
        calendarEventGroupId,
        calendarEvents: {
          type: 'only',
          calendarEvents: selectedCalendarEvents,
        },
      },
    ];
  }

  return [
    ...calendarEventGroups.filter((ceg) =>
      ceg.calendarEventGroupId !== calendarEventGroupId,
    ),
    {
      calendarEventGroupId,
      calendarEvents: {
        type: 'only',
        calendarEvents: [
          ...selectedCalendarEventGroup.calendarEvents.calendarEvents,
          { calendarEventId },
        ],
      },
    },
  ];
};
