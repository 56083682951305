import { m, MotionProps } from 'framer-motion';
// @mui
import { Box, BoxProps, Stack } from '@mui/material';
//
import { varFade } from './variants';

// ----------------------------------------------------------------------

type Props = BoxProps & MotionProps;

interface TextAnimateProps extends Props {
  text: string;
}

export default function TextAnimate({ text, variants, sx, ...other }: TextAnimateProps) {
  const words = text.split(' ');
  return (
    <Stack direction="row" gap={1} flexWrap="wrap">
      {words.map((w, i) => {
        return (
          <Box
            key={i}
            component={m.h1}
            sx={{
              typography: 'h1',
              overflow: 'hidden',
              display: 'inline-flex',
              ...sx,
            }}
            {...other}
          >
            {[...w].map((letter, index) => {
              return (
                <m.span key={index} variants={variants || varFade().inUp}>
                  {letter}
                </m.span>
              );
            })}
          </Box>
        );
      })}
    </Stack>
  );
}
