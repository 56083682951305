import { AnyAction } from 'redux';

/**
 * action types
 */

export const SHOW_INFO_MESSAGE = 'SHOW_INFO_MESSAGE';
export const HIDE_INFO_MESSAGE = 'HIDE_INFO_MESSAGE';

/**
 * reducer
 */

export type MsgType = 'success' | 'error' | 'info';

interface InfoMessageState {
  isVisible: boolean;
  text: string;
  msgType: MsgType;
  iconName: string | undefined;
  iconColor: string | undefined;
  iconSize: string;
  closeTimeout: number | undefined;
}

const initialState: InfoMessageState = {
  isVisible: false,
  text: '',
  msgType: 'success',
  iconName: undefined,
  iconColor: undefined,
  iconSize: '24',
  closeTimeout: undefined,
};

export default function reducer(
  state = initialState,
  action: AnyAction
): InfoMessageState {
  switch (action.type) {
    case SHOW_INFO_MESSAGE:
      return {
        ...state,
        isVisible: true,
        text: action.text,
        msgType: action.msgType,
        iconName: action.iconName,
        iconColor: action.iconColor,
        iconSize: action.iconSize,
        closeTimeout: action.closeTimeout,
      };
    case HIDE_INFO_MESSAGE:
      return {
        ...state,
        isVisible: false,
        text: '',
        msgType: 'success',
        iconName: undefined,
        iconColor: undefined,
        iconSize: '24',
        closeTimeout: undefined,
      };
    default:
      return state;
  }
}

/**
 * action creators
 */

export function showInfoMessage({
  text,
  msgType,
  iconName,
  iconColor,
  iconSize,
  closeTimeout,
}: {
  text: string;
  msgType?: MsgType;
  iconName?: string | undefined;
  iconColor?: string | undefined;
  iconSize?: string;
  closeTimeout?: number | undefined;
}): {
  type: string;
  msgType: MsgType;
  text: string;
  iconName?: string | undefined;
  iconColor?: string | undefined;
  iconSize?: string;
  closeTimeout: number | undefined;
} {
  return {
    type: SHOW_INFO_MESSAGE,
    msgType: msgType || 'success',
    text,
    iconName,
    iconColor,
    iconSize: iconSize || "24",
    closeTimeout,
  };
}

export function hideInfoMessage(): { type: string } {
  return {
    type: HIDE_INFO_MESSAGE,
  };
}
