import { Reaction } from 'views/community-feed/models/event';

const getCurrentUserReaction = (currentUserId: string, reactions: Reaction[]) => {
  return reactions.find((r) => r.user._id === currentUserId);
};

export const isOwnReaction = (currentUserId: string, reactions: Reaction[]) => {
  const reaction = getCurrentUserReaction(currentUserId, reactions);
  if (!reaction) return false;
  return reaction.user._id === currentUserId;
};
