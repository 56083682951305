import Analytics from 'analytics';
// Plugins
import { googleAnalyticsV4, amplitudeAnalytics } from './plugins';

const analytics = Analytics({
  app: 'LetsAct Web',
  debug: process.env.REACT_APP_ENV === 'development',
  plugins: [googleAnalyticsV4, amplitudeAnalytics],
});

export default analytics;
