import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useAuth } from 'views/shared/modules/auth/service';

import {
  loadCurrentGroupIdFromLocalStorage,
  loadCurrentOrganizationIdFromLocalStorage,
  clearCurrentGroupId,
  clearCurrentOrganizationId,
} from 'views/shared/modules/user/user-redux';

export default function useLoadCurrentGroupContext(): void {
  const dispatch = useDispatch();
  const { user } = useAuth();

  useEffect(() => {
    dispatch(loadCurrentGroupIdFromLocalStorage());
    dispatch(loadCurrentOrganizationIdFromLocalStorage());
  }, []); 

  useEffect(() => {
    if (!user) {
      dispatch(clearCurrentGroupId());
      dispatch(clearCurrentOrganizationId());
    }
  }, [user]);
}
