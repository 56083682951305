import React from 'react';

import useLoginRedirect from './use-login-redirect';

const LoginRedirect: React.FC = () => {
  useLoginRedirect();
  return null;
};

export default LoginRedirect;
