/* eslint-disable @typescript-eslint/no-explicit-any */
import { api } from 'views/shared/modules/api/request';
import storage from 'views/shared/utils/storage';
// Models
import { AxiosRequestConfig } from 'axios';

export const post = async <T>(
  url: string,
  sendingData?: Record<string, any>,
  config?: AxiosRequestConfig | undefined
) => {
  try {
    api.defaults.headers.common.Authorization = storage.getToken();
    const { data } = await api.post<T>(url, sendingData, config);
    return data;
  } catch (e) {
    return Promise.reject(e);
  }
};
