/* eslint-disable */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Input as AntInput, Select as AntSelect } from 'antd';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

import PageTitle from '../../common/PageTitle';
import PageSubTitle from '../../common/PageSubTitle';
import Button, { BackButton } from '../../common/Button';

import {
  setTimeAWeek,
  redirectToNextPage,
  deleteEvents,
} from 'views/create-project-old/store/ducks/createProject/actions';
import { eventsSelector } from 'views/create-project-old/store/ducks/createProject/selectors';
import get from 'lodash/get';

const { Option } = AntSelect;

const Wrapper = styled.div`
  width: 100%;
  max-width: 1200px;
`;

const InputWrapper = styled.div`
  margin-top: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Input = styled(AntInput)`
  width: 100px;
  height: 100px;
  font-size: 40px;
  text-align: center;

  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

const Text = styled.div`
  font-size: 40px;
  font-weight: bold;
  color: #000;
  margin: 0 30px;
`;

const Select = styled(AntSelect)`
  width: 300px;
  height: 100px;

  .ant-select-selection--single {
    padding: 30px 100px;
    font-size: 40px;
    height: auto;
    width: 350px;
  }
`;

const ButtonWrapper = styled.div`
  width: 335px;
  height: 56px;
`;

const ButtonsWrapper = styled.div`
  width: 750px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 150px auto;
`;

class TimeAWeek extends Component {
  state = {
    frequency: 'weekly',
    hours: 0,
    isMappedFromProps: false,
  };

  static getDerivedStateFromProps(props, state) {
    if (!state.isMappedFromProps) {
      const event = get(props.events, '[0]', {});
      if (!event.timeAWeek) return null;
      return {
        isMappedFromProps: true,
        hours: event.opp_custom_hours,
        frequency: event.opp_custom_frequency,
      };
    }
    return null;
  }

  handleHoursInput = (e) => {
    this.setState({ hours: e.target.value });
  };

  handleFrequencyChange = (value) => {
    this.setState({ frequency: value });
  };

  handleSubmit = () => {
    const { hours, frequency } = this.state;
    this.props.setTimeAWeek({ hours, frequency });
    this.props.history.push('/create-project/location');
  };

  render() {
    return (
      <Wrapper>
        <PageTitle>
          {this.props.t('createProjectOld.steps.setTime.title')}
        </PageTitle>
        <PageSubTitle>
          {this.props.t('createProjectOld.steps.setTime.custom.subtitle')}
        </PageSubTitle>
        <InputWrapper>
          <Input
            onChange={this.handleHoursInput}
            type="number"
            value={this.state.hours}
          />
          <Text>
            {this.props.t('createProjectOld.steps.setTime.custom.hours')}
          </Text>
          <Select
            defaultValue={this.state.frequency}
            value={this.state.frequency}
            onChange={this.handleFrequencyChange}>
            <Option value="daily">
              {this.props.t('createProjectOld.steps.setTime.custom.day')}
            </Option>
            <Option value="weekly">
              {this.props.t('createProjectOld.steps.setTime.custom.week')}
            </Option>
            <Option value="monthly">
              {this.props.t('createProjectOld.steps.setTime.custom.month')}
            </Option>
            <Option value="yearly">
              {this.props.t('createProjectOld.steps.setTime.custom.year')}
            </Option>
          </Select>
        </InputWrapper>
        <ButtonsWrapper>
          <ButtonWrapper>
            <BackButton
              onClick={() => {
                this.props.deleteEvents();
                this.props.history.push('/create-project/set-time');
              }}>
              {this.props.t('createProjectOld.back')}
            </BackButton>
          </ButtonWrapper>
          <ButtonWrapper>
            <Button
              disabled={this.state.hours <= 0}
              onClick={this.handleSubmit}>
              {this.props.t('createProjectOld.next')}
            </Button>
          </ButtonWrapper>
        </ButtonsWrapper>
      </Wrapper>
    );
  }
}

const mapState = (state) => ({
  events: eventsSelector(state),
});

const mapDispatch = {
  setTimeAWeek,
  redirectToNextPage,
  deleteEvents,
};

export default connect(
  mapState,
  mapDispatch
)(withTranslation()(withRouter(TimeAWeek)));
