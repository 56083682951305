/* eslint-disable */
import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Calendar } from 'antd';
import moment from 'moment';
import { withRouter, useHistory } from 'react-router-dom';

import {
  openEventModal,
  redirectToNextPage,
  deleteEvents,
} from 'views/create-project-old/store/ducks/createProject/actions';
import { eventsSelector } from 'views/create-project-old/store/ducks/createProject/selectors';

import PageTitle from '../../common/PageTitle';
import PageSubTitle from '../../common/PageSubTitle';
import Button, { BackButton } from '../../common/Button';

import EventCard from './EventCard';

const Wrapper = styled.div`
  width: 100%;
  max-width: 1200px;
`;

const ButtonWrapper = styled.div`
  width: 335px;
  height: 56px;
`;

const ButtonsWrapper = styled.div`
  width: 750px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 50px auto;
`;

const OneTimeEvents = ({
  openEventModal,
  events,
  redirectToNextPage,
  deleteEvents,
  intl,
}) => {
  const { t } = useTranslation();
  const history = useHistory();

  const currentDate = moment();

  const handleSelect = (date) => {
    if (date.isSameOrAfter(currentDate, 'day')) {
      openEventModal({ date, withFrequency: false });
    }
  };

  const dateCellRender = (date) => {
    return events.map((event) => {
      const startDate = moment(event.startDate).local();
      const endDate = moment(event.endDate).local();
      const isSame = date.isSame(startDate, 'day');
      if (!isSame) return null;

      const shifts = [];

      if (event.shifts && Number.isInteger(event.shifts[0])) {
        let from = moment(startDate);
        event.shifts.forEach((shift) => {
          const to = moment(from).add(shift, 'ms');
          shifts.push({
            from: from.format('HH:mm'),
            to: to.format('HH:mm'),
          });
          from = to;
        });
      } else {
        shifts.push({
          from: startDate.format('HH:mm'),
          to: endDate.format('HH:mm'),
        });
      }

      return (
        <EventCard
          onClick={(e) => {
            e.stopPropagation();

            if (date.isSameOrAfter(currentDate, 'day')) {
              openEventModal({
                date,
                withFrequency: false,
                eventId: event._id,
              });
            }
          }}
          shifts={shifts}
        />
      );
    });
  };

  return (
    <Wrapper>
      <PageTitle>{t('createProjectOld.steps.setTime.title')}</PageTitle>
      <PageSubTitle>
        {t('createProjectOld.steps.setTime.once.subtitle')}
      </PageSubTitle>
      <Calendar onSelect={handleSelect} dateCellRender={dateCellRender} />
      <ButtonsWrapper>
        <ButtonWrapper>
          <BackButton
            onClick={() => {
              deleteEvents();
              history.push('/create-project/set-time');
            }}>
            {t('createProjectOld.back')}
          </BackButton>
        </ButtonWrapper>
        <ButtonWrapper>
          <Button
            onClick={() => history.push('/create-project/location')}
            disabled={events.length === 0}>
            {t('createProjectOld.next')}
          </Button>
        </ButtonWrapper>
      </ButtonsWrapper>
    </Wrapper>
  );
};

const mapState = (state) => ({
  events: eventsSelector(state),
});

const mapDispatch = {
  openEventModal,
  redirectToNextPage,
  deleteEvents,
};

export default connect(mapState, mapDispatch)(withRouter(OneTimeEvents));
