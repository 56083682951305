/* eslint-disable */
import { handleActions } from 'redux-actions';

import {
  createProjectProcessingStatusSet,
  fetchProjectCategoriesResult,
  fetchMyProjects,
  fetchMyProjectsSucceeded,
  fetchAllProjectsResult,
  createProjectSuccessed,
  createProjectFailed,
  createProjectPopupClose,
  fetchProjectInfoById,
  fetchProjectInfoByIdSuccess,
  updateProjectFailed,
  updateProjectSuccessed,
  deleteProject,
  deleteProjectSuccess,
  openDescriptionModal,
} from './actions';

const initialState = {
  myProjects: {
    data: [],
    loaded: false,
  },
  projects: [],
  newProject: {
    processing: false,
    coordinators: [],
    result: null,
  },
  categories: [],
  descriptionModal: false,
};

const projects = handleActions(
  {
    [createProjectProcessingStatusSet]: (...args) =>
      createProjectProcessingStatusSetHandler(...args),
    [fetchProjectCategoriesResult]: (...args) =>
      fetchProjectCategoriesResultHandler(...args),
    [fetchMyProjects]: (...args) => fetchMyProjectsHandler(...args),
    [fetchMyProjectsSucceeded]: (state, action) =>
      fetchMyProjectsSucceededHandler(state, action),
    [fetchAllProjectsResult]: (state, action) =>
      fetchAllProjectsResultHandler(state, action),
    [fetchProjectInfoById]: (...args) => fetchProjectInfoByIdHandler(...args),
    [fetchProjectInfoByIdSuccess]: (...args) =>
      fetchProjectInfoSuccesHandler(...args),
    [deleteProject]: (...args) => deleteProjectHandler(...args),
    [deleteProjectSuccess]: (...args) => deleteProjectSuccessHandler(...args),
    [createProjectSuccessed]: (state, action) =>
      createProjectSuccededHandler(state, action),
    [createProjectFailed]: (state, action) =>
      createProjectFailedHandler(state, action),
    [createProjectPopupClose]: (state, action) =>
      creationResultPopupHandler(state, action),
    [updateProjectSuccessed]: (state, action) =>
      updateProjectSuccededHandler(state, action),
    [updateProjectFailed]: (state, action) =>
      updateProjectFailedHandler(state, action),
    [openDescriptionModal]: (state, action) =>
      openDescriptionModalHandler(state, action),
  },
  initialState
);

export default projects;

function createProjectProcessingStatusSetHandler(state, action) {
  return {
    ...state,
    newProject: {
      processing: action.payload.status,
    },
  };
}

function fetchProjectCategoriesResultHandler(state, action) {
  return {
    ...state,
    categories: action.payload,
  };
}

function fetchMyProjectsHandler(state, action) {
  return {
    ...state,
    myProjects: {
      ...state.myProjects,
      loaded: false,
    },
  };
}

function fetchMyProjectsSucceededHandler(state, action) {
  return {
    ...state,
    myProjects: {
      data: [...action.payload],
      loaded: true,
    },
  };
}

function fetchAllProjectsResultHandler(state, action) {
  return {
    ...state,
    projects: [...action.payload],
  };
}

function deleteProjectHandler(state, action) {
  return {
    ...state,
    loading: true,
  };
}

function deleteProjectSuccessHandler(state, action) {
  return {
    ...state,
    loading: false,
  };
}

function fetchProjectInfoByIdHandler(state, action) {
  return {
    ...state,
    project: {
      ...state.project,
      id: action.payload,
    },
  };
}

function fetchProjectInfoSuccesHandler(state, action) {
  return {
    ...state,
    loaded: true,
    projectInfo: action.payload,
  };
}

function createProjectSuccededHandler(state, action) {
  return {
    ...state,
    newProject: {
      ...state.newProject,
      result: action.payload,
    },
  };
}

function updateProjectSuccededHandler(state, action) {
  return {
    ...state,
    updated: true,
  };
}

function updateProjectFailedHandler(state, action) {
  return {
    ...state,
    updated: false,
  };
}

// function fetchProjectDefaultsHandler(state, action) {
//  return {
//    ...state,
//    newProject: {
//      coordinators: action.payload,
//      loaded: true,
//    },
//  };
// }

function createProjectFailedHandler(state, action) {
  return {
    ...state,
    newProject: {
      ...state.newProject,
      result: action.payload,
    },
  };
}

function creationResultPopupHandler(state, action) {
  return {
    ...state,
    newProject: {
      ...state.newProject,
      result: null,
    },
  };
}

function openDescriptionModalHandler(state, action) {
  return {
    ...state,
    descriptionModal: action.payload,
  };
}
