import React from 'react';

import useAutoSetLanguage from './use-auto-set-language';

const AutoSetLanguage: React.FC = () => {
  useAutoSetLanguage();
  return null;
};

export default AutoSetLanguage;
