/* eslint-disable */
import styled from 'styled-components';

const PageTitle = styled.div`
  font-size: 40px;
  color: #14161d;
  letter-spacing: 0.5px;
  text-align: center;
`;

export default PageTitle;
