import { API } from 'api';
import { Activity, ActivityClient } from 'models/activity';
import { FeedbackResponse } from 'models/feedback';
import { useSnackbar } from 'notistack';
import { useMutation, useQueryClient } from 'react-query';
import useLocales from 'views/shared/hooks/use-locales';

type FeedbackActivity = Activity<FeedbackResponse>;
export type FeedbackActivityClient = ActivityClient<FeedbackResponse>;

export const useSubmitFeedback = () => {
  const { translate: t } = useLocales('feedback/feedback');
  const { enqueueSnackbar } = useSnackbar();

  const queryClient = useQueryClient();
  const { mutate: handleSubmitFeedback, isLoading } = useMutation(
    (feedback: Partial<FeedbackActivityClient>) =>
      API.post<FeedbackActivity>('/activities', {
        activity: 'feedback',
        target: 'application',
        ...feedback,
      } as FeedbackActivityClient),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['userFeedbackConfig']);

        queryClient.invalidateQueries(['project-activities']);
        enqueueSnackbar(t('actions.success'));
      },
    }
  );

  return {
    handleSubmitFeedback,
    isLoading,
  };
};
