import { FC } from 'react';
import { Box, Paper, Stack, Typography } from '@mui/material';
import { Reaction } from 'views/community-feed/models/event';
import { useAuth } from 'views/shared/modules/auth/service';
import useLocales from 'views/shared/hooks/use-locales';
import { getEmojiCode } from 'views/shared/utils/get-emoji';

interface Props {
  emoji: string;
  reactions: Reaction[];
}

export const EmojiReactionUsers: FC<Props> = ({ emoji, reactions }) => {
  const { user: currentUser } = useAuth();
  const { translate } = useLocales('community-feed/feed');
  return (
    <Box sx={{ bgcolor: 'inherit', width: 'fit-content' }}>
      <Stack alignItems="center" gap={1}>
        <Paper variant="outlined" sx={{ p: 1, bgcolor: 'inherit' }}>
          <Typography variant="h1">{emoji}</Typography>
        </Paper>
        <Stack direction="row" alignItems="center" flexWrap="wrap" gap={0.5}>
          {reactions.map((r, i) => {
            const lastElement = reactions.length - 1;
            let separator = '';
            if (i !== lastElement) separator = ',';
            if (i === lastElement - 1) separator = ` ${translate('events.emojiReaction.and')} `;

            return (
              <Stack key={i} direction="row" alignItems="baseline" gap={0.5}>
                {currentUser?._id === r.user._id ? (
                  <Typography
                    textTransform={i === 0 ? 'capitalize' : 'initial'}
                    variant="caption"
                    color="grey.0"
                    fontWeight={600}
                  >
                    {reactions.length === 1
                      ? translate('events.emojiReaction.youAlone')
                      : translate('events.emojiReaction.you')}
                    <Typography component="span" variant="inherit" color="inherit" textTransform="lowercase">
                      {separator}
                    </Typography>
                  </Typography>
                ) : (
                  <Typography
                    textTransform={i === 0 ? 'capitalize' : 'initial'}
                    variant="caption"
                    color="grey.0"
                    fontWeight={600}
                  >
                    {r.user.firstName} {r.user.lastName}
                    <Typography component="span" variant="inherit" color="inherit" textTransform="lowercase">
                      {separator}
                    </Typography>
                  </Typography>
                )}
              </Stack>
            );
          })}
          <Typography variant="caption" color="grey.400">
            {translate('events.emojiReaction.reactedWith')}
          </Typography>
          <Typography variant="caption" fontWeight={700} color="grey.400">
            {getEmojiCode(emoji)}
          </Typography>
        </Stack>
      </Stack>
    </Box>
  );
};
