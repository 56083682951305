/* eslint-disable */
import { fork, spawn, put, take, select, call, all } from 'redux-saga/effects';
import { push } from 'react-router-redux';

import without from 'lodash/without';
import moment from 'moment';
import { v4 as uuid } from 'uuid';
import { postFormDataRequest, getRequest } from '../../api';

import {
  setFormField,
  formInput,
  redirectToNextPage,
  setCurrentPage,
  initCategories,
  setCategoryOptions,
  setImages,
  setEvent,
  setEventToState,
  closeEventModal,
  setTimeAWeek,
  setEventTerms,
  setEventTermsToState,
  setLocation,
  setLocationToState,
  submitDetails,
  setDetails,
  createProject,
  redirectToPreviousPage,
} from './actions';
import {
  currentPageSelector,
  categoriesSelector,
  selectEventModal,
  selectDataToCreateProject,
  categoryOptionsSelector,
} from './selectors';
import { pagesOrder } from './routes';

const HOUR = 3600000;

const ROUTE_ADDRESS_COMPONENTS = [
  'route',
  'locality',
  'administrative_area_level_1',
  'administrative_area_level_2',
  'administrative_area_level_3',
];

const CITY_ADDRESS_COMPONENTS = [
  'locality',
  'administrative_area_level_1',
  'administrative_area_level_2',
  'administrative_area_level_3',
  'country',
];

const COUNTRY_ADDRESS_COMPONENTS = ['country'];

export const fetchProjectCategoriesRequest = () => getRequest('/categories');
export const createProjectRequest = (data) =>
  postFormDataRequest('/projects/create', data);

function* redirectToNextPageSaga() {
  while (true) {
    yield take(redirectToNextPage);

    const currentPage = yield select(currentPageSelector);
    const nextPageIndex = currentPage + 1;
    yield put(setCurrentPage(nextPageIndex));

    const nextPagePath = pagesOrder[nextPageIndex];
    yield put(push(`/dashboard/add-project/${nextPagePath}`));
  }
}

function* redirectToPreviousPageSaga() {
  while (true) {
    yield take(redirectToPreviousPage);

    const currentPage = yield select(currentPageSelector);
    const previousPageIndex = currentPage - 1;
    yield put(setCurrentPage(previousPageIndex));

    const previousPagePath = pagesOrder[previousPageIndex];
    yield put(push(`/dashboard/add-project/${previousPagePath}`));
  }
}

function* initCategoriesSaga() {
  while (true) {
    yield take(initCategories);
    try {
      const {
        data: { categories },
      } = yield call(fetchProjectCategoriesRequest);
      yield put(setCategoryOptions(categories));
    } catch (err) {
      // TODO: add err handler
    }
  }
}

function* categoriesInputHandlerSaga({ field, value: id }) {
  let categories = yield select(categoriesSelector);

  categories = categories.includes(id)
    ? without(categories, id)
    : [...categories, id];

  yield put(setFormField({ field, value: categories }));
}

function* userImagesUploadSaga({ value: files }) {
  const images = [];

  const createImageUrl = (file) => {
    // eslint-disable-next-line no-undef
    const reader = new FileReader();

    return new Promise((resolve) => {
      reader.onload = () => {
        images.push({ file, url: reader.result });
        resolve();
      };

      reader.readAsDataURL(file);
    });
  };

  yield all(Array.from(files).map((file) => call(createImageUrl, file)));

  yield put(setImages({ field: 'uploadedImages', images }));
  yield put(setImages({ field: 'titleImage', images: images[0].url }));
}

function* createRecurringEventSaga({
  fromTime,
  toTime,
  shifts,
  frequency,
  _id,
}) {
  const { date } = yield select(selectEventModal);

  const event = {
    _id: _id || uuid(),
    recurringEvent: true,
  };

  const selectedDate = moment(date);
  selectedDate.seconds(0);

  const [startHour, startMinutes] = fromTime.split(':');
  const [endHour, endMinutes] = toTime.split(':');

  selectedDate.hour(startHour);
  selectedDate.minutes(startMinutes);

  const startDate = moment(selectedDate);
  event.startDate = moment(startDate).utc();

  event.endDate = moment({ year: 9999 }).utc();

  const endDate = moment(startDate);
  endDate.hour(endHour);
  endDate.minutes(endMinutes);

  const toDate = moment(endDate);

  let millisecondsBetween = toDate.diff(startDate);
  event.duration = millisecondsBetween;

  if (shifts) {
    const eventShifts = [];
    const shiftInMilliseconds = shifts * HOUR;
    event.shiftInHours = shifts;

    while (true) {
      if (millisecondsBetween === 0) break;

      millisecondsBetween -= shiftInMilliseconds;

      if (millisecondsBetween >= 0) {
        eventShifts.push(shiftInMilliseconds);
      }

      if (millisecondsBetween < 0) {
        const shorterShift =
          shiftInMilliseconds - Math.abs(millisecondsBetween);
        eventShifts.push(shorterShift);
        break;
      }
    }

    event.shifts = eventShifts;
  }

  const minutes = event.startDate.minutes();
  const hour = event.startDate.hour();
  const dayOfWeek = event.startDate.day();
  const dayOfMonth = event.startDate.date();
  const month = event.startDate.month();

  let cron;

  event.frequency = frequency;

  switch (frequency) {
    case 'daily':
      cron = `${minutes} ${hour} * * *`;
      break;
    case 'weekly':
      cron = `${minutes} ${hour} * * ${dayOfWeek}`;
      break;
    case 'monthly':
      cron = `${minutes} ${hour} ${dayOfMonth} * *`;
      break;
    case 'yearly':
      cron = `${minutes} ${hour} ${dayOfMonth} ${month} *`;
      break;
    default:
      return event;
  }

  event.cron = cron;

  return event;
}

function* createOneTimeEventSaga({ fromTime, toTime, shifts, _id }) {
  const { date } = yield select(selectEventModal);

  const event = {
    _id: _id || uuid(),
    oneTimeEvent: true,
  };

  const selectedDate = moment(date);
  selectedDate.seconds(0);

  const [startHour, startMinutes] = fromTime.split(':');
  const [endHour, endMinutes] = toTime.split(':');

  selectedDate.hour(startHour);
  selectedDate.minutes(startMinutes);
  const startDate = moment(selectedDate);
  event.startDate = moment(startDate).utc();

  const endDate = moment(selectedDate);
  endDate.hour(endHour);
  endDate.minutes(endMinutes);
  event.endDate = moment(endDate).utc();

  if (shifts) {
    const eventShifts = [];
    let millisecondsBetween = endDate.diff(startDate);
    const shiftInMilliseconds = shifts * HOUR;
    event.shiftInHours = shifts;

    while (true) {
      if (millisecondsBetween === 0) break;

      millisecondsBetween -= shiftInMilliseconds;

      if (millisecondsBetween >= 0) {
        eventShifts.push(shiftInMilliseconds);
      }

      if (millisecondsBetween < 0) {
        const shorterShift =
          shiftInMilliseconds - Math.abs(millisecondsBetween);
        eventShifts.push(shorterShift);
        break;
      }
    }

    event.shifts = eventShifts;
  }

  return event;
}

function* setEventSaga() {
  while (true) {
    const { payload } = yield take(setEvent);
    const { withFrequency } = yield select(selectEventModal);

    const event = withFrequency
      ? yield call(createRecurringEventSaga, payload)
      : yield call(createOneTimeEventSaga, payload);

    yield put(setEventToState(event));
    yield put(closeEventModal());
  }
}

function* setTimeAWeekSaga() {
  while (true) {
    const { payload } = yield take(setTimeAWeek);
    const event = {
      timeAWeek: true,
      startDate: moment(),
      endDate: moment({ year: 9999 }),
      opp_custom_hours: payload.hours,
      opp_custom_frequency: payload.frequency,
    };
    yield put(setEventToState(event));
  }
}

function* setEventTermsSaga() {
  while (true) {
    const { payload: isPermanentMembership } = yield take(setEventTerms);
    yield put(setEventTermsToState(isPermanentMembership));
  }
}

function* setLocationSaga() {
  while (true) {
    const { payload } = yield take(setLocation);
    const { isLocationIndependent, location, position, place } = payload;
    if (isLocationIndependent) {
      yield put(setLocationToState({ isLocationIndependent }));
    } else {
      yield put(
        setLocationToState({
          location,
          position,
          place,
          isLocationIndependent: false,
        })
      );
    }
  }
}

function* submitDetailsSaga() {
  while (true) {
    const { payload } = yield take(submitDetails);
    const { role, purpose, additionalInfo } = payload;
    const details = { role, purpose };
    if (additionalInfo) {
      details.additionalInfo = additionalInfo;
    }
    yield put(setDetails(details));
  }
}

function* submitProjectSaga() {
  while (true) {
    yield take(createProject);
    let project = {};

    const projectData = yield select(selectDataToCreateProject);
    const categoryOptions = yield select(categoryOptionsSelector);
    const currentGroupId = yield select((state) => state.user.currentGroupId);

    const {
      title,
      categories,
      images,
      permanentMembership,
      isLocationIndependent,
      location,
      position,
      events,
      role,
      purpose,
      additionalInfo,
      applicationRequired,
      maxParticipants,
      minimumAge,
      place,
      private: isPrivate,
    } = projectData;

    const selectedCategories = categories.map(
      (id) => categoryOptions.find(({ _id }) => id === _id).name
    );

    const event = events[0];

    const isRecurring = Boolean(event.recurringEvent);
    const isTimeAWeek = Boolean(event.timeAWeek);
    const isOneTime = Boolean(event.oneTimeEvent);

    project = {
      opp_name: title,
      opp_categories: JSON.stringify(selectedCategories),
      opp_role: role,
      opp_purpose: purpose,
      opp_private: isPrivate,
    };

    if (images.uploadedImages.length) {
      project.opp_image = images.uploadedImages[0].file;
      project.files = images.uploadedImages;
    } else {
      project.opp_string_photo = images.titleImage;
    }

    if (applicationRequired) {
      project.opp_confirmation_required = applicationRequired;
    }

    if (minimumAge) {
      project.opp_minimum_age = minimumAge;
    }

    if (maxParticipants) {
      project.opp_max_participant = maxParticipants;
    }

    if (additionalInfo) {
      project.opp_additionalinfo = additionalInfo;
    }

    if (!isLocationIndependent) {
      const { lat, lng } = position;
      project.opp_location = location;
      project.opp_longitude = lng;
      project.opp_latitude = lat;

      const addressComponents =
        place &&
        place.address_components &&
        place.address_components.reduce((acc, address) => {
          const { long_name: longName, types } = address;
          const type = types[0] || 'unknown';
          return {
            ...acc,
            [type]: longName,
          };
        }, {});

      const searchForAddress = (prop) => (component) => {
        if (project[prop]) return;

        const address = addressComponents[component];

        if (address) {
          project[prop] = address;
        }
      };

      if (addressComponents) {
        ROUTE_ADDRESS_COMPONENTS.forEach(searchForAddress('opp_street'));
        CITY_ADDRESS_COMPONENTS.forEach(searchForAddress('opp_city'));
        COUNTRY_ADDRESS_COMPONENTS.forEach(searchForAddress('opp_country'));
      }
    }

    if (isRecurring) {
      project.opp_permanent_membership = permanentMembership;

      const { endDate } = event;

      project.opp_startdate = moment.utc().format();
      project.opp_enddate = moment(endDate).utc().format();
      project.opp_repeatable = true;

      const schedule = events.map((ev) => {
        const newSchedule = {};
        const { duration, cron, shifts } = ev;
        newSchedule.duration = duration;
        newSchedule.cron = cron;

        if (shifts) {
          newSchedule.shifts = shifts;
        }

        return newSchedule;
      });

      project.opp_schedule = JSON.stringify(schedule);
    }

    if (isOneTime) {
      const { startDate, endDate, shifts } = event;
      project.opp_startdate = moment(startDate).utc().format();
      project.opp_enddate = moment(endDate).utc().format();
      project.opp_repeatable = false;

      if (shifts) {
        project.opp_once_shifts = JSON.stringify(shifts);
      }
    }

    if (isTimeAWeek) {
      const {
        startDate,
        endDate,
        opp_custom_hours: oppCustomHours,
        opp_custom_frequency: oppCustomFrequency,
      } = event;
      project.opp_startdate = moment(startDate).utc().format();
      project.opp_enddate = moment(endDate).utc().format();
      project.opp_custom_hours = oppCustomHours;
      project.opp_custom_frequency = oppCustomFrequency;
    }

    try {
      const result = yield call(createProjectRequest, project);
      yield put(push('/my-projects'));
      console.log(result);
    } catch (err) {
      console.error(err);
      // TODO: add error handler
    }
  }
}

function* titleInputHandlerSaga(payload) {
  yield put(setFormField(payload));
}

const inputTypeHandlersMapping = {
  title: titleInputHandlerSaga,
  categories: categoriesInputHandlerSaga,
  userImages: userImagesUploadSaga,
};

function* formInputHandlerSaga() {
  while (true) {
    const { payload } = yield take(formInput);
    const handler = inputTypeHandlersMapping[payload.field];
    yield spawn(handler, payload);
  }
}

export default function* createProjectSaga() {
  yield fork(formInputHandlerSaga);

  yield fork(redirectToNextPageSaga);
  yield fork(initCategoriesSaga);
  yield fork(setEventSaga);
  yield fork(setTimeAWeekSaga);
  yield fork(setEventTermsSaga);
  yield fork(setLocationSaga);
  yield fork(submitDetailsSaga);
  yield fork(submitProjectSaga);
  yield fork(redirectToPreviousPageSaga);
}
