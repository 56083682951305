import { useEffect } from 'react';
import { checkIfIsLetsact } from 'views/shared/utils/common';
import { updateFavicon } from 'views/shared/utils/get-favicon';

const useFaviconSelector = () => {
  useEffect(() => {
    if (!checkIfIsLetsact()) {
      updateFavicon('lets', 'Lets');
    } else {
      updateFavicon('letsact', 'Letsact');
    }

  }, []);

  return {

  };
};

export default useFaviconSelector;
