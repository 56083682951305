import storage from 'views/shared/utils/storage';
import { api } from 'views/shared/modules/api/request';
import { useQuery, UseQueryResult } from 'react-query';

import { GroupPopulated } from 'views/shared/modules/group/model';

export interface GroupData {
  data: GroupPopulated;
}

export interface GroupPopulatedData {
  data: GroupPopulated;
}

export interface GroupMembers {
  members: {
    _id: string;
    type: 'user' | 'group';
    role?: string;
    userId?: string;
    groupId?: string;
  }[];
}

export async function getGroup(id: string): Promise<GroupPopulated> {
  try {
    const res = await api.get<GroupData>(`/groups/${id}`, {
      headers: {
        Authorization: storage.getToken(),
      },
    });

    const { data: groupData } = res;

    return Promise.resolve(groupData.data);
  } catch (err) {
    return Promise.reject(err);
  }
}

export async function getGroupPopulated(id: string): Promise<GroupPopulated> {
  try {
    const res = await api.get<GroupPopulatedData>(`/groups/${id}`, {
      headers: {
        Authorization: storage.getToken(),
      },
      params: {
        populate: 'true',
      },
    });

    const { data: groupData } = res;

    return Promise.resolve(groupData.data);
  } catch (err) {
    return Promise.reject(err);
  }
}

export function useGetGroup(groupId: string | undefined): UseQueryResult<GroupPopulated, unknown> {
  return useQuery(['group', groupId], () => getGroup(groupId || ''), {
    enabled: !!groupId,
    keepPreviousData: true,
  });
}

export function useGetGroupPopulated(groupId: string | undefined): UseQueryResult<GroupPopulated, unknown> {
  return useQuery(['groupPopulated', groupId], () => getGroupPopulated(groupId || ''), {
    enabled: !!groupId,
  });
}

export function useGetGroupMembers(groupId: string | undefined): UseQueryResult<GroupMembers, unknown> {
  return useQuery(['groupMembers', groupId], () => getGroupPopulated(groupId || ''), {
    enabled: !!groupId,
  });
}
