import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { useAuth } from 'views/shared/modules/auth/service';
import { RootState } from 'store/reducers';
import { GroupPopulated } from 'views/shared/modules/group/model';
import { Organization } from 'views/shared/modules/organization/model';
import { useGetOrganization } from 'views/shared/modules/organization/api/get-organization';
import { useGetGroup } from 'views/shared/modules/group/api/get-group';
import { Role } from 'models/roles';

export interface HookData {
  group: GroupPopulated | undefined;
  organization: Organization | undefined;
  role: Role;
  isLoadingOrg: boolean;
  isLoadingGroup: boolean;
}

export function useCurrentOrganizationAndGroup(): HookData {
  const { user } = useAuth();

  const currentGroupId = useSelector((state: RootState) => state.user.currentGroupId);
  const currentOrganizationId = useSelector((state: RootState) => state.user.currentOrganizationId);
  const { data: organization, isLoading: isLoadingOrg } = useGetOrganization(currentOrganizationId);
  const { data: group, isLoading: isLoadingGroup } = useGetGroup(currentGroupId);

  // return all sub groups

  const role = useMemo(() => {
    if (user && group) {
      const _user = group.members.find((member) => member.userId === user._id);
      if (_user) {
        return _user.role || 'member';
      }
    }

    return 'member';
  }, [user, group]);

  return {
    organization,
    group,
    role,
    isLoadingOrg,
    isLoadingGroup,
  };
}
