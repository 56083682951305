import storage from 'views/shared/utils/storage';
import { api } from 'views/shared/modules/api/request';
import { User } from 'views/shared/modules/user/model';
import { getTokenPayload } from 'views/shared/utils/get-token-payload';

export interface RegisterData {
  firstName: string;
  lastName: string;
  email: string;
  dob: string;
  password?: string;
  phone?: string;
  individualRegistration?: boolean;
}

export interface InstantSignupData {
  firstName: string;
  lastName: string;
  email: string;
  dob: string;
  phone?: string;
}

export interface RegisterRes {
  statusCode: number;
  token: string;
  firebaseToken: string;
  user: User;
}

export interface RegisterErrorRes {
  status: string;
  statusCode: number;
  message: string;
  error: {
    message: string;
    errors: {
      keyword: string;
    }[];
  };
}

export function instanceOfRegisterRes(object: RegisterRes | RegisterErrorRes): object is RegisterRes {
  return 'user' in object;
}

export function instanceOfRegisterErrorRes(object: RegisterRes | RegisterErrorRes): object is RegisterErrorRes {
  return 'error' in object;
}

export async function registerWithEmailAndPassword(inputData: RegisterData): Promise<RegisterRes | RegisterErrorRes> {
  try {
    const { data } = await api.post<RegisterRes>('/auth/register/volunteer', inputData);

    if (data.statusCode === 400) {
      return Promise.reject<RegisterErrorRes>(data);
    }

    return Promise.resolve(data);
  } catch (err) {
    return Promise.reject<RegisterErrorRes>(err);
  }
}

export async function registerWithEmailOnly(inputData: InstantSignupData): Promise<RegisterRes | RegisterErrorRes> {
  try {
    // check if the token is instant token (magic-link)
    const queryString = window.location.search;
    const params = new URLSearchParams(queryString);
    const token = params.has('token') && getTokenPayload(params.get('token'));
    if (!token) throw new Error('Invalid token provided');
    const { data } = await api.post<RegisterRes>('/auth/signup/instant', inputData, {
      Authorization: `Bearer ${token}`,
    });

    if (data.statusCode === 400) {
      return Promise.reject<RegisterErrorRes>(data);
    }

    return Promise.resolve(data);
  } catch (err) {
    return Promise.reject<RegisterErrorRes>(err);
  }
}

export interface EmailOtpData {
  email: string;
  type: 'ORGANIZATION' | 'VOLUNTEER' | 'CORPORATE';
}

export interface EmailOtpRes {
  status: string;
  statusCode: number;
  message: string;
  email: string;
  otp: number;
}

export async function sendEmailConfirmationOtp(inputData: EmailOtpData): Promise<EmailOtpRes> {
  try {
    const { data } = await api.post<EmailOtpRes>('/auth/verification/email', inputData);
    return Promise.resolve(data);
  } catch (err) {
    return Promise.reject(err);
  }
}

export interface EmailOtpCheckData {
  email: string;
  verifyCode: string;
  withoutOnboardingEmail?: boolean;
}

export interface EmailOtpCheckRes {
  status: string;
  statusCode: number;
  message: string;
}

export async function checkEmailConfirmationOtp(inputData: EmailOtpCheckData): Promise<EmailOtpCheckRes> {
  try {
    const { data } = await api.post<EmailOtpCheckRes>('/auth/verification/email/check', inputData);

    if (data.status === 'error') {
      return Promise.reject(data);
    }

    return Promise.resolve(data);
  } catch (err) {
    return Promise.reject(err);
  }
}

export interface EmailAvailabilityData {
  email: string;
}

export interface EmailAvailabilityRes {
  message: string;
}

export async function checkEmailAvailability(inputData: EmailAvailabilityData): Promise<EmailAvailabilityRes> {
  try {
    const { data } = await api.post<EmailAvailabilityRes>('/auth/validate/email', inputData);
    return Promise.resolve(data);
  } catch (err) {
    return Promise.reject(err);
  }
}

export interface Email2FAOtpData {
  tempToken: string;
  userId: string;
}

export interface Email2FAOtpRes {
  status: string;
  statusCode: number;
  message: string;
  email: string;
  otp: number;
}

export async function send2FAEmailConfirmationOtp(inputData: Email2FAOtpData): Promise<Email2FAOtpRes> {
  try {
    const { data } = await api.post<Email2FAOtpRes>('/auth/_verification/email', inputData);
    return Promise.resolve(data);
  } catch (err) {
    return Promise.reject(err);
  }
}

export interface Email2FAOtpCheckData {
  tempToken: string;
  userId: string;
  verifyCode: string;
}

export interface Email2FAOtpCheckRes {
  status: string;
  statusCode: number;
  message: string;
  tempToken?: string;
  token?: string;
  firebaseToken?: string;
}

export async function check2FAEmailConfirmationOtp(inputData: Email2FAOtpCheckData): Promise<Email2FAOtpCheckRes> {
  try {
    const { data } = await api.post<Email2FAOtpCheckRes>('/auth/_verification/email/check', inputData);

    if (data.status === 'error') {
      return Promise.reject(data);
    }

    return Promise.resolve(data);
  } catch (err) {
    return Promise.reject(err);
  }
}

export interface SMS2FAOtpData {
  tempToken: string;
  userId: string;
}

export interface SMS2FAOtpRes {
  status: string;
  statusCode: number;
  message: {
    to: string;
  };
}

export async function send2FASMSConfirmationOtp(inputData: SMS2FAOtpData): Promise<SMS2FAOtpRes> {
  try {
    const { data } = await api.post<SMS2FAOtpRes>('/auth/_sendcode', inputData);
    return Promise.resolve(data);
  } catch (err) {
    return Promise.reject(err);
  }
}

export interface SMS2FAOtpCheckData {
  tempToken: string;
  userId: string;
  code: string;
}

export interface SMS2FAOtpCheckRes {
  status: string;
  statusCode: number;
  message: string;
  token?: string;
  firebaseToken?: string;
}

export async function check2FASMSConfirmationOtp(inputData: SMS2FAOtpCheckData): Promise<SMS2FAOtpCheckRes> {
  try {
    const { data } = await api.post<SMS2FAOtpCheckRes>('/auth/_checkcode', inputData);

    if (data.status === 'error') {
      return Promise.reject(data);
    }

    return Promise.resolve(data);
  } catch (err) {
    return Promise.reject(err);
  }
}

export interface SMS2FAPhoneOtpData {
  phone: string;
}

export interface SMS2FAPhoneOtpRes {
  status: string;
  statusCode: number;
  data: {
    to: string;
  };
}

export async function send2FAPhoneSMSConfirmationOtp(inputData: SMS2FAPhoneOtpData): Promise<SMS2FAPhoneOtpRes> {
  try {
    const { data } = await api.post<SMS2FAPhoneOtpRes>('/auth/verification/phone', inputData, {
      headers: {
        Authorization: storage.getToken(),
      },
    });
    return Promise.resolve(data);
  } catch (err) {
    return Promise.reject(err);
  }
}

export interface SMS2FAPhoneOtpCheckData {
  phone: string;
  code: string;
}

export interface SMS2FAPhoneOtpCheckRes {
  status: string;
  statusCode: number;
  message: {
    to: string;
  };
}

export async function check2FAPhoneSMSConfirmationOtp(
  inputData: SMS2FAPhoneOtpCheckData
): Promise<SMS2FAPhoneOtpCheckRes> {
  try {
    const { data } = await api.post<SMS2FAPhoneOtpCheckRes>('/auth/verification/phone/check', inputData, {
      headers: {
        Authorization: storage.getToken(),
      },
    });

    if (data.status === 'error') {
      return Promise.reject(data);
    }

    return Promise.resolve(data);
  } catch (err) {
    return Promise.reject(err);
  }
}
