export const updateFavicon = (path: string, title: string) => {
  // fetching icons
  const favicon = document.querySelector("link[rel='icon']") as HTMLLinkElement
  const faviconShortcut = document.querySelector("link[rel='shortcut icon']") as HTMLLinkElement;
  const appleTouchIcon = document.querySelector("link[rel='apple-touch-icon']") as HTMLLinkElement;
  const faviconAndroid = document.querySelector("link[rel='icon'][sizes='192x192']") as HTMLLinkElement;
  const metaElement = document.querySelector("meta[name='msapplication-TileImage']");
  // fetching title
  const pageTitle = document.querySelector('title');
  // modifying title
  if (pageTitle) {
    pageTitle.textContent = title;
  }
  // modifying icons
  if (favicon) favicon.setAttribute("href", `${path}/favicon.ico`);
  if (faviconShortcut) faviconShortcut.setAttribute("href", `${path}/favicon-32x32.png`);
  if (appleTouchIcon) appleTouchIcon.setAttribute("href", `${path}/apple-touch-icon.png`);
  if (faviconAndroid) faviconAndroid.setAttribute("href", `${path}/android-chrome-192x192.png`);
  if (metaElement) metaElement.setAttribute("content", `${path}/android-chrome-192x192.png`);

};