import { AnyAction } from 'redux';

import { AppDispatch } from 'store';
import storage from 'views/shared/utils/storage';
import { refreshToken } from 'views/shared/modules/auth/api/refresh-token';

/**
 * action types
 */

export const SET_USER_LOCATION = 'SET_USER_LOCATION';
export const ADD_ANONYMOUSLY_VIEWED_PROJECT = 'ADD_ANONYMOUSLY_VIEWED_PROJECT';
export const SET_CURRENT_GROUP_ID = 'SET_CURRENT_GROUP_ID';
export const SET_CURRENT_ORGANIZATION_ID = 'SET_CURRENT_ORGANIZATION_ID';
export const CLEAR_CURRENT_GROUP_ID = 'CLEAR_CURRENT_GROUP_ID';
export const CLEAR_CURRENT_ORGANIZATION_ID = 'CLEAR_CURRENT_ORGANIZATION_ID';

/**
 * reducer
 */

export type Location = { lat: number; lng: number } | undefined;

interface UserState {
  location: Location;
  anonymouslyViewedProjects: string[];
  currentGroupId: string | undefined;
  currentOrganizationId: string | undefined;
}

const initialState: UserState = {
  location: undefined,
  anonymouslyViewedProjects: [],
  currentGroupId: undefined,
  currentOrganizationId: undefined,
};

export default function reducer(
  state = initialState,
  action: AnyAction
): UserState {
  switch (action.type) {
    case SET_USER_LOCATION:
      return {
        ...state,
        location: { ...action.location },
      };
    case ADD_ANONYMOUSLY_VIEWED_PROJECT:
      return {
        ...state,
        anonymouslyViewedProjects: [
          ...state.anonymouslyViewedProjects,
          ...(state.anonymouslyViewedProjects.indexOf(action.projectId) === -1
            ? [action.projectId]
            : []),
        ],
      };
    case SET_CURRENT_GROUP_ID:
      return {
        ...state,
        currentGroupId: action.groupId,
      };
    case CLEAR_CURRENT_GROUP_ID:
      return {
        ...state,
        currentGroupId: undefined,
      };
    case SET_CURRENT_ORGANIZATION_ID:
      return {
        ...state,
        currentOrganizationId: action.organizationId,
      };
    case CLEAR_CURRENT_ORGANIZATION_ID:
      return {
        ...state,
        currentOrganizationId: undefined,
      };
    default:
      return state;
  }
}

/**
 * action creators
 */

export function setUserLocation(location: { lat: number; lng: number }): {
  type: string;
  location: Location;
} {
  return {
    type: SET_USER_LOCATION,
    location,
  };
}

export function addAnonymouslyViewedProject(projectId: string): {
  type: string;
  projectId: string;
} {
  return {
    type: ADD_ANONYMOUSLY_VIEWED_PROJECT,
    projectId,
  };
}

function _setCurrentGroupId(groupId: string): {
  type: string;
  groupId: string;
} {
  return {
    type: SET_CURRENT_GROUP_ID,
    groupId,
  };
}

function _clearCurrentGroupId(): {
  type: string;
} {
  return {
    type: CLEAR_CURRENT_GROUP_ID,
  };
}

function _setCurrentOrganizationId(organizationId: string): {
  type: string;
  organizationId: string;
} {
  return {
    type: SET_CURRENT_ORGANIZATION_ID,
    organizationId,
  };
}

function _clearCurrentOrganizationId(): {
  type: string;
} {
  return {
    type: CLEAR_CURRENT_ORGANIZATION_ID,
  };
}

/**
 * constants
 */
// const CURRENT_GROUP_ID_KEY = 'currentGroupId';
// const CURRENT_ORGANIZATION_ID_KEY = 'currentOrganizationId';

/**
 * operations (thunks)
 */

export function clearCurrentGroupId() {
  return (dispatch: AppDispatch): void => {
    dispatch(_clearCurrentGroupId());
    // window.localStorage.removeItem(CURRENT_GROUP_ID_KEY);
    storage.clearCurrentGroupId();
  };
}

export function clearCurrentOrganizationId() {
  return (dispatch: AppDispatch): void => {
    dispatch(_clearCurrentOrganizationId());
    // window.localStorage.removeItem(CURRENT_ORGANIZATION_ID_KEY);
    storage.clearCurrentOrganizationId();
  };
}

export function loadCurrentGroupIdFromLocalStorage() {
  return (dispatch: AppDispatch): void => {
    // const currentGroupId = window.localStorage.getItem(CURRENT_GROUP_ID_KEY);
    const currentGroupId = storage.getCurrentGroupId();

    if (currentGroupId) {
      dispatch(_setCurrentGroupId(currentGroupId));
    }
  };
}

export function loadCurrentOrganizationIdFromLocalStorage() {
  return (dispatch: AppDispatch): void => {
    // const currentOrganizationId = window.localStorage.getItem(
    //   CURRENT_ORGANIZATION_ID_KEY
    // );
    const currentOrganizationId = storage.getCurrentOrganizationId();

    if (currentOrganizationId) {
      dispatch(_setCurrentOrganizationId(currentOrganizationId));
    }
  };
}

export function setCurrentGroupId(groupId: string, withTokenRefresh = false) {
  return (dispatch: AppDispatch): void => {
    // window.localStorage.setItem(CURRENT_GROUP_ID_KEY, groupId);
    storage.setCurrentGroupId(groupId);

    if (withTokenRefresh) {
      (async () => {
        await refreshToken(groupId);
      })();
    }

    dispatch(_setCurrentGroupId(groupId));
  };
}

export function setCurrentOrganizationId(organizationId: string) {
  return (dispatch: AppDispatch): void => {
    // window.localStorage.setItem(CURRENT_ORGANIZATION_ID_KEY, organizationId);
    storage.setCurrentOrganizationId(organizationId);
    dispatch(_setCurrentOrganizationId(organizationId));
  };
}
