/* eslint-disable */
import React, { Component, useEffect } from 'react';
import { useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { Checkbox } from 'antd';
import { useTranslation } from 'react-i18next';

import { getCategories } from 'views/shared/modules/category/api/get-categories';
import {
  initCategories,
  formInput,
  redirectToNextPage,
  setCurrentPage,
  redirectToPreviousPage,
  setCategoryOptions,
} from 'views/create-project-old/store/ducks/createProject/actions';
import {
  categoryOptionsSelector,
  categoriesSelector,
} from 'views/create-project-old/store/ducks/createProject/selectors';
import PageTitle from '../common/PageTitle';
import PageSubTitle from '../common/PageSubTitle';
import Button, { BackButton } from '../common/Button';

const CategoriesPageWrapper = styled.div`
  width: 100%;
  max-width: 1000px;
`;

const ButtonWrapper = styled.div`
  width: 335px;
  height: 56px;
`;

const ButtonsWrapper = styled.div`
  width: 750px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 50px auto;
`;

const Category = styled.div`
  margin-top: 15px;

  :first-child {
    margin-top: 0;
  }
`;

const CategoriesWrapper = styled.div`
  margin: 50px 0;
  display: flex;
  justify-content: center;
`;

const CategoriesContent = styled.div`
  display: inline-block;
  font-size: 25px;
`;

const StyledCheckbox = styled(Checkbox)`
  span {
    font-size: 18px;
    text-transform: capitalize;
  }
`;

function Categories(props) {
  const { t } = useTranslation();
  const { data: categoryOptions } = useQuery('categories', getCategories);
  const history = useHistory();

  useEffect(() => {
    if (categoryOptions) {
      props.setCategoryOptions(categoryOptions);
    }
  }, [categoryOptions]);

  const { categories, formInput, redirectToNextPage, redirectToPreviousPage } =
    props;

  const handleChange = (id) => {
    if (categories && categories.length >= 3 && !categories.includes(id))
      return;

    formInput({ field: 'categories', value: id });
  };

  return (
    <CategoriesPageWrapper>
      <PageTitle>{t('createProjectOld.steps.setCategories.title')}</PageTitle>
      <PageSubTitle>
        {t('createProjectOld.steps.setCategories.subtitle')}
      </PageSubTitle>
      <CategoriesWrapper>
        <CategoriesContent>
          {categoryOptions &&
            categoryOptions.map(({ _id: id, name }) => (
              <Category key={id}>
                <StyledCheckbox
                  checked={categories.includes(id)}
                  onChange={() => handleChange(id)}>
                  {t(`category.${name}`)}
                </StyledCheckbox>
              </Category>
            ))}
        </CategoriesContent>
      </CategoriesWrapper>
      <ButtonsWrapper>
        <ButtonWrapper>
          <BackButton onClick={() => history.push('/create-project/title')}>
            {t('createProjectOld.back')}
          </BackButton>
        </ButtonWrapper>
        <ButtonWrapper>
          <Button
            onClick={() => history.push('/create-project/title-image')}
            disabled={!categories || !categories.length}>
            {t('createProjectOld.next')}
          </Button>
        </ButtonWrapper>
      </ButtonsWrapper>
    </CategoriesPageWrapper>
  );
}

const mapProps = (state) => ({
  categories: categoriesSelector(state),
});

const mapDispatch = {
  initCategories,
  formInput,
  redirectToNextPage,
  setCurrentPage,
  redirectToPreviousPage,
  setCategoryOptions,
};

export default connect(mapProps, mapDispatch)(Categories);
