import { useEffect } from 'react';
import { useQueryParams, StringParam } from 'use-query-params';

// import { useAuth } from 'views/shared/modules/auth/service';
import storage from 'views/shared/utils/storage';
import { getTokenPayload } from 'views/shared/utils/get-token-payload';

// import { useHistory } from 'react-router-dom';
import { showSignUp } from 'views/shared/components/sign-up/sign-up-redux';
import { useDispatch } from 'react-redux';

export default function useAutoSignup(): void {
  // const { refetchUser } = useAuth();

  // const history = useHistory();
  const dispatch = useDispatch();

  const [queryParams] = useQueryParams({
    token: StringParam,
  });

  useEffect(() => {
    if (queryParams.token) {
      const token = getTokenPayload(queryParams.token);

      if (token && token.email) {
        dispatch(showSignUp());
      }
      storage.setInstantSignupToken(queryParams.token);

      // refetchUser();

      // setQueryParams({}, 'push');
    }
  }, [queryParams.token]);
}
