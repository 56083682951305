import React from 'react';
import { Box, Button, ButtonTypeMap, CircularProgress } from '@mui/material';

type ButtonProps = ButtonTypeMap['props'];

export interface Props extends ButtonProps {
  handleClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  label?: string | JSX.Element;
  loading?: boolean;
  type?: 'submit' | 'reset' | 'button';
  analyticsFn?: VoidFunction;
  className?: string; // Add className prop
  ref?: ((instance: HTMLButtonElement | null) => void) | React.RefObject<HTMLButtonElement> | null | undefined;
}

export const CustomButton: React.FC<Props> = ({
  handleClick,
  loading,
  label,
  type = 'button',
  analyticsFn,
  ref,
  className, // Include className prop in destructuring

  ...buttonProps
}) => {
  return (
    <Button
      ref={ref}
      sx={{ minWidth: 'fit-content' }}
      onClick={(e) => {
        if (handleClick) {
          handleClick(e);
        }
        if (analyticsFn) analyticsFn();
      }}
      type={type}
      variant="contained"
      color="primary"
      disabled={loading}
      className={className} // Pass className to the Button component
      {...buttonProps}
    >
      <CircularProgress
        thickness={6}
        size={getProgressSize(buttonProps.size)}
        color="primary"
        sx={{ display: loading ? 'block' : 'none', position: 'absolute' }}
      />
      <Box sx={{ width: '100%', opacity: loading ? 0.25 : 1 }}>{label}</Box>
    </Button>
  );
};

const getProgressSize = (size?: 'small' | 'medium' | 'large' | undefined) => {
  switch (size) {
    case 'small':
      return 18;
    case 'medium':
      return 22;
    case 'large':
      return 26;
    default:
      return 20;
  }
};
