import { useMemo, useEffect } from 'react';

import { useGetGroup } from 'views/shared/modules/group/api/get-group';
import { useOrgByCurrentHostname } from 'views/shared/modules/organization/hooks';
import { useAuth } from 'views/shared/modules/auth/service';
//
import { joinGroup } from 'views/shared/modules/group/api/join-group';
import { intersection } from 'views/shared/utils/common';

export const useAutoJoinGroup = () => {
  const { user, refetchUser } = useAuth();

  const { orgByHostname: currentHostnameOrg } = useOrgByCurrentHostname();

  const commonGroupIds = useMemo(() => {
    if (user && currentHostnameOrg && user.groupIds && currentHostnameOrg.groupIds) {
      return intersection(user.groupIds, currentHostnameOrg.groupIds);
    }

    return [];
  }, [currentHostnameOrg, user]);

  const { data: group } = useGetGroup(commonGroupIds.length > 0 ? commonGroupIds[0] : undefined);

  useEffect(() => {
    if (currentHostnameOrg && user && user.status === 'active') {
      const iBelongTocurrentHostnameOrg = commonGroupIds.length > 0;

      // Try to join the organization group if not part of
      if (!iBelongTocurrentHostnameOrg && currentHostnameOrg.groupIds.length > 0) {
        (async () => {
          await joinGroup(currentHostnameOrg.groupIds[0], {
            member: {
              type: 'user',
              userId: user._id,
            },
          });

          refetchUser();
        })();
      }
    }
  }, [currentHostnameOrg, user, group]);
};
