import React, { ReactElement, Suspense, lazy } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { QueryParamProvider } from 'use-query-params';
import { ReactRouter5Adapter } from 'use-query-params/adapters/react-router-5';
import { parse, stringify } from 'query-string';
import { ThemeProvider } from 'styled-components';

import ThemeConfig from 'theme';
import store from 'store';
import { AuthProvider } from 'views/shared/modules/auth/service';
import Loader from 'views/shared/components/loader';
import ProtectedRoute from 'views/shared/components/protected-route';
import LoadCurrentGroupContext from 'views/shared/components/load-current-group-context';
import RedirectFromNonExistentOrgDomain from 'views/shared/components/redirect-from-non-existent-org-domain';
import LoginRedirect from 'views/shared/components/login-redirect';
import AutoLogin from 'views/shared/components/auto-login';
import AutoSignup from 'views/shared/components/auto-signup';
import AutoSetLanguage from 'views/shared/components/auto-set-language';
import UserFeedbackProvider from 'views/feedback';
import AutoRedirectCustomDomain from 'views/shared/components/auto-redirect-custom-domain';

// Analytics
import { AnalyticsHooks } from 'services/analytics/hooks/AnalyticsHooks';
import AutoJoinGroup from 'views/shared/components/auto-join-group';
import FaviconSelector from 'views/shared/components/favicon-selector';

const Home = lazy(() => import('../views/home'));
const ForgotPassword = lazy(() => import('../views/forgot-password'));
const Instant = lazy(() => import('../views/instant'));
const OrganizationSignUp = lazy(() => import('../views/organization-sign-up'));
const NGOSignUp = lazy(() => import('../views/ngo-sign-up'));
const Volunteering = lazy(() => import('../views/volunteering'));
const MessagesHistory = lazy(() => import('../views/messages-history'));
const ProjectDetail = lazy(() => import('../views/project-detail'));
const ProjectApplyChooseEvents = lazy(() => import('../views/project-apply-choose-events'));
const ProjectApplyConfirm = lazy(() => import('../views/project-apply-confirm'));
const ProjectApplySuccess = lazy(() => import('../views/project-apply-success'));
const ProjectCancellation = lazy(() => import('../views/project-cancellation'));
const ProjectContact = lazy(() => import('../views/project-contact'));
const VolunteeringSuccess = lazy(() => import('../views/volunteering-success'));
// const VolunteerProfile = lazy(() => import('../views/volunteer-profile'));
const VolunteerProfileNew = lazy(() => import('../views/volunteer-profile-new'));
const AccountSettings = lazy(() => import('../views/account-settings'));
// const OrganizationDetail = lazy(() => import('../views/organization-detail'));
const OrganizationProfile = lazy(() => import('../views/organization-profile'));
const Calendar = lazy(() => import('../views/calendar'));

// const OrganizationDashboard = lazy(
//   () => import('../views/organization-dashboard')
// );

const Dashboard = lazy(() => import('../views/dashboard'));
// const OrganizationProjects = lazy(() => import('../views/organization-projects'));
const VolunteeringProjects = lazy(() => import('../views/(volunteering)/index'));
const OrganizationVolunteers = lazy(() => import('../views/organization-volunteers'));
// const OrganizationFaq = lazy(() => import('../views/organization-faq'));
const FAQNew = lazy(() => import('../views/faq'));
const OrganizationKnowledgeBase = lazy(() => import('../views/organization-knowledge-base'));
const OrganizationAccountSettings = lazy(() => import('../views/organization-account-settings'));
const OrganizationUpdateProject = lazy(() => import('../views/organization-update-project-v2'));
const OrganizationDuplicateProject = lazy(() => import('../views/organization-duplicate-project'));
const Chat = lazy(() => import('../views/chat'));
const Notifications = lazy(() => import('../views/notifications'));
const SocialWeek = lazy(() => import('../views/social-week'));
const CreateProjectOld = lazy(() => import('../views/create-project-old'));
const ShowCase = lazy(() => import('../views/internal/components-show-case'));
const CommunityFeed = lazy(() => import('../views/community-feed/page'));
// CorporateGiving
const CorporateGiving = lazy(() => import('../views/corporate-giving'));
const DonationProjectDetails = lazy(() => import('../views/corporate-giving/pages/donation-project-details'));
const DonationSuccess = lazy(() => import('../views/corporate-giving/pages/donation-success'));
const NotFound = lazy(() => import('../views/not-found'));

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
      staleTime: 1000 * 60 * 60 * 24, // 24 hours
    },
  },
});

const theme = {
  flexboxgrid: {
    // Defaults
    gridSize: 24, // columns
    gutterWidth: 1, // rem
    outerMargin: 2, // rem
  },
};

const IndexRouter: React.FC = (): ReactElement => {
  return (
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <AuthProvider>
          <LoadCurrentGroupContext />
          <RedirectFromNonExistentOrgDomain />
          <Router>
            <QueryParamProvider
              adapter={ReactRouter5Adapter}
              options={{
                searchStringToObject: parse,
                objectToSearchString: stringify,
              }}
            >
              <AutoLogin />
              <AutoSignup />
              <AutoJoinGroup />
              <AutoSetLanguage />
              <AutoRedirectCustomDomain />
              <LoginRedirect />
              <AnalyticsHooks />
              <FaviconSelector />
              <Suspense fallback={<Loader centered />}>
                <ThemeProvider theme={theme}>
                  <ThemeConfig>
                    <UserFeedbackProvider />
                    <Switch>
                      <Route path="/" exact component={Home} />
                      <Route path="/forgot-password" exact component={ForgotPassword} />
                      <ProtectedRoute path="/instant" exact component={Instant} />
                      <Route
                        path={['/organization-sign-up', '/organisation-sign-up']}
                        exact
                        component={OrganizationSignUp}
                      />
                      <Route path="/ngo-sign-up" exact component={NGOSignUp} />
                      <ProtectedRoute path="/dashboard" exact component={Dashboard} />
                      <ProtectedRoute path="/community-feed" exact component={CommunityFeed} />
                      <ProtectedRoute path="/volunteering" exact component={Volunteering} />
                      <ProtectedRoute path="/volunteering/:id" exact component={ProjectDetail} />
                      <ProtectedRoute
                        path="/volunteering/:id/apply-choose-events"
                        exact
                        component={ProjectApplyChooseEvents}
                      />
                      <ProtectedRoute path="/volunteering/:id/apply-confirm" exact component={ProjectApplyConfirm} />
                      <ProtectedRoute path="/volunteering/:id/apply-success" exact component={ProjectApplySuccess} />
                      <ProtectedRoute path="/volunteering/:id/cancel" exact component={ProjectCancellation} />
                      <ProtectedRoute path="/volunteering/:id/contact" exact component={ProjectContact} />
                      <ProtectedRoute
                        path="/volunteering/:id/participation-success"
                        exact
                        component={VolunteeringSuccess}
                      />
                      {/* <ProtectedRoute path="/profile" exact component={VolunteerProfile} />
                      <Route path="/profile/:id" exact component={VolunteerProfile} /> */}
                      <ProtectedRoute path="/profile" exact component={VolunteerProfileNew} />
                      <Route path="/profile/:id" exact component={VolunteerProfileNew} />
                      <ProtectedRoute path="/account-settings" exact component={AccountSettings} />
                      {/* <ProtectedRoute
                        path={['/organization/:id', '/organisation/:id']}
                        exact
                        component={OrganizationDetail}
                      /> */}
                      <ProtectedRoute
                        path={['/organization/:id', '/organisation/:id']}
                        exact
                        component={OrganizationProfile}
                      />
                      <ProtectedRoute path="/upcoming" exact component={Calendar} />
                      {/* <ProtectedRoute path="/my-projects" exact component={OrganizationProjects} /> */}
                      <ProtectedRoute path="/my-projects" exact component={VolunteeringProjects} />
                      {/* CorporateGiving */}
                      <ProtectedRoute path="/donation-projects" exact component={CorporateGiving} />
                      <ProtectedRoute path="/donation-projects/:id" exact component={DonationProjectDetails} />
                      <ProtectedRoute
                        path="/donation-projects/:id/donation-success"
                        exact
                        component={DonationSuccess}
                      />
                      <ProtectedRoute
                        path="/my-projects/:id"
                        exact
                        component={() => <ProjectDetail withOrganizationMenu />}
                      />
                      <ProtectedRoute path="/project-manage" exact component={OrganizationVolunteers} />
                      <ProtectedRoute path="/messages-history" exact component={MessagesHistory} />
                      {/* <Route path="/faq" exact component={OrganizationFaq} /> */}
                      <Route path="/faq" exact component={FAQNew} />
                      <Route path="/knowledge-base" exact component={OrganizationKnowledgeBase} />
                      <ProtectedRoute
                        path={['/organization-account-settings', '/organisation-account-settings']}
                        groupRole="admin"
                        exact
                        component={OrganizationAccountSettings}
                      />
                      <ProtectedRoute path="/edit-project-new/:id" component={OrganizationUpdateProject} />
                      <ProtectedRoute path="/create-project-new" exact component={OrganizationUpdateProject} />
                      <ProtectedRoute path="/duplicate/:id" exact component={OrganizationDuplicateProject} />
                      <ProtectedRoute path="/create-project/:tab/:selectTimeMode" exact component={CreateProjectOld} />
                      <ProtectedRoute path="/chat" exact component={Chat} />
                      <ProtectedRoute path="/chat/rooms/:roomId" exact component={Chat} />
                      <ProtectedRoute path="/chat/group-inbox/:groupId" component={Chat} />
                      <ProtectedRoute path="/chat/group-inbox-in/:groupId/rooms/:roomId" component={Chat} />
                      <ProtectedRoute path="/notifications" exact component={Notifications} />
                      <Route path="/components-showcase" exact component={ShowCase} />
                      <Route path="/social-week" exact component={SocialWeek} />
                      <Route component={NotFound} />
                    </Switch>
                  </ThemeConfig>
                </ThemeProvider>
              </Suspense>
            </QueryParamProvider>
          </Router>
          <ReactQueryDevtools />
        </AuthProvider>
      </QueryClientProvider>
    </Provider>
  );
};

export default IndexRouter;
