import React from 'react';

import useFaviconSelector from './use-favicon-selector';

const FaviconSelector: React.FC = () => {
  useFaviconSelector();
  return null;
};

export default FaviconSelector;
