import { useEffect, ReactElement } from 'react';
import { Redirect, Route, RouteProps, useLocation } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';

import { RootState } from 'store/reducers';
import Loader from 'views/shared/components/loader';
import { setRedirectPath } from 'views/shared/components/protected-route/protected-route-redux';
import { useCurrentOrganizationAndGroup } from 'views/shared/hooks/use-current-organization-and-group';
import { useAuth } from 'views/shared/modules/auth/service';

const authenticationPath = '/';

interface Props {
  groupRole?: 'admin' | 'member';
}

export default function ProtectedRoute({ ...routeProps }: RouteProps & Props): ReactElement {
  const currentLocation = useLocation();

  const dispatch = useDispatch();
  const redirectPath = useSelector((state: RootState) => state.protectedRoute.redirectPath);
  const { user } = useAuth();
  const { role, group } = useCurrentOrganizationAndGroup();

  useEffect(() => {
    if (!user || (user && user.status !== 'active') || (routeProps.groupRole && role !== routeProps.groupRole)) {
      dispatch(setRedirectPath(currentLocation.pathname));
    }
  }, [user, role, currentLocation]);

  if (
    user &&
    user.status === 'active' &&
    (!routeProps.groupRole || (routeProps.groupRole && role === routeProps.groupRole))
  ) {
    return <Route {...routeProps} />;
  }

  if (!group) return <Loader centered />;

  return <Redirect to={{ pathname: user ? redirectPath : authenticationPath }} />;
}
