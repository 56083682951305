/* eslint-disable */
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Map, GoogleApiWrapper } from 'google-maps-react';
import { useTranslation } from 'react-i18next';

import PageTitle from '../../common/PageTitle';
import PageSubTitle from '../../common/PageSubTitle';

import LocationSearch from './LocationSearch';

const Wrapper = styled.div`
  width: 100%;
  max-width: 1000px;
`;

const ContentWrapper = styled.div`
  display: flex;
  justify-content: center;
  max-width: 1000px;
  height: 600px;
`;

function SetLocation(props) {
  const { google } = props;
  const { t } = useTranslation();

  return (
    <Wrapper>
      <PageTitle>{t('createProjectOld.steps.setLocation.title')}</PageTitle>
      <PageSubTitle>
        {t('createProjectOld.steps.setLocation.subtitle')}
      </PageSubTitle>
      <ContentWrapper>
        <Map google={google} visible={false}>
          <LocationSearch {...props} />
        </Map>
      </ContentWrapper>
    </Wrapper>
  );
}

const SetLocationWrapper = GoogleApiWrapper({
  apiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY || '',
})(SetLocation);

export default SetLocationWrapper;
