import React from 'react';

import { initReactQueryAuth } from 'react-query-auth';

import storage from 'views/shared/utils/storage';

import { getTokenPayload } from 'views/shared/utils/get-token-payload';

import { loginWithEmailAndPassword, LoginData } from 'views/shared/modules/auth/api/login';
import {
  RegisterData,
  // InstantSignupData,
  registerWithEmailAndPassword,
  registerWithEmailOnly,
  instanceOfRegisterRes,
} from 'views/shared/modules/auth/api/register';
import { getUser } from 'views/shared/modules/auth/api/get-user';
import { User } from 'views/shared/modules/user/model';

import Loader from 'views/shared/components/loader';

async function loadUser(): Promise<User | undefined> {
  const queryString = window.location.search;
  const params = new URLSearchParams(queryString);
  // const token = params.has('token') && getTokenPayload(params.get('token'));

  if (params.has('token')) {
    try {
      return await getUser();
    } catch (error) {
      console.log('👍🏼 ->', error);
      return undefined;
    }
  }

  if (storage.getToken()) {
    try {
      return await getUser();
    } catch (err) {
      return undefined;
    }
  }

  return undefined;
}

async function loginFn(initData: LoginData) {
  const data = { ...initData };

  const currentGroupId = storage.getCurrentGroupId();
  if (currentGroupId) {
    data.groupId = currentGroupId;
  }

  const response = await loginWithEmailAndPassword(data);
  const {
    user,
    token,
    //  firebaseToken
  } = response;
  storage.setToken(token);
  // storage.setFirebaseToken(firebaseToken);
  return user;
}

async function registerFn(data: RegisterData) {
  try {
    let response;
    // check if the token is instant token (magic-link)
    const queryString = window.location.search;
    const params = new URLSearchParams(queryString);
    const token = params.has('token') && getTokenPayload(params.get('token'));

    if (token && token.email) {
      response = await registerWithEmailOnly(data);
    } else {
      // if not then trigger normal signup flow email and password
      response = await registerWithEmailAndPassword(data);
    }

    if (instanceOfRegisterRes(response)) {
      const {
        user,
        token,
        // firebaseToken
      } = response;
      storage.setToken(token);
      // storage.setFirebaseToken(firebaseToken);
      return user;
    }
    throw response;
  } catch (err) {
    console.log(err);
    throw new Error('Register failed!');
  }
}

async function logoutFn() {
  await storage.clearToken();
  await storage.clearInstantToken();
  // await storage.clearFirebaseToken();
  await storage.clearCurrentGroupId();
  await storage.clearCurrentOrganizationId();

  window.location.replace('/');
}

const LoaderComponent = () => <Loader centered />;

const authConfig = {
  loadUser,
  loginFn,
  registerFn,
  logoutFn,
  LoaderComponent,
};

const { AuthProvider, useAuth } = initReactQueryAuth<User | undefined>(authConfig);

export { AuthProvider, useAuth };
