import { useCallback } from 'react';
// Locales
import { useTranslation } from 'react-i18next';
import { Localization, enUS, deDE } from '@mui/material/locale';
// Dates
import enLocale from 'date-fns/locale/en-US';
import deLocale from 'date-fns/locale/de';

// ----------------------------------------------------------------------

export type Locales = 'en' | 'de';
export type LocalesCode = 'en-US' | 'de-DE';

export interface LocaleI {
  label: string;
  value: Locales;
  code: LocalesCode;
  systemValue: Localization;
  dateValue: Locale;
  icon: string; // flag
}

const LANGS: LocaleI[] = [
  {
    label: 'English',
    value: 'en',
    code: 'en-US',
    systemValue: enUS,
    dateValue: enLocale,
    icon: 'emojione-v1:flag-for-united-kingdom',
  },
  {
    label: 'German',
    value: 'de',
    code: 'de-DE',
    systemValue: deDE,
    dateValue: deLocale,
    icon: 'emojione-v1:flag-for-germany',
  },
];

export default function useLocales(ns?: string | undefined) {
  const { i18n, t: translate } = useTranslation(ns);
  const langStorage = localStorage.getItem('i18nextLng');
  const currentLang = LANGS.find((_lang) => _lang.value === langStorage) || LANGS[1];
  const handleChangeLanguage = useCallback(
    (newLang: string) => {
      i18n.changeLanguage(newLang);
    },
    [i18n]
  );

  return {
    handleChangeLanguage,
    translate,
    currentLang,
    allLangs: LANGS,
  };
}
