import { FC } from 'react';
import { Stack, Typography } from '@mui/material';

import { getEmoji } from 'views/shared/utils/get-emoji';

import { CustomButton } from '../custom-button/button';
import { AnimateWrap } from '../animations';

interface Props {
  emoji: string;
  reactionsAmount: number;
  handleClick: VoidFunction;
  isOwnReaction?: boolean;
}

export const EmojiButton: FC<Props> = ({ handleClick, emoji, reactionsAmount, isOwnReaction }) => {
  return (
    <AnimateWrap size="small">
      <CustomButton
        size="small"
        sx={{
          padding: '4px 8px',
          minWidth: '32px',
          height: 32,
          boxShadow: 'none',
        }}
        variant={isOwnReaction ? 'contained' : 'contained'}
        color={isOwnReaction ? 'primary' : 'inherit'}
        handleClick={handleClick}
        label={
          <Stack gap="8px" direction="row" alignItems="center" px="5px">
            <Typography variant="body1">{getEmoji(emoji)}</Typography>
            <Typography variant="body1" color={isOwnReaction ? 'grey.0' : 'inherit'}>
              {reactionsAmount}
            </Typography>
          </Stack>
        }
      />
    </AnimateWrap>
  );
};
