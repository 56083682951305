/* eslint-disable */
import React, { Component } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import get from 'lodash/get';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

import {
  submitDetails as submitDetailsRedux,
  setCurrentPage as setCurrentPageRedux,
  redirectToPreviousPage as redirectToPreviousPageRedux,
} from 'views/create-project-old/store/ducks/createProject/actions';
import { selectInfo } from 'views/create-project-old/store/ducks/createProject/selectors';
import AddIcon from 'assets/images/add.svg';

import Title from '../../common/PageTitle';
import SubTitle from '../../common/PageSubTitle';
import Button, { BackButton } from '../../common/Button';
import Tips from '../../common/Tips';

const arrayStab = Array(3).fill('');

const Wrapper = styled.div`
  width: 100%;
  max-width: 1000px;
`;

const ButtonWrapper = styled.div`
  width: 335px;
  height: 56px;
`;

const ButtonsWrapper = styled.div`
  width: 750px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 50px auto;
`;

const TextAreaSubTitle = styled(SubTitle)`
  text-align: start;
  margin: 10px 0;
`;

const TextAreaWrapper = styled.div`
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  margin-top: 20px;
`;

const TextArea = styled.textarea`
  width: 100%;
  height: 230px;
  resize: none;
  background: #f6f7f9;
  border-radius: 20px;
  font-size: 16px;
  border: none;
  outline: none;
  padding: 20px;

  ::placeholder {
    color: #c5c5c9;
  }
`;

const TextAreaLabel = styled.div`
  color: #000;
  font-size: 14px;
  text-align: end;
  font-weight: bold;
  width: 100%;
`;

const ShowAdditionalInfo = styled.div`
  display: flex;
  align-items: center;
  font-size: 20px;
  color: #000;
  font-weight: bold;
  cursor: pointer;
  margin: 20px 0;

  :before {
    content: '';
    display: block;
    background-image: url(${AddIcon});
    background-position: 50% 50%;
    background-repeat: no-repeat;
    width: 24px;
    height: 24px;
    margin-right: 10px;
  }
`;

const TipsWrapper = styled.div`
  max-width: 800px;
  margin: 50px auto;
  display: flex;
  justify-content: space-between;
`;

const DescriptionInput = ({
  title,
  subTitle,
  onChange,
  value,
  placeholder,
  maxchar,
}) => (
  <TextAreaWrapper>
    <Title>{title}</Title>
    <TextAreaSubTitle>{subTitle}</TextAreaSubTitle>
    <TextArea onChange={onChange} value={value} placeholder={placeholder} />
    <TextAreaLabel>{maxchar}</TextAreaLabel>
  </TextAreaWrapper>
);

class Description extends Component {
  constructor(props) {
    super(props);

    this.state = {
      purpose: '',
      role: '',
      additionalInfo: '',
      showAdditionalInfo: false,
    };
  }

  static getDerivedStateFromProps(props, state) {
    if (!state.purpose) {
      const { purpose, role, additionalInfo } = get(props, 'info', {});

      let newState = { purpose, role };

      if (additionalInfo) {
        newState = {
          ...newState,
          additionalInfo,
          showAdditionalInfo: true,
        };
      }

      return newState;
    }

    return null;
  }

  componentDidMount() {
    const { setCurrentPageRedux: setCurrentPage } = this.props;
    setCurrentPage(5);
  }

  handleInput = ({ value, type }) => {
    if (value.length > 600) return;
    this.setState({ [type]: value });
  };

  handleShowAdditionalInfo = () => {
    this.setState({ showAdditionalInfo: true });
  };

  handleSubmit = () => {
    const { purpose, role, additionalInfo } = this.state;
    const { submitDetailsRedux: submitDetails } = this.props;
    submitDetails({ purpose, role, additionalInfo });
    this.props.history.push('/create-project/options');
  };

  render() {
    const {
      showAdditionalInfo,
      purpose,
      role,
      additionalInfo,
      redirectToPreviousPage,
    } = this.state;
    const isSubmitButtonDisabled = !purpose.length || !role.length;
    return (
      <Wrapper>
        <Title>
          {this.props.t('createProjectOld.steps.setDescription.title')}
        </Title>
        <SubTitle>
          {this.props.t('createProjectOld.steps.setDescription.subtitle')}
        </SubTitle>

        <DescriptionInput
          placeholder={this.props.t('createProjectOld.placeholder')}
          title={this.props.t(
            'createProjectOld.steps.setDescription.purpose.title'
          )}
          subTitle={this.props.t(
            'createProjectOld.steps.setDescription.purpose.subtitle'
          )}
          maxchar={this.props.t('createProjectOld.maxchar', {
            value: 600 - purpose.length,
          })}
          onChange={(e) =>
            this.handleInput({ value: e.target.value, type: 'purpose' })
          }
          value={purpose}
        />
        <DescriptionInput
          placeholder={this.props.t('createProjectOld.placeholder')}
          title={this.props.t(
            'createProjectOld.steps.setDescription.role.title'
          )}
          subTitle={this.props.t(
            'createProjectOld.steps.setDescription.role.subtitle'
          )}
          maxchar={this.props.t('createProjectOld.maxchar', {
            value: 600 - purpose.length,
          })}
          onChange={(e) =>
            this.handleInput({ value: e.target.value, type: 'role' })
          }
          value={role}
        />

        {!showAdditionalInfo && (
          <ShowAdditionalInfo onClick={this.handleShowAdditionalInfo}>
            {this.props.t(
              'createProjectOld.steps.setDescription.additional.title'
            )}
          </ShowAdditionalInfo>
        )}
        {showAdditionalInfo && (
          <DescriptionInput
            placeholder={this.props.t('createProjectOld.placeholder')}
            title={this.props.t(
              'createProjectOld.steps.setDescription.additional.title'
            )}
            maxchar={this.props.t('createProjectOld.maxchar', {
              value: 600 - purpose.length,
            })}
            onChange={(e) =>
              this.handleInput({
                value: e.target.value,
                type: 'additionalInfo',
              })
            }
            value={additionalInfo}
          />
        )}

        <ButtonsWrapper>
          <ButtonWrapper>
            <BackButton
              onClick={() =>
                this.props.history.push('/create-project/location')
              }>
              {this.props.t('createProjectOld.back')}
            </BackButton>
          </ButtonWrapper>
          <ButtonWrapper>
            <Button
              disabled={isSubmitButtonDisabled}
              onClick={this.handleSubmit}>
              {this.props.t('createProjectOld.next')}
            </Button>
          </ButtonWrapper>
        </ButtonsWrapper>

        <TipsWrapper>
          <Tips
            title={this.props.t('createProjectOld.do')}
            subTitle={this.props.t(
              'createProjectOld.steps.setDescription.good.title'
            )}
            type="do"
            tips={arrayStab.map((_o, index) =>
              this.props.t(
                `createProjectOld.steps.setDescription.good.examples.${index}`
              )
            )}
          />
          <Tips
            title={this.props.t('createProjectOld.dont')}
            subTitle={this.props.t(
              'createProjectOld.steps.setDescription.bad.title'
            )}
            type="dont"
            tips={arrayStab.map((_o, index) =>
              this.props.t(
                `createProjectOld.steps.setDescription.bad.examples.${index}`
              )
            )}
          />
        </TipsWrapper>
      </Wrapper>
    );
  }
}

const mapState = (state) => ({
  info: selectInfo(state),
});

const mapDispatch = {
  submitDetailsRedux,
  setCurrentPageRedux,
  redirectToPreviousPageRedux,
};

export default connect(
  mapState,
  mapDispatch
)(withTranslation()(withRouter(Description)));
