/* eslint-disable */
import React from 'react';
import styled from 'styled-components';

const Card = styled.div`
  background: #45dbd0;
  padding: 5px;
  display: inline-block;
  border-radius: 8px;
  position: relative;
  width: 100%;
  cursor: pointer;
  margin: 5px 0;

  :before {
    content: '';
    width: 10px;
    height: 2px;
    display: block;
    background-color: #45dbd0;
    position: absolute;
    z-index: 1;
    left: calc(14% + 7px + 7px);
  }

  :after {
    content: '';
    width: 10px;
    height: 2px;
    display: block;
    background-color: #45dbd0;
    position: absolute;
    z-index: 1;
    right: calc(14% + 7px + 7px);
    bottom: 5px;
  }
`;

const Content = styled.div`
  padding: 6px;
  position: relative;
  border-radius: 5px;
  border: 2px solid #fff;
  text-align: center;
  color: #fff;
  font-size: 14px;

  :before {
    content: '';
    width: 7px;
    height: 7px;
    display: block;
    border-top: 2px solid #fff;
    border-right: 2px solid #fff;
    position: absolute;
    transform: rotate(45deg);
    transform-origin: top right;
    top: -1px;
    left: calc(16% + 1px);
    z-index: 2;
  }

  :after {
    content: '';
    width: 7px;
    height: 7px;
    display: block;
    border-bottom: 2px solid #fff;
    border-left: 2px solid #fff;
    position: absolute;
    transform: rotate(45deg);
    transform-origin: bottom left;
    bottom: -1px;
    right: calc(16% + 1px);
    z-index: 2;
  }
`;

export default function EventCard({ shifts, onClick }) {
  return (
    <div onClick={onClick}>
      {shifts.map((shift) => {
        const { from, to } = shift;
        return (
          <Card>
            <Content>
              {from} - {to}
            </Content>
          </Card>
        );
      })}
    </div>
  );
}
