import React from 'react';

import useAutoRedirectCustomDomain from './use-auto-redirect-custom-domain';

const AutoRedirectCustomDomain: React.FC = () => {
  useAutoRedirectCustomDomain();
  return null;
};

export default AutoRedirectCustomDomain;
