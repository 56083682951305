import { ReactNode, createContext, useContext } from 'react';
// hooks
import useLocalStorage from '../../views/shared/hooks/use-local-storage';
// utils
import getColorPresets, { colorPresets, defaultPreset } from './utils/get-color-presets';
// config
import { defaultSettings } from './config';
// @type
import { ThemeMode, ThemeColorPresets, SettingsContextProps, SettingsValueProps, ColorVariants } from './models/type';

// ----------------------------------------------------------------------

const initialState: SettingsContextProps = {
  ...defaultSettings,
  onChangeMode: () => {},
  onToggleMode: () => {},
  onChangeColor: () => {},
  onToggleStretch: () => {},
  onResetSetting: () => {},
  onChangeColorConfig: () => {},
  currentColor: defaultPreset,
  colorOption: [],
};

const SettingsContext = createContext(initialState);

type SettingsProviderProps = {
  children: ReactNode;
};

function SettingsProvider({ children }: SettingsProviderProps) {
  const { value: settings, handleChangeValue: setSettings } = useLocalStorage<SettingsValueProps>('settings', {
    themeMode: initialState.themeMode,
    themeColorPresets: initialState.themeColorPresets,
    currentColor: initialState.currentColor,
  });

  const onChangeMode = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSettings({
      ...settings,
      themeMode: (event.target as HTMLInputElement).value as ThemeMode,
    });
  };

  const onToggleMode = () => {
    setSettings({
      ...settings,
      themeMode: settings.themeMode === 'light' ? 'dark' : 'light',
    });
  };

  const onChangeColor = (newColorPreset: ThemeColorPresets) => {
    setSettings({
      ...settings,
      themeColorPresets: newColorPreset,
      currentColor: getColorPresets(newColorPreset),
    });
  };

  const onChangeColorConfig = (newColorVariant: ColorVariants) => {
    setSettings({
      ...settings,
      themeColorPresets: newColorVariant.name,
      currentColor: newColorVariant,
    });
  };

  const onResetSetting = () => {
    setSettings({
      themeMode: initialState.themeMode,
      themeColorPresets: initialState.themeColorPresets,
      currentColor: initialState.currentColor,
    });
  };

  return (
    <SettingsContext.Provider
      value={{
        ...settings,
        // Mode
        onChangeMode,
        onToggleMode,
        // Color
        onChangeColor,
        onChangeColorConfig,
        currentColor: settings.currentColor || getColorPresets(settings.themeColorPresets),
        colorOption: colorPresets.map((color) => ({
          name: color.name,
          value: color.main,
        })),
        // Reset Setting
        onResetSetting,
      }}
    >
      {children}
    </SettingsContext.Provider>
  );
}

const useSettings = () => useContext(SettingsContext);

export { SettingsProvider, SettingsContext, useSettings };
