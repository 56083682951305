import { PathnameTypes, routesWithFeedback } from 'models/feedback';
import { matchPath, useLocation } from 'react-router-dom';
import { FeedbackActivityClient } from 'views/feedback/hooks/useSubmitFeedback';

const usePathPattern = (): PathnameTypes => {
  const { pathname } = useLocation();

  return (
    routesWithFeedback.find((route) =>
      matchPath(pathname, {
        path: route,
        exact: true,
        strict: false,
      })
    ) || (pathname as PathnameTypes)
  );
};

const extractTargetId = (pathName: string, patterns: PathnameTypes[] = routesWithFeedback): string | null => {
  const urlParts = pathName.split('/');

  const matchingPattern = patterns.find((pattern) => {
    const patternParts = pattern.split('/');
    const idIndex = patternParts.indexOf(':id');
    return idIndex !== -1 && urlParts.length === patternParts.length;
  });

  if (matchingPattern) {
    const idIndex = matchingPattern.split('/').indexOf(':id');
    return urlParts[idIndex];
  }

  return null;
};

export const getFeedbackActivityType = (pathName: string): Partial<FeedbackActivityClient> => {
  const targetId = extractTargetId(pathName);
  if (targetId)
    return {
      target: 'project',
      targetId,
    };

  return {
    target: 'application',
  };
};

export default usePathPattern;
