import storage from 'views/shared/utils/storage';
import { api } from 'views/shared/modules/api/request';
import { Group } from 'views/shared/modules/group/model';

export interface GroupData {
  data: Group;
}

export interface MemberData {
  member: {
    userId: string;
    type: string;
  };
}

export async function joinGroup(id: string, data: MemberData): Promise<Group> {
  try {
    const res = await api.post<GroupData>(`/groups/${id}/join`, data, {
      headers: {
        Authorization: storage.getToken(),
      },
    });

    const { data: groupData } = res;

    return Promise.resolve(groupData.data);
  } catch (err) {
    return Promise.reject(err);
  }
}
