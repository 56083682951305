import { FC } from 'react';
import { SxProps, Theme, Typography, TypographyVariant, Skeleton, SkeletonProps } from '@mui/material';
// Animations
import { AnimatePresence, m } from 'framer-motion';
import { varZoom } from 'views/shared/components/animations';

const AnimatedData: FC<{
  data: number | string;
  sx?: SxProps<Theme>;
  variant?: TypographyVariant;
  loading?: boolean;
  skeletonProps?: SkeletonProps;
}> = ({ data, sx, variant = 'h3', loading = false, skeletonProps }) => {
  return (
    <AnimatePresence exitBeforeEnter>
      <Typography
        sx={{ whiteSpace: 'nowrap', ...sx }}
        key={data}
        component={m.span}
        // initial={varFade().in.initial}
        // animate={varFade({ durationIn: 0.2 }).in.animate}
        // exit={varFade({ durationOut: 0.2 }).out.exit}
        {...varZoom({ durationIn: 0.1, durationOut: 0.1 }).in}
        variant={variant}
      >
        {loading ? <Skeleton width="40px" {...skeletonProps} /> : `${data}`}
      </Typography>
    </AnimatePresence>
  );
};

export default AnimatedData;
