import { SettingsValueProps } from './models/type';
import getColorPresets from './utils/get-color-presets';

// THEME SETTINGS
// Please remove `localStorage` when you set settings.
// ----------------------------------------------------------------------
export const defaultSettings: SettingsValueProps = {
  themeMode: 'light',
  themeColorPresets: 'default',
  currentColor: getColorPresets('default'),
};
