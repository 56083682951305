import { useMemo, ReactNode } from 'react';
// material
import { createTheme, ThemeOptions, ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import useLocales from 'views/shared/hooks/use-locales';
// Providers
import { MotionLazyProvider } from 'views/shared/components/animations';
import NotistackProvider from 'views/shared/components/snackbar/notistack-snackbar-provider';
// Overrides
import componentsOverride from './overrides';
// Theme customs
import palette from './palette';
import typography from './typography';
import shape from './shape';
import breakpoints from './breakpoints';
import shadows, { customShadows } from './shadows';
import ThemeColorPresets from './theme-color-presets';
import { SettingsProvider, useSettings } from './settings/settings-provider';
// Styles
import GlobalStyles from './globalStyles';
import ChartStyles from './custom-styles/chart-styles';
// Lazy loading image
import 'react-lazy-load-image-component/src/effects/blur.css';
import 'react-lazy-load-image-component/src/effects/opacity.css';
import 'react-lazy-load-image-component/src/effects/black-and-white.css';
// scroll bar
import 'simplebar/src/simplebar.css';
// editor
import 'react-quill/dist/quill.snow.css';
// lightbox
import 'react-image-lightbox/style.css';

// ----------------------------------------------------------------------

type ThemeConfigProps = {
  children: ReactNode;
};

export default function ThemeConfig({ children }: ThemeConfigProps) {
  const { themeMode } = useSettings();
  const isLight = themeMode === 'light';

  const { currentLang } = useLocales();
  const themeOptions: ThemeOptions = useMemo(
    () => ({
      palette: isLight ? palette.light : palette.dark,
      typography,
      shape,
      breakpoints,
      shadows: isLight ? shadows.light : shadows.dark,
      customShadows: isLight ? customShadows.light : customShadows.dark,
    }),
    [isLight]
  );

  const theme = createTheme(themeOptions, currentLang.systemValue);
  theme.components = componentsOverride(theme);

  return (
    <StyledEngineProvider injectFirst>
      <SettingsProvider>
        <ThemeProvider theme={theme}>
          <ThemeColorPresets>
            <GlobalStyles />
            <ChartStyles />
            <MotionLazyProvider>
              <NotistackProvider>{children}</NotistackProvider>
            </MotionLazyProvider>
          </ThemeColorPresets>
        </ThemeProvider>
      </SettingsProvider>
    </StyledEngineProvider>
  );
}
