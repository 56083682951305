// theme
import palette from 'theme/palette';
// @type
import { ColorVariants, ThemeColorPresets } from '../models/type';

// ----------------------------------------------------------------------

export const colorPresets: ColorVariants[] = [
  // DEFAULT
  {
    name: 'default',
    ...palette.light.primary,
  },
  // PURPLE
  {
    name: 'purple',
    lighter: '#EBD6FD',
    light: '#B985F4',
    main: '#7635dc',
    dark: '#431A9E',
    darker: '#200A69',
    contrastText: '#fff',
  },
  // CYAN
  {
    name: 'cyan',
    lighter: '#D1FFFC',
    light: '#76F2FF',
    main: '#1CCAFF',
    dark: '#0E77B7',
    darker: '#053D7A',
    contrastText: palette.light.grey[800],
  },
  // BLUE
  {
    name: 'blue',
    lighter: '#D1E9FC',
    light: '#76B0F1',
    main: '#2065D1',
    dark: '#103996',
    darker: '#061B64',
    contrastText: '#fff',
  },
  // ORANGE
  {
    name: 'orange',
    lighter: '#FEF4D4',
    light: '#FED680',
    main: '#fda92d',
    dark: '#B66816',
    darker: '#793908',
    contrastText: palette.light.grey[800],
  },
  // RED
  {
    name: 'red',
    lighter: '#FFE3D5',
    light: '#FFC1AC',
    main: '#FF3030',
    dark: '#B71833',
    darker: '#7A0930',
    contrastText: '#fff',
  },
  {
    name: 'AdmiralDirekt',
    lighter: 'ignored',
    light: '#B4EEA1',
    main: '#62BB46',
    dark: '#4A8C35',
    darker: 'ignored',
    contrastText: '#F5F8FD',
  },
  {
    name: 'is2',
    lighter: 'ignored',
    light: '#BFC0E0',
    main: '#000282',
    dark: '#000262',
    darker: 'ignored',
    contrastText: '#FCFCFD',
  },
  {
    name: 'immowelt',
    lighter: '#FFFAE3',
    light: '#FFF6D1',
    main: '#FFCC00',
    dark: '#7A6200',
    darker: '#493b00',
    contrastText: '#303038',
  },
];

export const defaultPreset = colorPresets[0];
export const purplePreset = colorPresets[1];
export const cyanPreset = colorPresets[2];
export const bluePreset = colorPresets[3];
export const orangePreset = colorPresets[4];
export const redPreset = colorPresets[5];
// Orgs
export const AdmiralDirekt = colorPresets[6];
export const is2 = colorPresets[7];
export const immowelt = colorPresets[8];

export default function getColorPresets(presetsKey: ThemeColorPresets) {
  return (
    {
      purple: purplePreset,
      cyan: cyanPreset,
      blue: bluePreset,
      orange: orangePreset,
      red: redPreset,
      // orgs
      AdmiralDirekt,
      is2,
      immowelt,
      default: defaultPreset,
    }[presetsKey] || defaultPreset
  );
}
