/* eslint-disable */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Switch, Slider as AntSlider } from 'antd';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import get from 'lodash/get';

import {
  setOptions as setOptionsRedux,
  createProject as createProjectRedux,
  setCurrentPage as setCurrentPageRedux,
  redirectToPreviousPage as redirectToPreviousPageRedux,
} from 'views/create-project-old/store/ducks/createProject/actions';
import { selectOptions } from 'views/create-project-old/store/ducks/createProject/selectors';

import Title from '../../common/PageTitle';
import PageSubTitle from '../../common/PageSubTitle';
import Button, { BackButton } from '../../common/Button';

const Wrapper = styled.div`
  width: 100%;
  max-width: 1000px;
`;

const SubTitle = styled(PageSubTitle)`
  margin-bottom: 55px;
`;

const ButtonWrapper = styled.div`
  width: 335px;
  height: 56px;
`;

const ButtonsWrapper = styled.div`
  width: 750px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 50px auto;
`;

const OptionWrapper = styled.div`
  width: 100%;
  padding: 60px 100px;
  background: #fff;
  box-shadow: 5px 7px 30px rgba(0, 0, 0, 0.07);
  border-radius: 9px;
  margin-top: 35px;
`;
const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const OptionTitle = styled.div`
  font-size: 20px;
  font-weight: bold;
  color: #000;
`;
const OptionText = styled.div`
  margin-top: 20px;
  font-size: 16px;
  color: #8e8e93;
`;

const SliderWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const SliderLabel = styled.span`
  color: #45dbd0;
  font-size: 15px;
`;

const Slider = styled(AntSlider)`
  width: 450px;
  margin-right: 20px;
`;

const Option = ({ title, text, children, active, onChange }) => (
  <OptionWrapper>
    <TitleWrapper>
      <OptionTitle>{title}</OptionTitle>
      <Switch checked={active} onChange={onChange} />
    </TitleWrapper>
    <OptionText>{text}</OptionText>
    {active && children}
  </OptionWrapper>
);

class Options extends Component {
  constructor(props) {
    super(props);

    this.state = {
      applicationRequired: false,
      maxParticipants: false,
      maxParticipantsValue: 0,
      minimumAge: false,
      minimumAgeValue: 16,
      isMappedFromProps: false,
      // private: false,
      private: true,
    };
  }

  static getDerivedStateFromProps(props, state) {
    if (!state.isMappedFromProps) {
      let newState = { isMappedFromProps: true };

      const {
        applicationRequired,
        maxParticipants,
        minimumAge,
        private: isPrivate,
      } = get(props, 'options', {});

      if (applicationRequired) {
        newState.applicationRequired = applicationRequired;
      }

      if (maxParticipants) {
        newState = {
          ...newState,
          maxParticipants: true,
          maxParticipantsValue: maxParticipants,
        };
      }

      if (minimumAge !== 16) {
        newState = {
          ...newState,
          minimumAge: true,
          minimumAgeValue: minimumAge,
        };
      }

      // newState.private = isPrivate;
      newState.privet = true;

      return newState;
    }

    return null;
  }

  componentDidMount() {
    const { setCurrentPageRedux: setCurrentPage } = this.props;

    setCurrentPage(6);
  }

  handleOptionChange = ({ type, active }) => {
    this.setState({ [type]: active });
  };

  handleSliderChange = ({ field, value }) => {
    this.setState({ [field]: value });
  };

  handleSetOptions = () => {
    const {
      applicationRequired,
      maxParticipantsValue,
      minimumAgeValue,
      private: isPrivate,
    } = this.state;
    const { createProjectRedux: createProject, setOptionsRedux: setOptions } =
      this.props;

    if (!applicationRequired && !maxParticipantsValue && !minimumAgeValue) {
      createProject();
    }

    const options = {};

    if (applicationRequired) {
      options.applicationRequired = applicationRequired;
    }

    if (maxParticipantsValue) {
      options.maxParticipants = maxParticipantsValue;
    }

    if (minimumAgeValue) {
      options.minimumAge = minimumAgeValue;
    }

    // options.private = isPrivate;
    options.private = true;

    setOptions(options);
  };

  handleSubmit = () => {
    const { createProjectRedux: createProject } = this.props;

    this.handleSetOptions();
    createProject();
    this.props.history.push('/my-projects');
  };

  render() {
    const {
      applicationRequired,
      maxParticipants,
      maxParticipantsValue,
      minimumAge,
      minimumAgeValue,
      private: isPrivate,
    } = this.state;
    const { redirectToPreviousPageRedux: redirectToPreviousPage } = this.props;
    return (
      <Wrapper>
        <Title>{this.props.t('createProjectOld.steps.setOptions.title')}</Title>
        <SubTitle>
          {this.props.t('createProjectOld.steps.setOptions.subtitle')}
        </SubTitle>
        <Option
          title={this.props.t(
            'createProjectOld.steps.setOptions.confirm.title'
          )}
          text={this.props.t(
            'createProjectOld.steps.setOptions.confirm.subtitle'
          )}
          active={applicationRequired}
          onChange={(active) =>
            this.handleOptionChange({ type: 'applicationRequired', active })
          }
        />
        <Option
          title={this.props.t(
            'createProjectOld.steps.setOptions.private.title'
          )}
          text={this.props.t(
            'createProjectOld.steps.setOptions.private.subtitle'
          )}
          active={isPrivate}
          onChange={(active) =>
            // this.handleOptionChange({ type: 'private', active })
            this.handleOptionChange({ type: 'private', active: true })
          }
        />
        <Option
          title={this.props.t(
            'createProjectOld.steps.setOptions.quantity.title'
          )}
          text={this.props.t(
            'createProjectOld.steps.setOptions.quantity.subtitle'
          )}
          active={maxParticipants}
          onChange={(active) => {
            this.handleOptionChange({ type: 'maxParticipants', active });
            this.handleSliderChange({
              field: 'maxParticipantsValue',
              value: 0,
            });
          }}>
          <SliderWrapper>
            <Slider
              min={1}
              max={100}
              defaultValue={1}
              value={maxParticipantsValue}
              onChange={(value) =>
                this.handleSliderChange({
                  field: 'maxParticipantsValue',
                  value,
                })
              }
            />
            <SliderLabel>
              {maxParticipantsValue
                ? this.props.t(
                    'createProjectOld.steps.setOptions.quantity.label',
                    { value: maxParticipantsValue }
                  )
                : this.props.t(
                    'createProjectOld.steps.setOptions.quantity.unlimit'
                  )}
            </SliderLabel>
          </SliderWrapper>
        </Option>
        <Option
          title={this.props.t('createProjectOld.steps.setOptions.age.title')}
          text={this.props.t('createProjectOld.steps.setOptions.age.subtitle')}
          active={minimumAge}
          onChange={(active) => {
            this.handleOptionChange({ type: 'minimumAge', active });
            this.handleSliderChange({ field: 'minimumAgeValue', value: 16 });
          }}>
          <SliderWrapper>
            <Slider
              min={16}
              max={100}
              defaultValue={18}
              value={minimumAgeValue}
              onChange={(value) =>
                this.handleSliderChange({ field: 'minimumAgeValue', value })
              }
            />
            <SliderLabel>{minimumAgeValue}</SliderLabel>
          </SliderWrapper>
        </Option>
        <ButtonsWrapper>
          <ButtonWrapper>
            <BackButton
              onClick={() => {
                this.handleSetOptions();
                this.props.history.push('/create-project/description');
              }}>
              {this.props.t('createProjectOld.back')}
            </BackButton>
          </ButtonWrapper>
          <ButtonWrapper>
            <Button onClick={this.handleSubmit}>
              {this.props.t('createProjectOld.steps.setOptions.create')}
            </Button>
          </ButtonWrapper>
        </ButtonsWrapper>
      </Wrapper>
    );
  }
}

const mapState = (state) => ({
  options: selectOptions(state),
});

const mapDispatch = {
  setOptionsRedux,
  createProjectRedux,
  setCurrentPageRedux,
  redirectToPreviousPageRedux,
};

export default connect(
  mapState,
  mapDispatch
)(withTranslation()(withRouter(Options)));
