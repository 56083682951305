const CURRENT_GROUP_ID_KEY = 'currentGroupId';
const CURRENT_ORGANIZATION_ID_KEY = 'currentOrganizationId';

const storage = {
  getToken: (): string => {
    return window.localStorage.getItem('token') || '';
  },
  getInstantSignupToken: (): void => {
    return window.localStorage.getItem('instantSignupToken') || '';
  },
  // getFirebaseToken: (): string => {
  //   return window.localStorage.getItem('firebaseToken') || '';
  // },
  getLanguage: (): string => {
    return window.localStorage.getItem('language') || 'de';
  },
  getUserIp: (): string | null => {
    return window.sessionStorage.getItem('userIp');
  },
  setToken: (token: string): void => {
    window.localStorage.setItem('token', `Bearer ${token}`);
  },
  setInstantSignupToken: (token: string): void => {
    window.localStorage.setItem('instantSignupToken', `Bearer ${token}`);
  },
  // setFirebaseToken: (token: string): void => {
  //   window.localStorage.setItem('firebaseToken', token);
  // },
  setLanguage: (language: string): void => {
    window.localStorage.setItem('language', language);
  },
  setUserIp: (ip: string): void => {
    window.sessionStorage.setItem('userIp', ip);
  },
  clearToken: (): void => {
    window.localStorage.removeItem('token');
  },
  clearInstantToken: (): void => {
    window.localStorage.removeItem('instantSignupToken');
  },
  // clearFirebaseToken: (): void => {
  //   window.localStorage.removeItem('firebaseToken');
  // },
  getCurrentGroupId: (): string | null => {
    return window.localStorage.getItem(CURRENT_GROUP_ID_KEY);
  },
  getCurrentOrganizationId: (): string | null => {
    return window.localStorage.getItem(CURRENT_ORGANIZATION_ID_KEY);
  },
  getOrgSignUpPersonalInfo: (): string | null => {
    return window.localStorage.getItem('orgSignUpPersonalInfo');
  },
  getOrgSignUpConfirmationCode: (): string | null => {
    return window.localStorage.getItem('orgSignUpConfirmationCode');
  },
  getOrgSignUpCompanyInfo: (): string | null => {
    return window.localStorage.getItem('orgSignUpCompanyInfo');
  },
  setCurrentGroupId: (groupId: string): void => {
    window.localStorage.setItem(CURRENT_GROUP_ID_KEY, groupId);
  },
  setCurrentOrganizationId: (organizationId: string): void => {
    window.localStorage.setItem(CURRENT_ORGANIZATION_ID_KEY, organizationId);
  },
  clearCurrentGroupId: (): void => {
    window.localStorage.removeItem(CURRENT_GROUP_ID_KEY);
  },
  clearCurrentOrganizationId: (): void => {
    window.localStorage.removeItem(CURRENT_ORGANIZATION_ID_KEY);
  },
  setOrgSignUpPersonalInfo: (data: string): void => {
    window.localStorage.setItem('orgSignUpPersonalInfo', data);
  },
  setOrgSignUpConfirmationCode: (value: string): void => {
    window.localStorage.setItem('orgSignUpConfirmationCode', value);
  },
  setOrgSignUpCompanyInfo: (data: string): void => {
    window.localStorage.setItem('orgSignUpCompanyInfo', data);
  },
  clearSignUpInfo: (): void => {
    window.localStorage.removeItem('orgSignUpPersonalInfo');
    window.localStorage.removeItem('orgSignUpConfirmationCode');
    window.localStorage.removeItem('orgSignUpCompanyInfo');
  },
  setWasRedirectedTo: (url: string): void => {
    window.localStorage.setItem('wasRedirectedTo', url);
  },
  getWasRedirectedTo: (): string | null => {
    return window.localStorage.getItem('wasRedirectedTo');
  },
};

export default storage;
