import { api } from 'views/shared/modules/api/request';
import storage from 'views/shared/utils/storage';
// Models
import { API } from 'models/api';
import { AxiosRequestConfig } from 'axios';

export const deleteReq = async <T>(url: string, config?: AxiosRequestConfig | undefined) => {
  api.defaults.headers.common.Authorization = storage.getToken();
  try {
    const {
      data: { data },
    } = await api.delete<API<T>>(url, config);
    return data;
  } catch (e) {
    return Promise.reject(e);
  }
};
